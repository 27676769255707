import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  addJobCertificate,
  deleteJobCertificate,
  getJobCertificates,
} from './queries';

/**
 *
 * @param {{
 *  jobId: number;
 * }} params
 */
export const useJobCertificates = ({ jobId }) =>
  useQuery({
    queryKey: ['jobs', jobId, 'certificates'],
    queryFn: () => getJobCertificates({ jobId }),
    enabled: !!jobId,
    placeholderData: {
      certOn: [],
      certOff: [],
    },
  });

/**
 *
 * @param {{
 *  jobId: number;
 * }} params
 */
export const useAddJobCertificate = ({ jobId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: certificateId => addJobCertificate({ jobId, certificateId }),
    onSuccess: () => {
      queryClient.invalidateQueries(['jobs', jobId, 'certificates']);
      swal(`Successfully Added Certificate`, '', 'error');
    },
    onError: error => {
      swal(
        `Unable to Add Certificate`,
        error?.response?.data?.message,
        'error',
      );
    },
  });
};

/**
 *
 * @param {{
 *  jobId: number;
 * }} params
 */
export const useDeleteJobCertificate = ({ jobId }) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: certificateId => deleteJobCertificate({ jobId, certificateId }),
    onSuccess: () => {
      queryClient.invalidateQueries(['jobs', jobId, 'certificates']);
      swal(`Successfully Deleted Certificate`, '', 'error');
    },
    onError: error => {
      swal(
        `Unable to Delete Certificate`,
        error?.response?.data?.message,
        'error',
      );
    },
  });
};
