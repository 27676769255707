import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';

import { document } from 'services/document/service';
import { aws } from 'services/aws/service';
import {
  getCommodities,
  getCustomerCommodities,
  getJobCommodities,
  createCommodity,
  editCommodity,
  getCommoditiesTypes,
  getExtRef,
  addExtref,
  getCommodity,
  deleteCommodity
} from './queries';

export const useCommodities = () =>
  useQuery({
    queryKey: ['commodities'],
    queryFn: getCommodities,
    placeholderData: [],
  });

export const useCustomerCommodities = customerId =>
  useQuery({
    queryKey: ['customer', customerId, 'commodities'],
    queryFn: () => getCustomerCommodities(customerId),
    enabled: !!customerId,
  });

export const useJobCommodities = jobId =>
  useQuery({
    queryKey: ['jobs', jobId, 'commodities'],
    queryFn: () => getJobCommodities(jobId),
    enabled: !!jobId
  });

export const useCommodity = commodityId =>
  useQuery({
    queryKey: ['commodities', commodityId],
    queryFn: () => getCommodity(commodityId),
  });

export const useCommoditiesTypes = () =>
  useQuery({
    queryKey: ['commoditiesTypes'],
    queryFn: getCommoditiesTypes,
    placeholderData: [],
  });

export const useAddCommodity = (options = {}) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: createCommodity,
    onSuccess: () => {
      queryClient.invalidateQueries(['commodities']);
      swal(`Successfully added commodity!`, '', 'success');
    },
    onError: err => {
      swal('Failed to add commodity.', err?.response?.data?.message, 'error');
    },
    ...options,
  });
};

/**
 *
 * @param {number} commodityId
 */
export const useEditCommodity = commodityId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['commodities', commodityId],
    mutationFn: commodity => editCommodity({ commodityId, commodity }),
    onSuccess: () => {
      queryClient.invalidateQueries(['commodities']);
      swal(`Successfully edited commodity!`, '', 'success');
    },
    onError: err => {
      swal('Failed to edit commodity.', err?.response?.data?.message, 'error');
    },
  });
};

export const addDocumentCommodity = async (file, documents) => {
  if (!file) {
    return {};
  }

  try {
    const response = await document.getSignedUrl();
    const url = response.data.data.uploadUrl;
    const documentId = response.data.data.id;
    await aws.uploadToS3(file, url, file.type);
    await document.finishUpload(documentId);
    const data = [
      ...documents,
      { docId: documentId.toString(), docName: file.name },
    ];
    return data;
  } catch (err) {
    swal('Error adding document.', err?.response?.data?.message, 'error');
    return {};
  }
};

/**
 *
 * @param {number} commodityId
 */
export const useExtRef = commodityId =>
  useQuery({
    queryKey: ['extRef', commodityId],
    queryFn: () => getExtRef({ commodityId }),
    enabled: !!commodityId,
  });

/**
 *
 * @param {number} commodityId
 */
export const useAddExtRef = commodityId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['extRef', commodityId],
    mutationFn: extRef => addExtref({ commodityId, extRef }),
    onSuccess: () => {
      queryClient.invalidateQueries(['extRef', commodityId]);
      swal(`Successfully edited external reference!`, '', 'success');
    },
    onError: err => {
      swal(
        'Failed to edit external reference.',
        err?.response?.data?.message,
        'error',
      );
    },
  });
};

/**
 *
 * @returns {object}
 */
export const useDeleteCommodity = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (commodityId) => deleteCommodity(commodityId),
    onSuccess: (data, variables) => {
      queryClient.invalidateQueries(['commodities']);
      queryClient.invalidateQueries(['commodities', variables]);
      swal(`Successfully deleted commodity!`, '', 'success');
    },
    onError: err => {
      swal(
        'Failed to delete commodity.',
        err?.response?.data?.message,
        'error',
      );
    },
  });
};
