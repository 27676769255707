import React from 'react';
import { connect } from 'react-redux';
import { Image, Row } from 'react-bootstrap';
import authService from 'services/auth/service';
import { locationPermissions } from 'components/globalInfo/locationPermissions';
import Icon from 'components/shared/Icon';
import noAvatar from 'public/img/no_avatar.jpg';
import Label from 'components/shared/Label';

const token = authService.getToken();

const DriverInfo = ({ details, orderInfo }) => {
  const {
    driverLocationPermissions,
    driverDeviceVersion,
    driverProfileUrl,
    driverRating,
    displayRateToDriver,
    driverName,
    driverPhoneNumber,
  } = details;
  const { driver_id: driverId } = orderInfo;
  const locationPermissionItem =
    locationPermissions[driverLocationPermissions] || {};
  const { title, text, label } = locationPermissionItem;

  return (
    <Row>
      {driverId && (
        <>
          <div className="col-lg-4" style={{ paddingLeft: 0 }}>
            {(driverProfileUrl && (
              <Image
                src={
                  driverProfileUrl
                    ? `${driverProfileUrl}?token=${token}`
                    : noAvatar
                }
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.src = noAvatar;
                }}
                className="img-circle circle-border m-b-md avatar-driver"
                alt="profile"
              />
            )) || (
              <Icon
                icon="user"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '50%',
                  background: '#1d2332',
                }}
              />
            )}
          </div>
          <div className="col-lg-8" style={{ paddingRight: 0 }}>
            <h3 style={{ marginBottom: 0 }}>
              Driver{' '}
              {driverName && <span className="text-navy">/ {driverName}</span>}
            </h3>
            <br />
            <Label
              title={title}
              type={label}
              className="label--margin-bottom"
              style={{ marginLeft: 0 }}>
              {text}
            </Label>
            <address>
              <span>
                <strong>Rating: </strong>
                {driverRating} <Icon icon="star" />
              </span>
              <br />
              {details.driverDeviceVersion !== 'unknown' && (
                <span>
                  <strong>Device: </strong> v.
                  {driverDeviceVersion}
                  <br />
                </span>
              )}
              {driverPhoneNumber && (
                <span>
                  <Icon icon="phone" />
                  {driverPhoneNumber}
                </span>
              )}

              <br />
              <span>
                <strong>Rates: </strong>
                {displayRateToDriver ? 'Visible' : 'Hidden'}
              </span>
            </address>
          </div>
        </>
      )}
    </Row>
  );
};

const mapStateToProps = state => ({
  details: state.orderDetails.info.details,
  orderInfo: state.orderDetails.info.order,
});

export default connect(
  mapStateToProps,
  null,
)(DriverInfo);
