import React from 'react';
import { image } from 'components/globalInfo/commodityUnits';

export const commodityType = data => {
  return (
    <strong>
      {image(data)}
      <span> Commodity type</span>
    </strong>
  );
};
