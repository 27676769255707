import { createSelector } from 'reselect';

const getReasons = state => state.resourceReducer.demurrageReasons.reasons;

export const filterReasons = createSelector(
  [getReasons],
  reasons => {
    const demurr = [];
    if (reasons.length) {
      reasons.map(item => {
        demurr.push({
          id: item.id,
          name: item.name,
          margin: '',
          symbol: '',
          disabled: true,
        });
        item.children.map(child => {
          demurr.push({
            id: child.id,
            name: child.name,
            symbol: '-- |',
            disabled: false,
          });
        });
      });
    }

    return demurr;
  },
);