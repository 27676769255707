import moment from 'moment';
import { priceTypes } from '../components/globalInfo/priceTypes';

const missingSandTickets = (sandTicketIds, sandTicketTypes) => {
  const massingArray = [];
  sandTicketTypes.forEach(item => {
    if (item.isRequired) {
      const isExist = sandTicketIds.some(
        ticket => ticket.sand_ticket_type == item.sandTicketTypeId,
      );
      if (!isExist) {
        massingArray.push(item.name);
      }
    }
  });
  return massingArray;
};

const mappedSandTicketsToTypes = (sandTicketIds, sandTicketTypes) => {
  const mappedArray = [];
  sandTicketTypes.forEach(ticketType => {
    if (ticketType.isRequired) {
      const mappedObject = {
        sandTicketType: ticketType,
        sandTicketIds: [],
      };
      sandTicketIds.forEach(ticketId => {
        if (ticketId.sand_ticket_type == ticketType.sandTicketTypeId) {
          mappedObject.sandTicketIds.push(ticketId);
        }
      });
      mappedArray.push(mappedObject);
    }
  });
  return mappedArray;
};

const notRequiredTicketsToTypes = (sandTicketIds, sandTicketTypes) => {
  const notRequiredArray = [];

  sandTicketTypes.forEach(ticketType => {
    if (!ticketType.isRequired && ticketType.sandTicketTypeId) {
      const mappedObject = {
        sandTicketType: ticketType,
        sandTicketIds: [],
      };
      sandTicketIds.forEach(ticketId => {
        if (ticketId.sand_ticket_type == ticketType.sandTicketTypeId) {
          mappedObject.sandTicketIds.push(ticketId);
        }
      });
      notRequiredArray.push(mappedObject);
    }
  });
  return notRequiredArray;
};

const demurrageList = demurrageList => {
  const dataDemurrage = [];
  demurrageList.forEach(item => {
    dataDemurrage.push({
      id: item.id,
      name: item.name,
      margin: '',
      disabled: true,
    });
    item.children.forEach(child => {
      dataDemurrage.push({
        id: child.id,
        name: child.name,
        symbol: '--',
        disabled: false,
      });
    });
  });
  return dataDemurrage;
};

const toImgUrl = data => {
  let arrayBufferView;
  let blob;
  let urlCreator;
  arrayBufferView = new Uint8Array(data);
  blob = new Blob([arrayBufferView]);
  urlCreator = window.URL || window.webkitURL;
  const imgUrl = urlCreator.createObjectURL(blob);
  return imgUrl;
};

const demurrSelectId = (type, demurrIds) => {
  const { sand, well } = demurrIds;
  let selectId;
  if (type === 3) {
    selectId = well;
  } else {
    selectId = sand;
  }
  return selectId;
};

const paramsNewDemurr = (type, demurrIds, selectId, orderId) => {
  let param_data = {};
  const { sand, well } = demurrIds;
  if (type === 3) {
    param_data = {
      orderId,
      sandsiteDemurrageReasonId: sand,
      wellsiteDemurrageReasonId: selectId,
    };
  } else {
    param_data = {
      orderId,
      sandsiteDemurrageReasonId: selectId,
      wellsiteDemurrageReasonId: well,
    };
  }
  return param_data;
};

const loadingTimes = order => {
  const times = {};
  times.loading =
    moment(order.load_depart).diff(moment(order.load_arrival), 'minutes') ||
    'unknown';
  times.unloading =
    moment(order.well_depart).diff(moment(order.well_arrival), 'minutes') ||
    'unknown';
  times.staging =
    moment(order.depart_stage).diff(moment(order.enter_stage), 'minutes') ||
    'unknown';
  return times;
};

const typesPrice = prices => {
  prices.map(item => {
    Object.assign(item, {
      title: priceTypes[item.type].title,
    });
  });
  return prices;
};

const orderDetailsModel = {
  missingSandTickets,
  mappedSandTicketsToTypes,
  demurrageList,
  toImgUrl,
  demurrSelectId,
  paramsNewDemurr,
  loadingTimes,
  typesPrice,
  notRequiredTicketsToTypes,
};
export default orderDetailsModel;
