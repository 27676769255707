import React from 'react';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/Export';
import { Row } from 'react-bootstrap';
import Export from './Export';
import Orders from './Orders';

const OrdersBlock = props => (
    <Row>
      <Export {...props} />
      <Orders />
    </Row>
  );

const mapStateToProps = state => ({
  exportSettings: state.exportPage.exportSettings,
  isLoadedTemplates: state.exportPage.templates.isLoadedTemplates,
  templates: state.exportPage.templates.templates,
});

const mapDispatchToProps = dispatch => ({
  setFilterValue: (e, name) => {
    dispatch(actions.setFilterValue(targetValue(e), name));
  },
  download: (e, selectedBtn) => {
    e.preventDefault();
    dispatch(actions.download(selectedBtn));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersBlock);
