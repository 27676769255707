export const addCreatedUserName = (job, users) => {
  let createdBy = 'Unknown';
  users.forEach(item => {
    if (item.id === job.createdByUser) {
      createdBy = `#${item.id} | ${item.profile.firstName} ${
        item.profile.lastName
      }`;
    }
  });
  return createdBy;
};
