import { useQuery } from '@tanstack/react-query';
import { getCustomerBillingProfiles, getCustomers } from './queries';

export const useCustomers = () =>
  useQuery({
    queryKey: ['customers'],
    queryFn: getCustomers,
    placeholderData: [],
  });

export const useCustomerBillingProfiles = ({ customerId, status }) =>
  useQuery({
    queryKey: ['customers', customerId, 'billing-profiles', status],
    queryFn: () => getCustomerBillingProfiles({ customerId, status }),
    enabled: !!customerId,
    placeholderData: [],
  });
