import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import { Modal, Row } from 'react-bootstrap';
import actions from 'store/actions/JobsListAddJob';
import Job from './Job';
import Alerts from './Alerts';
import JobDesign from './JobDesign';
import JobDetails from './JobDetails';
import OnsiteStorage from './OnsiteStorage';
import Header from './Header';
import JobOperationType from './JobOperationType';
import WaterJob from './WaterJob';

const currentStep = (step, props, userCustomer) => {
  const {
    addJob: { jobOperationType },
  } = props;

  if (Number(jobOperationType) === 12) {
    return (
      <WaterJob props={props} userCustomer={userCustomer} key="waterjob" />
    );
  }

  switch (step) {
    case 1:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Job {...props} userCustomer={userCustomer} key="job" />;
    case 2:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <JobDetails {...props} key="job-details" />;
    case 3:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <Alerts {...props} key="alerts" />;
    case 4:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <JobDesign {...props} key="job-design" />;
    case 5:
      // eslint-disable-next-line react/jsx-props-no-spreading
      return <OnsiteStorage {...props} key="storage" />;
    default:
      return null;
  }
};

const AddJob = props => {
  const {
    selectedCustomer,
    setModalValue,
    initModal,
    onsiteStorageTypes,
    getOnsiteStorageTypes,
    getBillingProfiles,
    showAddJob,
    closeAddJobModal,
    addJob,
    addJobInitData,
    setStep,
    customerIdFromCustomer,
  } = props;

  const checkForAndGetInitData = () => {
    if (customerIdFromCustomer) {
      setModalValue(customerIdFromCustomer, 'customerId');
      selectedCustomer(closeAddJobModal);
      getBillingProfiles();
    }
    if (!onsiteStorageTypes.length) {
      getOnsiteStorageTypes();
    }
    initModal();
  };

  const { step } = addJobInitData;
  const { jobOperationType } = addJob;
  const [userCustomer, changeUserCustomer] = useState(false);

  useEffect(() => {
    if (customerIdFromCustomer) {
      changeUserCustomer(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      className="modal-container"
      size="xl"
      dialogClassName="modal-w40"
      show={showAddJob}
      onHide={closeAddJobModal}
      onEntered={checkForAndGetInitData}>
      <Modal.Header style={{ display: 'block' }}>
        <Row>
          <Modal.Title as="h3">Add Job</Modal.Title>
          <Header
            step={step}
            setStep={setStep}
            jobOperationType={jobOperationType}
          />
        </Row>
      </Modal.Header>
      {jobOperationType !== undefined ? (
        currentStep(step, props, userCustomer)
      ) : (
        <JobOperationType
          closeAddJobModal={closeAddJobModal}
          setModalValue={setModalValue}
        />
      )}
    </Modal>
  );
};
const mapStateToProps = state => ({
  addJob: state.jobsList.jobs.addJob,
  addJobInitData: state.jobsList.jobs.addJobInitData,
  // sandTypes: state.lookUp.sandTypes,
  // equipmentRequired: state.lookUp.equipmentRequired,
  onsiteStorageTypes:
    state.jobsList.jobs.onsiteStorageTypesData.onsiteStorageTypes,
});

const mapDispatchToProps = dispatch => ({
  setModalValue: (e, name) => {
    dispatch(actions.setAddJobValue(targetValue(e), name));
  },
  setStep: step => {
    dispatch(actions.setStepValue(step));
  },
  initModal: () => {
    dispatch(actions.initModalAddJob());
  },
  getOnsiteStorageTypes: () => {
    dispatch(actions.getOnsiteStorageTypes());
  },
  selectedCustomer: closeAddJobModal => {
    dispatch(actions.selectedCustomer(closeAddJobModal));
  },
  validateCustomerJobId: () => {
    dispatch(actions.validateCustomerJobId());
  },
  getBillingProfiles: () => {
    dispatch(actions.getBillingProfiles());
  },
  setStartDate: start => dispatch(actions.setAddJobValue(start, 'startDate')),
  setEndDate: end => dispatch(actions.setAddJobValue(end, 'endDate')),
  selectData: (selected, name) =>
    dispatch(actions.setAddJobValue(selected, name)),
  addArrayValue: (name, content) =>
    dispatch(actions.addArrayValue(name, content)),
  setArrayValue: (e, index, name, arrayName) => {
    dispatch(actions.setArrayValue(targetValue(e), index, name, arrayName));
  },
  deleteArrayValue: (index, name) => {
    dispatch(actions.deleteArrayValue(index, name));
  },
  setOnsiteStorageType: (selectedType, index) => {
    dispatch(actions.setOnsiteStorageType(selectedType, index));
  },
  saveJob: e => {
    e.preventDefault();
    dispatch(actions.saveJob(false, null));
  },
  saveDraftJob: closeAddJobModal => {
    dispatch(actions.saveJob(true, closeAddJobModal));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddJob);
