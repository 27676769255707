import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import Link from 'components/common/Link';
import { authorize } from 'components/common/Authorize';
import NotarizationReport from 'components/views/NotarizationsReport';
import Loader from 'components/shared/Loader';

import { useLocations } from 'api/v2/locations';

import OrderColumn from './OrderColumn';
import { linkPermission } from './LinkPermission';
import { commodityType } from './CommodityType';

const idLocationDeleted = (id, locations) => {
  const location = locations.find(e => id === e.id);

  if (!location) {
    return true;
  }

  return location.is_deleted;
};

const MainInfo = ({ order }) => {
  const { data: locations, isLoading } = useLocations();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="row">
      <h3>Route</h3>
      <div className="row">
        <div className="col-lg-6" style={{ paddingLeft: 0 }}>
          <OrderColumn
            header="Origin"
            title={[
              'Origin',
              commodityType(order.commodityType),
              'PO',
              'Expected Pickup',
            ]}
            value={[
              idLocationDeleted(order.loading_site_id, locations)
                ? `#${order.loading_site_id} | ${order.loading_site_name}`
                : linkPermission(
                    order.loading_site_id,
                    order.loading_site_name,
                    'location-details',
                    'Locations',
                  ),
              `#${order.sand_type || 'unknown'} | ${order.commodity_name ||
                'unknown'}`,
              `${order.po || 'unknown'}`,
              moment(order.expected_pickup_time).format('MM-DD hh:mm A'),
            ]}
          />
        </div>
        <div className="col-lg-6" style={{ paddingRight: 0 }}>
          <OrderColumn
            header="Destination"
            title={['Destination', 'Job', 'Customer']}
            value={[
              idLocationDeleted(order.well_site_id, locations)
                ? `#${order.well_site_id} | ${order.well_site_name}`
                : linkPermission(
                    order.well_site_id,
                    order.well_site_name,
                    'location-details',
                    'Locations',
                  ),
              linkPermission(
                order.jobId,
                order.job_name,
                'job-details',
                'Jobs',
              ),
              linkPermission(
                order.customer_id,
                order.customer_name,
                'customer-details',
                'Customers',
              ),
            ]}
          />
        </div>
      </div>

      {order.status != 0 && order.status != 1 && (
        <div className="row">
          <div className="col-lg-6" style={{ paddingLeft: 0 }}>
            <h4>Load: </h4>
            <br />
            <NotarizationReport {...{ order }} />
          </div>
          <div className="col-lg-6" style={{ paddingRight: 0 }}>
            <OrderColumn
              header="Carrier"
              title={['Driver', 'Truck', 'Carrier']}
              value={[
                authorize({
                  abilityPermissions: [
                    {
                      resource: 'Drivers',
                      permissions: ['read'],
                    },
                  ],
                }) ? (
                  <Link {...{ id: order.driver_id, to: 'driver-details' }}>
                    {`#${order.driver_id || 'unknown'} | ${order.driver_name ||
                      ''}`}
                  </Link>
                ) : (
                  `#${order.driver_id || 'unknown'} | ${order.driver_name ||
                    ''}`
                ),
                `${order.truck || 'unknown'}`,
                authorize({
                  abilityPermissions: [
                    {
                      resource: 'Carriers',
                      permissions: ['read'],
                    },
                  ],
                }) ? (
                  <Link {...{ id: order.carrier_id, to: 'carrier-details' }}>
                    {`#${order.carrier_id ||
                      'unknown'} | ${order.carrier_name || 'unknown'}`}
                  </Link>
                ) : (
                  `#${order.carrier_id || 'unknown'} | ${order.carrier_name ||
                    'unknown'}`
                ),
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => ({
  order: state.orderDetails.info.order,
});

export default connect(
  mapStateToProps,
  null,
)(MainInfo);
