import authService from 'services/auth/service';
import { customer } from 'services/customer/service';
import { district } from 'services/district/service';
import { addCustomerConstants } from '../constants/AddCustomer';
import actions from './CustomerDetails';
import swal from 'bootstrap-sweetalert';

const clear = () => ({ type: 'CUSTOMER_ADD_CUSTOMER_CLEAR' });
const setStep = step => ({
  type: 'CUSTOMER_ADD_CUSTOMER_SET_STEP',
  step,
});
const setBillValue = (value, name) => ({
  type: 'CUSTOMER_ADD_CUSTOMER_SET_BILL',
  value,
  name,
});
const setConfigValue = (value, name) => ({
  type: 'CUSTOMER_ADD_CUSTOMER_SET_CONFIG',
  value,
  name,
});
const setCompanyValue = (value, name) => ({
  type: 'CUSTOMER_ADD_CUSTOMER_SET_COMPANY',
  value,
  name,
});

const getCustomers = () => {
  const request = () => ({ type: addCustomerConstants.GET_CUSTOMERS_REQUEST });
  const success = customers => ({
    type: addCustomerConstants.GET_CUSTOMERS_SUCCESS,
    customers,
  });
  const failure = error => ({
    type: addCustomerConstants.GET_CUSTOMERS_FAILURE,
    error,
  });

  return dispatch => {
    dispatch(request());

    customer
      .get()
      .then(response => {
        dispatch(success(response.data.data.customers));
      })
      .catch(error => {
        dispatch(failure(error));
        swal(error.response.data.error, '', 'error');
      });
  };
};

const addSubmit = closeModal => (dispatch, getState) => {
  const {
    manageCustomers: {
      addCustomer: { input },
    },
  } = getState();
  const data = { ...input };
  data.companyInfo.districts = data.companyInfo.districts.map(
    item => item.value,
  );
  data.configuration = {
    // Temporary commented, it may to bring back later
    // sandCoordinatorRequired: String(data.configuration.sandco),
    useQRCode: data.configuration.useQRCode ? '1' : '0',
    fuelSurchargeEnabled: data.configuration.fuelSurchargeEnabled ? '1' : '0',
    // price: data.configuration.sandco ? data.configuration.sandcoPrice : '0',
  };
  customer
    .addCustomer(data)
    .then(response => {
      dispatch(getCustomers());
      swal(response.data.message, '', 'success');
      closeModal();
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const addSubmitAfterCustomer = (setStep, e) => (dispatch, getState) => {
  const {
    manageCustomers: {
      addCustomer: { input },
    },
  } = getState();
  const data = {
    ...input,
  };
  data.companyInfo.districts = !isNaN(data.companyInfo.districts[0])
    ? data.companyInfo.districts
    : data.companyInfo.districts.map(item => item.value);
  data.companyInfo.bcSync = Boolean(data.companyInfo.bcSync);
  data.configuration = {
    useQRCode: data.configuration.useQRCode ? '1' : '0',
    fuelSurchargeEnabled: data.configuration.fuelSurchargeEnabled ? '1' : '0',
  };

  customer
    .addCustomer(data)
    .then(response => {
      const { customerId } = response.data.data;
      dispatch(actions.init(customerId));
      dispatch(getCustomers());
      setStep(5, e);
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const init = () => dispatch => {
  const save = districts => ({
    type: 'CUSTOMER_ADD_CUSTOMER_SAVE_INIT',
    districts,
  });
  district
    .get()
    .then(msg => dispatch(save(msg.data.data.district)))
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const shareCustomer = (closeModal, customerId) => dispatch => {
  const user = authService.getUser();
  const params = {
    data: {
      companyType: user.entityType,
      companyId: user.entityId,
    },
    customerId,
  };
  customer
    .shareCustomer(params)
    .then(response => {
      dispatch(actions.init(customerId));
      dispatch(getCustomers());
      closeModal();
      swal(
        'You have successfully connected to the selected customer',
        '',
        'success',
      );
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

export default {
  clear,
  addSubmit,
  setBillValue,
  setConfigValue,
  setCompanyValue,
  init,
  setStep,
  addSubmitAfterCustomer,
  shareCustomer,
};
