import { client } from 'api/client';
import authService from 'services/auth/service';
import { uploadDocument } from '../documents/queries';

export const getCarriers = async () => {
  const { data } = await client.get('v2/carrier');
  return data.data.carriers;
};

export const getCarrierDetails = async carrierId => {
  const token = authService.getToken();
  const res = await client.get(`v2/carrier/${carrierId}/details`);

  const carrier = res.data.data;
  carrier.details.profilePhoto = `${
    carrier.details.profilePhoto
  }?token=${token}`;

  return carrier;
};

export const createCarrier = async newCarrier => {
  const reqData = {
    name: newCarrier.name,
    email: newCarrier.email,
    contactNo: newCarrier.phone,
    fleetSize: Number(newCarrier.fleetSize),
    districts: newCarrier.selectedDistricts,
    usDotNo: newCarrier.numberUS,
    mcNo: newCarrier.numberMC,
    einNo: newCarrier.numberEIN,
    streetAddress: newCarrier.address,
    city: newCarrier.city,
    state: newCarrier.state,
    zip: newCarrier.zip,
    enableRemit: Boolean(newCarrier.remit),
    companyTypeId: newCarrier.companyType,
    interstateCertified: Number(newCarrier.interstateCertified),
    isNonPurchasable: Number(newCarrier.isNonPurchasable),
    bcSync: Boolean(newCarrier.bcSync),
  };

  const accessToken = localStorage.getItem('accessToken');
  if (accessToken) {
    reqData.accessToken = accessToken;
  }

  if (newCarrier.factorViewId && newCarrier.factorViewId !== '') {
    reqData.factorViewId = Number(newCarrier.factorViewId);
  }

  if (newCarrier.file) {
    reqData.profilePhotoUrl = await uploadDocument(newCarrier.file);
  }

  if (newCarrier.qbCarrierId) {
    reqData.qbCarrierId = Number(newCarrier.qbCarrierId);
  }

  const { data } = await client.post('v2/carrier', reqData);
  return data;
};

export const updateCarrier = async ({ carrierId, info }) => {
  const { data } = await client.put(`v2/carrier/${carrierId}/edit`, info);
  return data;
};

export const updateCarrierFinancials = async ({ carrierId, info }) => {
  const { data } = await client.put(`v2/carrier/${carrierId}/finances`, info);
  return data;
};

export const updateCarrierBillingAddress = async ({ carrierId, address }) => {
  const { data } = await client.put(`v2/carrier/${carrierId}/address`, address);
  return data;
};

export const updateCarrierSettings = async ({ carrierId, settings }) => {
  const { data } = await client.put(
    `v2/carrier/${carrierId}/settings`,
    settings,
  );
  return data;
};

export const toggleCarrierRemit = async ({ carrierId, remit }) => {
  const { data } = await client.put(
    `v2/carrier/${carrierId}/toggle-enable-remit`,
    remit,
  );
  return data;
};

export const updateCarrierRemit = async ({ carrierId, remitInfo }) => {
  const { data } = await client.put(`v2/carrier/${carrierId}/remit`, remitInfo);
  return data;
};

export const updateCarrierLogo = async ({ carrierId, logo }) => {
  const formData = new FormData();
  formData.append('logo', logo);

  const { data } = await client.put(`v2/carrier/${carrierId}/logo`, formData, {
    headers: {
      dataType: 'json',
      processData: false,
      contentType: false,
    },
  });
  return data;
};

export const deleteCarrier = async carrierId => {
  const { data } = await client.delete(`v2/carrier/${carrierId}`);
  return data;
};

export const restoreCarrier = async carrierId => {
  const { data } = await client.delete(`v2/carrier/${carrierId}/enable`);
  return data;
};

export const addManyCarrierDistricts = async ({ carrierId, districts }) => {
  const { data } = await client.post(
    `v2/carrier/${carrierId}/districts`,
    districts,
  );
  return data;
};

export const deleteCarrierDistrict = async ({ carrierId, districtId }) => {
  const { data } = await client.delete(
    `v2/carrier/${carrierId}/districts/${districtId}`,
  );
  return data;
};

export const syncBc = async ({ carrierId, sync }) => {
  const { data } = await client.post(`v2/carrier/${carrierId}/bc-sync`, {
    bcSync: sync,
  });
  return data;
};

export const sendCarrierNotification = async notification => {
  const { data } = await client.post(
    'v2/carrier/send-notification',
    notification,
  );
  return data;
};

export const getCarrierTruckAnalytics = async carrierId => {
  const { data } = await client.get(`v2/carrier/${carrierId}/trucks/analytics`);
  return data.data.analytics;
};

export const addDistrictCarrierRatio = async ({ carrierId, carrierRatios }) => {
  const { data } = await client.post(
    `v2/carrier/${carrierId}/districts/carrier-ratio`,
    carrierRatios,
  );
  return data;
};

export const getCarrierJobs = async carrierId => {
  const { data } = await client.get(`v2/carrier/${carrierId}/permissions/jobs`);
  return data.data.jobs;
};

export const getCarrierScadaReport = async carrierId => {
  const { data } = await client.get(`v2/carrier/${carrierId}/scada-report`);
  const hiddenElement = document.createElement('a');
  const blob = new Blob([data], {
    type: 'application/csv;charset=utf-8',
  });
  hiddenElement.href = window.URL.createObjectURL(blob);
  hiddenElement.download = 'scada.csv';
  hiddenElement.click();
};
