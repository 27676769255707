import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import OrderActions from 'components/views/OrderActions';
import Loader from 'components/shared/Loader';
import IboxTools from 'components/common/IboxTools';
import Pagination from 'components/common/ReduxPagination';

import targetValue from 'utils/targetValue';
import actions from 'store/actions/OrdersList';

import Order from './Order';
import FilterOrders from './Filters';
import OrderInfo from './OrderInfoModal';

const OrdersList = props => {
  const {
    initFilters,
    init,
    clear,
    details,
    info,
    openModal,
    filters: {
      input: { show },
    },
    getOrders,
    currentPage,
  } = props;
  const { isLoaded, totalPages, orders } = info;

  useEffect(() => {
    init(initFilters);
    return () => clear();
  }, []);

  useEffect(() => {
    getOrders();
  }, [currentPage]);

  return (
    <div>
      {openModal && <OrderInfo />}
      <div className="ibox float-e-margins">
        <div className="ibox-title">
          <h3>Orders</h3>
          <IboxTools />
        </div>
        <div className="ibox-content">
          <OrderActions
            details={details}
            order={{}}
            exportComponent={{ modals: true, actions: false }}
          />
          {show && <FilterOrders {...props} />}
          <Pagination totalPages={totalPages} />
          {!isLoaded ? (
            <Loader />
          ) : (
            <div>
              {!orders.length ? (
                <div>No orders found.</div>
              ) : (
                <Order {...props} />
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  info: state.orderList.info,
  filters: state.orderList.filters,
  equipmentRequired: state.lookUp.equipmentRequired,
  loadingEquipments: state.jobDetails.containerized.containers.loading,
  details: state.jobDetails.details,
  currentPage: state.pagination.pagination.currentPage,
  openModal: state.orderList.modalOrderInfo.openModal,
});

const mapDispatchToProps = dispatch => ({
  init: filters => dispatch(actions.init(filters)),
  clear: () => dispatch(actions.clear()),
  getOrders: () => dispatch(actions.getOrders()),
  handleFilterChange: () => dispatch(actions.handleFilterChange()),
  setDate: range => {
    dispatch(actions.setDate(range.selection.startDate, 'startDate'));
    dispatch(actions.setDate(range.selection.endDate, 'endDate'));
  },
  setFilterValue: (e, name) => {
    dispatch(actions.setPagination());
    dispatch(actions.setFilterValue(targetValue(e), name));
  },
  turnModalInfo: order => {
    dispatch(actions.turnModalInfo());
    dispatch(actions.initOrder(order));
  },
  downloadInvoicePdf: id => dispatch(actions.downloadInvoicePdf(id)),
  refreshOrder: id => dispatch(actions.refreshOrder(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersList);
