import React, { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'components/shared/Select';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';
import { generateCustomerOptions } from './helpers';

const SelectCustomer = ({
  setStep,
  submitShareCustomer,
  customers,
  closeAdd,
}) => {
  const [selectedCustomer, setCustomer] = useState(undefined);
  const customerOptions = generateCustomerOptions(customers);

  const acceptCustomer = () => {
    if (selectedCustomer) {
      submitShareCustomer(closeAdd, selectedCustomer);
    }
  };
  return (
    <>
      <Modal.Body className="form-group">
        <div className="step1">
          <h1 id="form-h-0" className="title current">
            Search Customer
          </h1>
          <fieldset id="form-p-0" role="tabpanel" className="body current">
            <div className="row">
              <div className="form-group has-feedback col-md-12">
                <Select
                  required
                  onChange={selected => {
                    const step = selected.value === '**' ? 2 : 5;
                    if (step === 2) {
                      setStep(step, null);
                    } else {
                      setCustomer(selected.value);
                    }
                  }}
                  options={customerOptions}
                  placeholder="Search Customer"
                  value={null}
                  addNew
                  testSelector="admin-customers_select-customer_select"
                />
              </div>
            </div>
          </fieldset>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {selectedCustomer && (
          <Button
            onClick={acceptCustomer}
            colour="white"
            testSelector="admin-customers_select_accept_btn">
            Accept Customer
          </Button>
        )}
        <Button
          onClick={closeAdd}
          colour="white"
          testSelector="admin-customers_select_close_btn">
          Close
        </Button>
      </Modal.Footer>
    </>
  );
};

const mapStateToProps = state => ({
  customers: state.resourceReducer.customers.customers,
});

export default connect(mapStateToProps)(SelectCustomer);
