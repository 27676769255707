import React from 'react';
import { connect } from 'react-redux';

import Authorize, { authorize } from 'components/common/Authorize';
import Link from 'components/common/Link';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';

import manageCustomersActions from 'store/actions/ManageCustomers';

import { Col, Row } from 'react-bootstrap';
import { assembleAddress } from '../helpers';

const CustomerList = ({ customers, syncBc }) => (
  <div>
    {customers.map(item => (
      <div key={item.email} className="ibox">
        <div className="ibox-content-customer">
          <div className="row">
            <h2
              style={
                authorize({
                  abilityPermissions: [
                    {
                      resource: 'Customers',
                      permissions: ['read'],
                    },
                  ],
                  itemAccessGranted:
                    item.accessLevel === 'shared' ||
                    item.accessLevel === 'owner',
                })
                  ? {
                      cursor: 'pointer',
                      display: 'inline-block',
                    }
                  : {
                      display: 'inline-block',
                    }
              }>
              #{item.id}
              {authorize({
                abilityPermissions: [
                  { resource: 'Customers', permissions: ['read'] },
                ],
                itemAccessGranted:
                  item.accessLevel === 'shared' || item.accessLevel === 'owner',
              }) ? (
                <Link
                  {...{
                    to: 'customer-details',
                    id: item.id,
                  }}>
                  <span
                    style={{
                      marginLeft: 5,
                    }}
                    className="text-navy">
                    {item.name}
                  </span>
                </Link>
              ) : (
                <span
                  style={{
                    marginLeft: 5,
                  }}>
                  {item.name}
                </span>
              )}
              {/* <Authorize
                  {...{
                    abilityPermissions: [
                      {
                        resource: 'Customers',
                        permissions: ['delete'],
                      },
                    ],
                    itemAccessGranted: item.accessLevel === 'owner',
                  }}>
                  {!item.isDeleted ? (
                    <Button
                      onClick={() => deleteAlert(deleteCustomer, item.id)}
                      theme="small"
                      colour="white">
                      <Icon icon="trash" />
                    </Button>
                  ) : null}
                </Authorize>
                {item.isDeleted ? (
                  <Button
                    colour="red"
                    theme="small"
                    onClick={
                      authorize({
                        abilityPermissions: [
                          {
                            resource: 'Customers',
                            permissions: ['delete'],
                          },
                        ],
                        itemAccessGranted: item.accessLevel === 'owner',
                      })
                        ? () => {
                            confirmAlert(unDeleteCustomer, item.id, {
                              title:
                                'Are you sure you want to UnDelete the customer?',
                              text: ' ',
                            });
                          }
                        : null
                    }>
                    Deleted
                  </Button>
                ) : null} */}
              <Authorize
                abilityPermissions={[
                  { resource: 'Carrier-documents', permissions: ['update'] },
                ]}>
                <div
                  className="row text-right buttons-customer"
                  style={{ marginTop: 10 }}>
                  {item.bcId ? (
                    <Button
                      onClick={() => {}}
                      theme="small"
                      colour="green"
                      testSelector="admin-carriers_list_bc_synced_btn">
                      BC SYNCED
                    </Button>
                  ) : (
                    <Button
                      onClick={() => syncBc(item.id)}
                      theme="small"
                      colour="purple"
                      testSelector="admin-carriers_list_sync_bc_btn">
                      SYNC BC
                    </Button>
                  )}
                </div>
              </Authorize>
            </h2>
          </div>
          <Authorize
            abilityPermissions={[
              { resource: 'Customers', permissions: ['read'] },
            ]}
            itemAccessGranted={
              item.accessLevel === 'shared' || item.accessLevel === 'owner'
            }>
            <Row
              className="tabs-container"
              style={{
                minWidth: 480,
              }}>
              <div className="panel-body">
                <Row>
                  <Col
                    lg={4}
                    md={4}
                    sm={5}
                    xs={11}
                    style={{
                      marginTop: 15,
                    }}>
                    <strong>
                      <Icon icon="envelope" /> {item.email}
                    </strong>
                    {/* // Temporary commented, it may to bring back later */}
                    {/* <br />
                      <strong>
                        <Icon icon="phone" /> {item.phone_no}
                      </strong>
                      <br />
                      <strong>Billing Address: {item.billing_address}</strong>
                      <br /> */}
                  </Col>
                  <Col lg={7} md={7} sm={6} xs={7} className="text-center">
                    <Row
                      style={{
                        marginTop: 10,
                      }}>
                      <Col lg={6} md={6} sm={6}>
                        <strong>
                          <Icon icon="phone" /> {item.phoneNo}
                        </strong>
                        {/* // Temporary commented, it may to bring back later */}
                        {/* {item.sand_coordinator_required ? (
                            <h2 className="text-navy m-b-xs">
                              <b>{item.price}</b>
                            </h2>
                          ) : (
                            <h2 className="text-navy m-b-xs">
                              <b>N/A</b>
                            </h2>
                          )}
                          <small>Job Coordinator Price</small> */}
                      </Col>
                      <Col lg={6} md={6} sm={6}>
                        <strong>
                          Billing Address:{' '}
                          {assembleAddress(
                            item?.address?.address1,
                            item?.address?.city,
                            item?.address?.state,
                            item?.address?.zip,
                          )}
                        </strong>
                        {/* // Temporary commented, it may to bring back later */}
                        {/* {item.sand_coordinator_required ? (
                            <h2 className="text-navy m-b-xs">
                              <b>Yes</b>
                            </h2>
                          ) : (
                            <h2 className="text-navy m-b-xs">
                              <b>No</b>
                            </h2>
                          )}
                          <small>Job Coordinator Required</small> */}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </div>
            </Row>
          </Authorize>
        </div>
      </div>
    ))}
  </div>
);

const mapDispatchToProps = dispatch => ({
  deleteCustomer: id => dispatch(manageCustomersActions.deleteCustomer(id)),
  unDeleteCustomer: id => dispatch(manageCustomersActions.unDeleteCustomer(id)),
  syncBc: id => dispatch(manageCustomersActions.syncCustomerBc(id)),
});

export const CustomerListTest = CustomerList; // for Tests

export default connect(
  null,
  mapDispatchToProps,
)(CustomerList);
