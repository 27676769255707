import axios from 'axios';

export const customer = {
  deleteAdditionalPrice(id) {
    return axios.delete(`v2/customer/additional-charges/${id}`, {});
  },
  addAdditionalPrice(customerId, priceType) {
    return axios.post('v2/customer/additional-charges', {
      customerId,
      priceType,
    });
  },
  get() {
    return axios.get('v2/customer');
  },
  details(id) {
    return axios.get(`v2/customer/${id || 0}/details`, {});
  },
  edit(info) {
    return axios.put(`v2/customer/${info.id}`, info);
  },
  jobs: {
    get(customerId) {
      return axios.get(`v2/customer/${customerId}/jobs`, {});
    },
  },
  rateTable: {
    get(customerId) {
      return axios.get(`v2/customer/${customerId}/rate-tables`, {});
    },
    add(data) {
      return axios.post(`v2/customer/${data.customerId}/rate-tables`, data);
    },
    deactivate(rateId) {
      return axios.delete(`v2/customer/${rateId}/rate-tables`);
    },
  },
  demurrage: {
    get(customerId) {
      return axios.get(`v2/customer/${customerId}/demurrage-table`, {});
    },
    add(data) {
      return axios.post(`v2/customer/${data.customerId}/demurrage-table`, data);
    },
    deactivate(demurrageId) {
      return axios.delete(`v2/customer/${demurrageId}/demurrage-table`);
    },
  },
  billing: {
    payments: {
      get(id, status) {
        const numStatus = Number(status);
        const isValid = !Number.isNaN(numStatus);
        const queryItem = isValid ? numStatus : 1;
        return axios.get(
          `v2/billing/customer/${id}/payments?active=${queryItem}`,
        );
      },
      add(params) {
        const { data, id } = params;
        return axios.put(`v2/billing/customer/${id}/payments`, data);
      },
      void(params) {
        const { data, id, customerId } = params;
        return axios.put(
          `v2/billing/customer/${customerId}/payments/${id}/void`,
          data,
        );
      },
    },
    profiles: {
      get(id, status) {
        const numStatus = Number(status);
        const isValid = !Number.isNaN(numStatus);
        const queryItem = isValid ? numStatus : 1;
        return axios.get(
          `v2/billing/customer/${id}/profiles?active=${queryItem}`,
        );
      },
      add(params) {
        const { data, id } = params;
        return axios.put(`v2/billing/customer/${id}/profiles`, data);
      },
      void(params) {
        const { data, id, customerId } = params;
        return axios.put(
          `v2/billing/customer/${customerId}/profiles/${id}/active`,
          data,
        );
      },
      addPaymentIntoProfile(params) {
        const { payment, profileId, customerId } = params;
        return axios.post(
          `v2/billing/customer/${customerId}/profiles/${profileId}`,
          payment,
        );
      },
      editPaymentInProfile(params) {
        const { payment, profileId, customerId, id } = params;
        return axios.post(
          `v2/billing/customer/${customerId}/profiles/${profileId}/item/${id}`,
          payment,
        );
      },
      getPaymentIntoProfile(params) {
        const { profileId, id } = params;
        return axios.get(`v2/billing/customer/${id}/profiles/${profileId}`);
      },
      getOrders(data) {
        // TODO: Match REST convention
        // need profileId, customerId
        return axios.post(`v2/billing/profile/order`, data);
      },
      reRateOrders(data) {
        // TODO: Match REST convention
        // need array of orders
        return axios.put(`v2/billing/profile/order/rerate`, data);
      },
    },
  },
  deleteCustomer(customerId) {
    return axios.delete(`v2/customer/${customerId}`);
  },
  unDeleteCustomer(customerId) {
    return axios.patch(`v2/customer/${customerId}/enable`);
  },
  syncBc(id) {
    return axios.post(`v2/customer/${id}/bc-sync`, { bcSync: true });
  },
  addCustomer(data) {
    return axios.post('v2/customer', data);
  },
  address: {
    edit(data) {
      return axios.put(`v2/customer/${data.id}/address`, data);
    },
  },
  billingTerms: {
    edit(data) {
      return axios.put(`v2/customer/${data.id}/billing-terms`, data);
    },
  },
  logo: {
    edit(data, id) {
      return axios.post(`v2/customer/${id}/logo`, data, {
        headers: {
          dataType: 'json',
          processData: false,
          contentType: false,
        },
      });
    },
  },
  member: {
    add(data) {
      // TODO: Maychance remove, does not exist
      return axios.post('v2/createCustomerMember', {
        customerId: data.customerId,
        description: data.description,
        email: data.email,
        name: data.name,
        password: data.password,
      });
    },
  },
  container: {
    get(data) {
      data.customerId = data.customerId || localStorage.getItem('customerId');
      return axios.get(`v2/customer/${data.customerId}/containers`, {
        params: {
          wellsiteId: data.wellsiteId,
          type: data.type,
          sandsiteId: data.sandsiteId,
          jobId: data.jobId,
        },
      });
    },
    updateStatus(data) {
      data.customerId = data.customerId || localStorage.getItem('customerId');
      return axios.put(
        `v2/customer/${data.customerId}/containers/${data.id}/status`,
        {
          jobId: data.jobId,
          sandTypeId: data.sandTypeId,
          sandVendorId: data.sandVendorId,
          status: data.status,
          weight: data.weight,
        },
      );
    },
    conteinerizedSettings(data, jobId) {
      return axios.post(`v2/job/${jobId}/containerized-settings`, {
        autoDispatchEnabled: data.autoDispatchEnabled,
        containersPerTruck: data.containersPerTruck,
        stageBuffer: data.stageBuffer,
        stageDesignId: data.stageDesignId,
      });
    },
  },
  notarizations: {
    get(customerId) {
      return axios.get(`v2/customer/${customerId}/notarization/settings`);
    },
    put(params) {
      const { customerId, data } = params;
      return axios.put(`v2/customer/${customerId}/notarization/settings`, data);
    },
  },
  scada: {
    get(id) {
      return axios.get(`v2/customer/${id}/scada-report`);
    },
  },
  shareCustomer(params) {
    const { customerId, data } = params;
    return axios.post(`v2/customer/${customerId}/share`, data);
  },
};
