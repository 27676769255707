import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderActions';
import { confirmAlert } from 'components/sweetalert';
import { ROLES } from 'config/constants';
import authService from 'services/auth/service';
import { authorize } from 'components/common/Authorize';
import { Dropdown } from 'react-bootstrap';
import Icon from 'components/shared/Icon';
import Modify from './Modify';
import UploadST from './UploadST';
import Divert from './Divert';
import Transfer from './Transfer';
import Requeue from './Requeue';
import DemurrageReason from './DemurrageReason';
import { setTitle } from './helpers';

const OrderActions = props => {
  const {
    openModal,
    orderActions,
    order,
    releaseDriver,
    callToWell,
    deleteOrder,
    reviveOrder,
    exportComponent,
    showModalDemurrage,
    details,
  } = props;
  const { modify, uploadST, divert, transfer, requeue } = orderActions;
  const { VENDOR, CUSTOMER, CARRIER, JOB_COORDINATOR, JOB_MANAGER } = ROLES;
  const accessAll = [VENDOR, CUSTOMER, CARRIER, JOB_COORDINATOR, JOB_MANAGER];
  const allStatuses = [0, 1, 2, 3, 4, 5, 6, 7, 8];
  const user = authService.getUser() || {};

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const complete = urlParams.get('complete');
    const fromQr = urlParams.get('fromQr');
    if (complete) {
      window.history.pushState('', '', window.location.pathname);
      openModal('modify', { ...order, fromQR: Boolean(fromQr) });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const components = !order.is_deleted
    ? [
        {
          component: (
            <Dropdown.Item
              key="upload"
              onClick={() => openModal('uploadST', order)}>
              Upload Ticket
            </Dropdown.Item>
          ),
          statuses: [2, 3, 4, 5, 6, 7, 8],
          roles: accessAll,
        },
        {
          component: (
            <Dropdown.Item
              key="release_driver"
              onClick={() => confirmAlert(() => releaseDriver(order.order_id))}>
              Release Driver
            </Dropdown.Item>
          ),
          statuses: [2, 5],
          roles: [JOB_MANAGER, CARRIER, CUSTOMER, JOB_COORDINATOR],
          if: o => o.driver_id != null,
        },
        {
          component: (
            <Dropdown.Item key="call_to_well" onClick={() => callToWell(order)}>
              Call to Destination
            </Dropdown.Item>
          ),
          statuses: [6, 7, 8],
          roles: [VENDOR, CUSTOMER, JOB_COORDINATOR, JOB_MANAGER],
          if: o => o.call_to_well_status === 0,
        },
        {
          component: (
            <Dropdown.Item
              key="Divert"
              onClick={() => openModal('divert', order)}>
              Divert to job
            </Dropdown.Item>
          ),
          statuses: allStatuses,
          roles: [JOB_MANAGER, CARRIER, CUSTOMER],
          if: o => o.driver_id != null,
        },
        {
          component: (
            <Dropdown.Item
              key="Requeue"
              onClick={() => openModal('requeue', order)}>
              {setTitle(order.status)}
            </Dropdown.Item>
          ),
          statuses: [0, 1, 2, 5],
          roles: [JOB_MANAGER, JOB_COORDINATOR, CUSTOMER],
          if: o =>
            (o.driver_id == null && (o.status === 0 || o.status === 1)) ||
            o.status === 2 ||
            o.status === 5,
        },
        {
          component: (
            <Dropdown.Item
              key="Requeue"
              onClick={() => openModal('requeue', order)}>
              {setTitle(order.status)}
            </Dropdown.Item>
          ),
          statuses: [0, 1, 2, 5],
          roles: [CARRIER],
          if: () => {
            if (details && details.details) {
              return !!details.details.isFullEdit;
            }
            return true;
          },
        },
      ]
    : [];

  if (
    authorize({
      itemAccessGranted: !order.is_deleted,
      abilityPermissions: [
        {
          resource: 'Orders',
          permissions: ['delete'],
        },
      ],
    }) &&
    order.billing_status !== 21 &&
    order.billing_status !== 22 &&
    order.billing_status !== 23
  ) {
    components.push({
      component: (
        <Dropdown.Item
          key="delete"
          onClick={() => confirmAlert(() => deleteOrder(order))}>
          Delete
        </Dropdown.Item>
      ),
      statuses: allStatuses,
      roles: [VENDOR, CUSTOMER, JOB_COORDINATOR, JOB_MANAGER, CARRIER],
    });
  }
  if (
    authorize({
      itemAccessGranted: !order.is_deleted,
      abilityPermissions: [
        {
          resource: 'Orders',
          permissions: ['update'],
        },
      ],
    })
  ) {
    components.unshift({
      component: (
        <Dropdown.Item key="modify" onClick={() => openModal('modify', order)}>
          Modify & Complete Order
        </Dropdown.Item>
      ),
      statuses: allStatuses,
      roles: [VENDOR, CUSTOMER, JOB_COORDINATOR, JOB_MANAGER, CARRIER],
    });
  }

  if (
    order.is_deleted &&
    authorize({
      abilityPermissions: [
        {
          resource: 'Orders',
          permissions: ['update'],
        },
      ],
    })
  ) {
    components.push({
      component: (
        <Dropdown.Item
          key="revive"
          onClick={() => confirmAlert(() => reviveOrder(order))}>
          Revive Order
        </Dropdown.Item>
      ),
      statuses: allStatuses,
      roles: [JOB_MANAGER, CUSTOMER, CARRIER],
    });
  }

  if (Number(user.companyType) !== 4) {
    components.splice(2, 0, {
      component: (
        <Dropdown.Item
          key="transfer"
          onClick={() => openModal('transfer', order)}>
          Transfer
        </Dropdown.Item>
      ),
      statuses: [2, 3, 5, 6, 7, 8],
      roles: [JOB_MANAGER, CARRIER, CUSTOMER],
    });
  }

  return (
    <span>
      {exportComponent.modals && (
        <span>
          {modify.openModal && (
            <Modify
              openModal={modify.openModal}
              turnModal={() => openModal('modify')}
            />
          )}
          {showModalDemurrage && <DemurrageReason />}
          {uploadST.openModal && (
            <UploadST
              openModal={uploadST.openModal}
              turnModal={() => openModal('uploadST')}
            />
          )}
          {divert.openModal && (
            <Divert
              openModal={divert.openModal}
              turnModal={() => openModal('divert')}
            />
          )}
          {transfer.openModal && (
            <Transfer
              openModal={transfer.openModal}
              turnModal={() => openModal('transfer')}
            />
          )}
          {requeue.openModal && (
            <Requeue
              openModal={requeue.openModal}
              setTitle={setTitle}
              turnModal={() => openModal('requeue')}
            />
          )}
        </span>
      )}
      {exportComponent.actions && (
        <Dropdown style={{ display: 'inline' }}>
          <span key="Download">
            <Dropdown.Toggle
              as="button"
              className="button button--label-type button--orange">
              Actions
              <Icon icon="down" className="icon--margin-left" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="dropdown-user">
              {components.map(
                item =>
                  item.statuses.includes(order.status) &&
                  item.roles.includes(user.role) &&
                  item.component,
              )}
            </Dropdown.Menu>
          </span>
        </Dropdown>
      )}
    </span>
  );
};

const mapStateToProps = state => ({
  orderActions: state.orderActions,
  showModalDemurrage: state.orderActions.demurrageReason.openModal,
});

const mapDispatchToProps = dispatch => ({
  openModal: (modal, order) => dispatch(actions.openModal(modal, order)),
  releaseDriver: orderId => dispatch(actions.releaseDriver(orderId)),
  callToWell: order => dispatch(actions.callToWell(order)),
  deleteOrder: order => dispatch(actions.deleteOrder(order)),
  reviveOrder: order => dispatch(actions.reviveOrder(order)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderActions);
