import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import targetValue from 'utils/targetValue';

import actions from 'store/actions/JobsList';

const QuickBooksModal = ({
  showQuickBooks,
  closeQuickBooks,
  clear,
  setValue,
  save,
  data,
}) => {
  const {
    isLoaded,
    isSyncing,
    qbProjectId,
    qbCustomerId,
    qbLinkUrl,
    jobName,
    jobId,
  } = data;

  useEffect(
    () => () => {
      clear();
    },
    [],
  );

  return (
    <Modal
      style={{ top: '10%' }}
      className="modal-container"
      show={showQuickBooks}
      onHide={closeQuickBooks}>
      <Modal.Body>
        <h3 className="modal-title">Link QuickBooks Project</h3>
        {isLoaded ? (
          <div className="content" style={{ padding: '20px 10px' }}>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'qbProjectId')}
                  value={qbProjectId}
                  label="QuickBooks ID"
                  disabled
                  testSelector="jobs_quickbook-modal_id_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                {isSyncing ? (
                  <Loader />
                ) : (
                  <div>
                    {qbProjectId ? (
                      <Button
                        onClick={() => {
                          save(jobId, false);
                        }}
                        colour="red"
                        testSelector="jobs-list_quick-book_unsync_btn">
                        Unsync
                      </Button>
                    ) : (
                      <Button
                        onClick={() => {
                          save(jobId, true);
                        }}
                        colour="green"
                        testSelector="jobs-list_quick-book_sync_btn">
                        Sync
                      </Button>
                    )}
                  </div>
                )}
              </div>
              <div className="form-group has-feedback col-md-12">
                {!qbProjectId && (
                  <a
                    href={`${qbLinkUrl}customerdetail?nameId=${qbCustomerId}`}
                    target="_blank"
                    title="navigate to QuickBooks"
                    rel="noreferrer">
                    {`${qbLinkUrl}customerdetail?nameId=${qbCustomerId}`}
                  </a>
                )}
              </div>
              <div className="form-group has-feedback col-md-12">
                {!qbProjectId && <>PROJECT NAME: {jobName}</>}
              </div>
            </Row>
          </div>
        ) : (
          <Loader />
        )}
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={closeQuickBooks}
          colour="white"
          testSelector="jobs-list_quick-book_close_btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  data: state.jobsList.quickBooksModal,
});

const mapDispatchToProps = dispatch => ({
  clear: () => dispatch(actions.clearQuickBooksModal()),
  setValue: (e, name) =>
    dispatch(actions.setQuickBooksValue(targetValue(e), name)),
  save: (jobId, link) => dispatch(actions.saveQuickBooks(jobId, link)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(QuickBooksModal);
