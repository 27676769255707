import React, { useEffect, useMemo, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import DatePicker from 'react-datetime';
import moment from 'moment';

import Authorize from 'components/common/Authorize';
import Select from 'components/shared/Select';
import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';

import { useLocations } from 'api/v2/locations';
import { filterOptions } from 'utils/selectUtils';
import multiSelectOptions from 'utils/multiSelectOptions';

const WaterJob = ({ props, userCustomer }) => {
  const {
    closeAddJobModal,
    setModalValue,
    addJob,
    addJobInitData,
    selectedCustomer,
    saveJob,
    setStartDate,
    selectData,
    getBillingProfiles,
  } = props;
  const {
    name,
    customerId,
    wellSiteId,
    startDate,
    load_weight: loadWeight,
    certificates,
    sandTicketDesign,
    districtIds,
    directions,
    customerJobId,
    billingProfileId,
    stagingSite,
  } = addJob;
  const {
    customers,
    wellSites,
    certificatesList,
    sandTicketTypes,
    districts,
    billingProfiles,
    companyArray,
  } = addJobInitData;

  const [additionalOperatorsList, updateOperatorsList] = useState([]);
  const { data: locations } = useLocations();
  const locationsOptions = useMemo(
    () =>
      (locations || [])
        .filter(item => item.locationType === 4)
        .map(i => ({
          value: i.id,
          label: i.name,
        })),
    [locations],
  );

  const formattedCustomers = customers
    .filter(
      item => item.accessLevel === 'owner' || item.accessLevel === 'shared',
    )
    .map(item => {
      item.label = `${item.id} | ${item.name}`;
      return item;
    });
  const formattedWellSites = wellSites.map(item => {
    item.label = `${item.id} | ${item.name}`;
    return item;
  });
  const billingProfilesList = billingProfiles.map(item => ({
    value: item.id,
    label: item.name,
  }));
  const customersList = filterOptions(
    formattedCustomers,
    null,
    null,
    null,
    'label',
  );
  const wellSitesList = filterOptions(
    formattedWellSites,
    null,
    null,
    null,
    'label',
  );

  useEffect(() => {
    const array = multiSelectOptions(companyArray, 'id', 'name').filter(
      ({ value }) =>
        Number(value.substring(0, value.indexOf('/'))) !== customerId,
    );
    updateOperatorsList(array);
  }, [customerId]);

  return (
    <form className="wizard-big wizard" onSubmit={saveJob}>
      <Modal.Body>
        <div className="content" style={{ padding: '20px 10px' }}>
          <h4 className="title current">Job</h4>
          <fieldset id="form-p-0" role="tabpanel" className="body current">
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setModalValue(e, 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="jobs_add_water-job_naem_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Choose Customer"
                  onChange={item => {
                    setModalValue(item.value, 'customerId');
                    selectedCustomer(closeAddJobModal);
                    getBillingProfiles();
                  }}
                  options={customersList}
                  value={customersList.find(item => item.value === customerId)}
                  required
                  isDisabled={userCustomer}
                  testSelector="jobs_add_water-job_customer_select"
                />
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Select
                  isMulti
                  closeMenuOnSelect={false}
                  onChange={selected => setModalValue(selected, 'districtIds')}
                  options={multiSelectOptions(districts, 'id', 'name')}
                  placeholder="Select district"
                  defaultValue={districtIds}
                  required
                  testSelector="jobs_add_water-job_districts_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Please Select Wellsite"
                  onChange={item => setModalValue(item.value, 'wellSiteId')}
                  options={wellSitesList}
                  value={
                    wellSiteId &&
                    wellSitesList.find(item => item.value === wellSiteId)
                  }
                  required
                  isDisabled={wellSites.length === 0}
                  testSelector="jobs_add_water-job_wellsite_select"
                />
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Select
                  placeholder="Please Select Billing Profile"
                  onChange={e => setModalValue(e.value, 'billingProfileId')}
                  value={
                    billingProfileId &&
                    billingProfilesList.find(k => k.value === billingProfileId)
                  }
                  options={billingProfilesList}
                  isDisabled={wellSites.length === 0}
                  testSelector="jobs_add_water-job_billing_profile_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <div className="cleafix">
                  <DatePicker
                    value={startDate}
                    onChange={setStartDate}
                    className="form-input__calendar"
                    placeholderText="Start Date"
                    data-testid="job-details_add-job_water_start-date_dp"
                    id="job-details_add-job_water_start-date_dp"
                    name="job-details_add-job_water_start-date_dp"
                  />
                  {moment().isSameOrAfter(startDate) && (
                    <small style={{ color: 'rgba(239, 61, 61, 0.483)' }}>
                      * Incorrect start date
                    </small>
                  )}
                </div>
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="col-md-6">
                <div className="form-group has-feedback ">
                  <Input
                    type="number"
                    onChange={e => setModalValue(e, 'load_weight')}
                    value={loadWeight}
                    label="Load volume, bbl"
                    required
                    min="1"
                    max="60000"
                    testSelector="jobs_add_water-job_load-volume_input"
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group has-feedback">
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={selected =>
                      selectData(selected, 'sandTicketDesign')
                    }
                    options={multiSelectOptions(
                      sandTicketTypes,
                      'sandTicketTypeId',
                      'name',
                    )}
                    placeholder="Select Ticket types"
                    defaultValue={sandTicketDesign}
                    testSelector="jobs_add_water-job_ticket_types_select"
                  />
                  {sandTicketDesign.length === 0 && (
                    <small style={{ color: 'rgba(239, 61, 61, 0.483)' }}>
                      * Need to select ticket type
                    </small>
                  )}
                </div>
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="col-md-6">
                <div className="form-group has-feedback ">
                  <Select
                    isMulti
                    closeMenuOnSelect={false}
                    onChange={selected => selectData(selected, 'certificates')}
                    options={multiSelectOptions(certificatesList, 'id', 'name')}
                    placeholder="Select certificate"
                    defaultValue={certificates}
                    testSelector="jobs_add_water-job_certificates_select"
                  />
                  {certificates.length === 0 && (
                    <small style={{ color: 'rgba(239, 61, 61, 0.483' }}>
                      * Need to select certificate
                    </small>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group has-feedback ">
                  <Authorize
                    {...{
                      access: ['JOB_MANAGER', 'CUSTOMER'],
                    }}>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={selected =>
                        setModalValue(selected, 'operatorList')
                      }
                      options={additionalOperatorsList}
                      placeholder="Additional Operators"
                      testSelector="jobs_add_water-job_additional_operators_select"
                    />
                  </Authorize>
                </div>
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setModalValue(e, 'customerJobId')}
                  value={customerJobId}
                  label="Customer job ID"
                  testSelector="jobs_add_water-job_customer-job-id_input"
                />
              </div>
              <div className="col-md-6">
                <div className="form-group has-feedback ">
                  <Textarea
                    label="Directions"
                    onChange={e => setModalValue(e, 'directions')}
                    value={directions}
                    required
                    testSelector="jobs_add_water-job_directions_input"
                  />
                </div>
              </div>
            </Row>
            <Row className="no-outer-gutter">
              <div className="form-group has-feedback col-md-6">
                <Select
                  closeMenuOnSelect={false}
                  onChange={e =>
                    setModalValue(e && e.value ? e.value : '', 'stagingSite')
                  }
                  value={
                    stagingSite &&
                    locationsOptions.find(k => k.value === stagingSite)
                  }
                  options={locationsOptions}
                  placeholder="Select Staging Site"
                  testSelector="jobs_add_staging-site_select"
                  isClearable
                />
              </div>
            </Row>
            {/* <Row className="no-outer-gutter">
                <div className="form-group has-feedback col-md-6">
                  <Input
                    type="checkbox"
                    label="Send completion code to driver"
                    testSelector="jobs-list_add-job_water-job_send-code-to-driver_input"
                    isChecked={sendCompletionCodeToDriver}
                    onChange={e =>
                      setModalValue(e, 'sendCompletionCodeToDriver')
                    }
                  />
                </div>
              </Row> */}
          </fieldset>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddJobModal}
          colour="white"
          testSelector="jobs-list_add-modal_water-job_close_btn">
          Close
        </Button>
        <Button
          type="submit"
          disabled={moment().isSameOrAfter(startDate)}
          testSelector="jobs-list_add-modal_water-job_save_btn">
          Save
        </Button>
      </Modal.Footer>
    </form>
  );
};
export default WaterJob;
