import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Authorize from 'components/common/Authorize';
import OnsiteStorageType from './OnsiteStorageType';
import { useJobDesignCommodities } from './hooks';

const OnsiteStorage = ({
  closeAddJobModal,
  addJob,
  setStep,
  saveDraftJob,
  saveJob,
  setArrayValue,
  deleteArrayValue,
  addArrayValue,
  onsiteStorageTypes,
  setOnsiteStorageType,
}) => {
  const { jobDesign } = addJob;
  const storageConfig = useSelector(
    state => state.jobsList.jobs.addJob.storageConfig,
  );

  const commodityOptions = useJobDesignCommodities(jobDesign, addJob.customerId);
  
  const [saveClicked, setSaveClicked] = useState(false);

  const handleSubmit = e => {
    setSaveClicked(true);
    saveJob(e);
  };

  const renderOnsiteStorage = (item, index) => (
    <OnsiteStorageType
      filteredCommodityOptions={commodityOptions}
      setArrayValue={setArrayValue}
      deleteArrayValue={deleteArrayValue}
      onsiteStorageTypes={onsiteStorageTypes}
      setOnsiteStorageType={setOnsiteStorageType}
      item={item}
      index={index}
      key={index}
      customerId={addJob.customerId}
    />
  );

  return (
    <form className="wizard-big wizard" onSubmit={handleSubmit}>
      <Modal.Body>
        <div style={{ padding: '20px 10px' }}>
          <div className="step3">
            <h4 className="title">Storage Details</h4>
            <fieldset id="form-p-2" role="tabpanel" className="body">
              {storageConfig.map(renderOnsiteStorage)}
            </fieldset>
            <Button
              theme="small"
              testSelector="jobs-list_add-modal_onsite-storage_add-item_btn"
              onClick={() => addArrayValue('storageConfig')}>
              <Icon icon="plus" /> Add Storage
            </Button>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAddJobModal}
          colour="white"
          testSelector="jobs-list_add-modal_onsite-storage_close_btn">
          Close
        </Button>
        <Button
          onClick={() => setStep(4)}
          testSelector="jobs-list_add-modal_onsite-storage_previous_btn">
          Previous
        </Button>
        <Authorize forbidden={['CARRIER']}>
          <Button
            testSelector="jobs-list_add-modal_job-design_save_btn"
            onClick={() => saveDraftJob(closeAddJobModal)}>
            Save Draft
          </Button>
        </Authorize>
        <Button
          type="submit"
          disabled={saveClicked}
          testSelector="jobs-list_add-modal_onsite-storage_next_btn">
          Submit
        </Button>
      </Modal.Footer>
    </form>
  );
};

export default OnsiteStorage;
