import swal from 'bootstrap-sweetalert';
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import authService from 'services/auth/service';
import {
  getDistricts,
  removeDistrict,
  editDistrict,
  addDistrict,
  getDistrictDetails,
  getDistrictUnits,
  addDistrictUnit,
  editDistrictUnit,
  addCarrierRatioToDistrict,
  addDistrictCarrier,
  getNotAddedDistricts
} from './queries';

export const useDistricts = () =>
  useQuery({
    queryKey: ['districts'],
    queryFn: getDistricts,
    placeholderData: [],
  });

export const useRemoveDistrict = (id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['districts', id],
    mutationFn: districtId => removeDistrict({ districtId }),
    onSuccess: () => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries(['districts']);
    },
    onError: error => {
      swal(error.response.data.message, '', 'error');
    }
  });
}

export const useEditDistrict = (id) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['districts', id],
    mutationFn: data => editDistrict(data),
    onSuccess: () => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries(['districts']);
    },
    onError: error => {
      swal(error.response.data.message, '', 'error');
    }
  });
}

export const useAddDistrict = () => {
  const queryClient = useQueryClient();
  const user = authService.getUser();

  return useMutation({
    mutationKey: ['districts'],
    mutationFn: data => addDistrict({ ...data, companyOwnerId: user.entityId, companyOwnerType: user.entityType }),
    onSuccess: () => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries(['districts']);
    },
    onError: error => {
      swal(error.response.data.message, '', 'error');
    }
  });
}

export const useDistrictDetails = (id) =>
  useQuery({
    queryKey: ['districts', Number(id), 'details'],
    queryFn: () => getDistrictDetails({ id }),
  });

export const useAddDistrictUnit = (districtId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['districts'],
    mutationFn: data => addDistrictUnit(data),
    onSuccess: () => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries(['districts', districtId, 'units']);
    },
    onError: error => {
      swal(error.response.data.message, '', 'error');
    }
  });
}

export const useEditDistrictUnit = (districtId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['districts'],
    mutationFn: data => editDistrictUnit(data),
    onSuccess: () => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries(['districts', districtId, 'units']);
    },
    onError: error => {
      swal(error.response.data.message, '', 'error');
    }
  });
}

export const useDistrictUnits = (districtId) =>
  useQuery({
    queryKey: ['districts', districtId, 'units'],
    queryFn: () => getDistrictUnits({ id: districtId }),
  });

export const useAddCarrierRatioToDistrict = (districtId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['districts'],
    mutationFn: data => addCarrierRatioToDistrict(data),
    onSuccess: () => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries(['districts', districtId, 'details']);
    },
    onError: error => {
      swal(error.response.data.message, '', 'error');
    }
  });
}

export const useAddDistrictCarrier = (districtId) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationKey: ['districts'],
    mutationFn: data => addDistrictCarrier(data),
    onSuccess: () => {
      swal('Success!', '', 'success');
      queryClient.invalidateQueries(['districts', districtId, 'details']);
    },
    onError: error => {
      swal(error.response.data.message, '', 'error');
    }
  });
}

export const useNotAddedDistricts = (carrierId) =>
  useQuery({
    queryKey: ['districts', carrierId, 'not-added'],
    queryFn: () => getNotAddedDistricts(carrierId),
  });
