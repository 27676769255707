import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  getLocations,
  getPaginatedLocations,
  addLocation,
  getCustomerLocations,
  editLocation,
  getLocationDetails,
  deleteLocation,
} from './queries';

/**
 *
 * @param {{
 *  type: number[];
 *  includeAlerts: boolean;
 * }} params
 */
export const useLocations = ({ type, includeAlerts } = {}) =>
  useQuery({
    queryKey: ['locations', { type, includeAlerts }],
    queryFn: () => getLocations({ type, includeAlerts }),
    placeholderData: []
  });

export const usePaginatedLocations = ({ type, includeAlerts, page, pageSize, id, name, includeDeleted }) =>
  useQuery({
    queryKey: ['locations', { type, includeAlerts, page, pageSize, id, name, includeDeleted }],
    queryFn: () => getPaginatedLocations({ type, includeAlerts, page, pageSize, id, name, includeDeleted }),
    placeholderData: { totalPages: 10, locations: [] }
  });

/**
 *
 * @param {number} locationId
 */
export const useLocationDetails = locationId =>
  useQuery({
    queryKey: ['locations', locationId],
    queryFn: () => getLocationDetails(locationId),
    enabled: !!locationId,
  });

/**
 *
 * @param {number} customerId
 */
export const useCustomerLocations = customerId =>
  useQuery({
    queryKey: ['locations', { type: 'customer', customerId }],
    queryFn: () => getCustomerLocations({ customerId }),
    enabled: !!customerId,
  });

export const useAddLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addLocation,
    onSuccess: () => queryClient.invalidateQueries(['locations']),
  });
};

/**
 *
 * @param {number} locationId
 */
export const useUpdateLocation = locationId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['locations', locationId],
    mutationFn: location => editLocation({ locationId, location }),
    onSuccess: () => queryClient.invalidateQueries(['locations']),
  });
};

export const useDeleteLocation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationKey: ['locations'],
    mutationFn: deleteLocation,
    onSuccess: () => {
      queryClient.invalidateQueries(['locations']);
      swal('Successfully Deleted Location', '', 'success');
    },
  });
};

