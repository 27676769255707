import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/AddCustomer';
import { SelectAction } from './helpers';

const AddCustomer = ({
  init,
  clear,
  showModalAdd,
  closeAdd,
  setStep,
  setCompanyValue,
  setBillValue,
  setConfigValue,
  addSubmit,
  addCustomer,
  shareCustomerWithCompany,
}) => {
  const { step } = addCustomer;
  useEffect(() => {
    init();
    return () => {
      clear();
    };
  }, []);

  return (
    <Modal className="modal-container" show={showModalAdd} onHide={closeAdd}>
      <div className="ibox">
        <Modal.Header>
          <h3 className="modal-title">Add Customer</h3>
        </Modal.Header>
        <div className="ibox-content ibox-content-no-shade">
          <span className="wizard-big wizard">
            <div className="steps">
              <ul role="tablist">
                <li
                  role="tab"
                  className={step == 1 ? 'current' : 'disabled'}
                  onClick={() => setStep(2)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">1.</span> Search
                  </a>
                </li>
                <li
                  role="tab"
                  className={step == 2 ? 'current' : 'disabled'}
                  onClick={() => setStep(3)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">2.</span> Company Info
                  </a>
                </li>
                <li
                  role="tab"
                  className={step == 3 ? 'current' : 'disabled'}
                  onClick={() => setStep(4)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">3.</span> Billing Address
                  </a>
                </li>
                <li
                  role="tab"
                  className={step == 4 ? 'current' : 'disabled'}
                  onClick={() => setStep(5)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">4.</span> Configuration
                  </a>
                </li>
                <li
                  role="tab"
                  className={step == 5 ? 'current' : 'disabled'}
                  onClick={() => setStep(6)}
                  style={{ width: '25%' }}>
                  <a>
                    <span className="number">5.</span> Billing Terms
                  </a>
                </li>
              </ul>
            </div>
            {SelectAction(
              setStep,
              addSubmit,
              setCompanyValue,
              setBillValue,
              setConfigValue,
              addCustomer,
              closeAdd,
              shareCustomerWithCompany,
            )}
          </span>
        </div>
      </div>
    </Modal>
  );
};

const mapStateToProps = state => ({
  addCustomer: state.manageCustomers.addCustomer,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setBillValue: (e, name) =>
    dispatch(actions.setBillValue(targetValue(e), name)),
  setConfigValue: (e, name) =>
    dispatch(actions.setConfigValue(targetValue(e), name)),
  setCompanyValue: (e, name) =>
    dispatch(actions.setCompanyValue(targetValue(e), name)),
  clear: () => dispatch(actions.clear()),
  init: () => dispatch(actions.init()),
  addSubmit: (e, setStep) => {
    e.preventDefault();
    dispatch(actions.addSubmitAfterCustomer(setStep, e));
  },
  setStep: (step, e) => {
    if (e) e.preventDefault();
    dispatch(actions.setStep(step));
  },
  shareCustomerWithCompany: (closeModal, customerId) => {
    dispatch(actions.shareCustomer(closeModal, customerId));
  },
});

export const AddCustomerTest = AddCustomer; // for Tests

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddCustomer);
