import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderDetails';
import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import Label from 'components/shared/Label';
import { useParams } from 'react-router-dom';
import wellStatus from 'components/globalInfo/callToWellStatus';
import { Card, Row } from 'react-bootstrap';
import OrderHeader from './Header';
import Info from './MainInfo';
import OrderTabs from './Tabs';
import DriverInfo from './DriverInfo';
import JobInfo from './JobInfo';
import NotFound from './NotFound';

const OrderDetails = ({ info, init, equipmentRequired }) => {
  const { countAPI, count, order } = useMemo(() => info, [info]);
  const { orderId } = useParams();

  if (!order) {
    window.location = `/${window.location.pathname.split('/')[1]}/home`;
  }

  const {
    equipment,
    manual_dispatch_enabled: manualDispatchEnabled,
    aprrovedBySupport,
    hasMissingSandTickets,
    call_to_well_status: callToWellStatus,
  } = useMemo(() => order, [order]);

  useEffect(() => {
    init(orderId);
  }, [init, orderId]);

  if (count !== countAPI) {
    return <Loader />;
  }

  return (
    <div>
      <Header title="Order Details" />
      <Authorize
        abilityPermissions={[{ resource: 'Orders', permissions: ['read'] }]}>
        {order ? (
          <Row>
            <div
              className="col-lg-9 col-md-9 col-sm-9"
              style={{ paddingLeft: 0 }}>
              <Card>
                <Card.Header>
                  <OrderHeader />
                </Card.Header>
                <Card.Body>
                  <Info />
                  <OrderTabs />
                </Card.Body>
              </Card>
            </div>
            <div
              className="col-lg-3 col-md-3 col-sm-3"
              style={{ paddingRight: 0 }}>
              <div className="wrapper-content project-manager">
                <div style={{ padding: '0.65rem 0' }}>
                  {!!equipment && (
                    <Label type="margin-bottom">
                      {equipmentRequired.map(
                        item => item.id === equipment && item.title,
                      )}
                    </Label>
                  )}
                  {manualDispatchEnabled === 1 && (
                    <Label type="margin-bottom">Manual</Label>
                  )}
                  {aprrovedBySupport === 1 && (
                    <Label type="sucess label--margin-bottom">
                      Approved by Support
                    </Label>
                  )}
                  {hasMissingSandTickets === 1 && (
                    <Label type="danger label--margin-bottom">
                      Missing Tickets
                    </Label>
                  )}
                  {callToWellStatus === 1 && (
                    <span
                      className={`label label-${
                        wellStatus[callToWellStatus].color
                      } label--margin-bottom`}>
                      {wellStatus[callToWellStatus].title}
                    </span>
                  )}
                </div>
                <DriverInfo />
                <JobInfo />
              </div>
            </div>
          </Row>
        ) : (
          <NotFound />
        )}
      </Authorize>
    </div>
  );
};

const mapStateToProps = state => ({
  info: state.orderDetails.info,
  equipmentRequired: state.lookUp.equipmentRequired,
});

const mapDispatchToProps = dispatch => ({
  init: orderId => dispatch(actions.init(orderId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderDetails);
