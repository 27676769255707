import React from 'react';
import { connect } from 'react-redux';
import Label from 'components/shared/Label';

const JobInfo = props => {
  const {
    details,
    order: { operation_type, carrier_email, carrier_dispatch_email },
  } = props;
  const {
    sendCompletionCodeToDriver,
    jobManagerName,
    jobPumpTime,
    jobWirelineTime,
    jobDirections,
    jobName,
    isTestJob,
    districts,
  } = details;

  const orderDetails = [
    // {
    //   title: 'Send completion code to driver',
    //   value: sendCompletionCodeToDriver ? 'Yes' : 'No',
    // },
    {
      title: 'Job Manager',
      value: jobManagerName,
    },
    {
      title: 'Carrier Email',
      value: carrier_email,
    },
    {
      title: 'Carrier Dispatch Email',
      value: carrier_dispatch_email,
    },
    {
      title: 'Pump Time/Wireline Time',
      value: `${jobPumpTime}/${jobWirelineTime}`,
    },
    {
      title: 'Directions',
      value: jobDirections,
    },
  ];

  return (
    <div>
      <h3 style={{ marginBottom: 0 }}>
        Job <span className="text-navy">/ {jobName}</span>
      </h3>
      {Boolean(isTestJob) && <Label type="warning">test job</Label>}
      <table className="table">
        <tbody>
          <tr>
            <td>
              <strong>Districts: </strong>
              {districts.map(item => `#${item.id} | ${item.name}`).join(', ')}
            </td>
          </tr>
          {orderDetails.map(item => {
            if (
              item.title === 'Pump Time/Wireline Time' &&
              operation_type === 12
            ) {
              return null;
            }
            if (item.title === 'Carrier Dispatch Email' && !item.value) {
              return null;
            }
            if (item.title === 'Carrier Email' && !item.value) {
              return null;
            }
            return (
              <tr key={item.title}>
                <td>
                  <strong>{item.title}: </strong> {item.value}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const mapStateToProps = state => ({
  details: state.orderDetails.info.details,
  order: state.orderDetails.info.order,
});

export default
  connect(
    mapStateToProps,
    null
  )(JobInfo);
