import React, { useEffect } from 'react';
import { Modal, Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/OrdersList';
import Button from 'components/shared/Button';
import { authorize } from 'components/common/Authorize';
import Timeline from './Timeline';
import Comments from './Comments';
import Info from './Info';
import Price from './Price';

const OrderInfo = props => {
  const {
    order,
    getPrices,
    clearState,
    turnModalInfo,
    changeKey,
    prices,
    openModal,
    tabKey,
  } = props;
  const { order_id } = order;

  useEffect(() => {
    getPrices(order_id);
    return () => clearState();
  }, []);

  return (
    <Modal className="modal-container" show={openModal} onHide={turnModalInfo}>
      <Modal.Header>
        <h3 className="modal-title">Order Info</h3>
      </Modal.Header>
      <Modal.Body>
        <div className="panel blank-panel tab">
          <Tabs
            activeKey={tabKey}
            onSelect={changeKey}
            id="controlled-tab-example">
            <Tab eventKey={1} title="Order Info">
              <div>{tabKey == 1 && <Info {...{ order }} />}</div>
            </Tab>
            {authorize({ forbidden: ['JOB_COORDINATOR'] }) && (
              <Tab eventKey={2} title="Price">
                <div>{tabKey == 2 && <Price {...{ order, prices }} />}</div>
              </Tab>
            )}
            <Tab eventKey={3} title="Timeline">
              <div>{tabKey == 3 && <Timeline />}</div>
            </Tab>
            <Tab eventKey={4} title="Comments">
              <div>{tabKey == 4 && <Comments />}</div>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={turnModalInfo}
          testSelector="order-list_order-info_close_btn">
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const mapStateToProps = state => ({
  tabKey: state.orderList.modalOrderInfo.key,
  openModal: state.orderList.modalOrderInfo.openModal,
  order: state.orderList.modalOrderInfo.order,
  prices: state.orderList.modalOrderInfo.prices,
});

const mapDispatchToProps = dispatch => ({
  changeKey: value => dispatch(actions.changeKey(value)),
  turnModalInfo: () => dispatch(actions.turnModalInfo()),
  getPrices: orderId => dispatch(actions.getPrices(orderId)),
  clearState: () => dispatch(actions.clearState()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderInfo);
