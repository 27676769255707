import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import swal from 'bootstrap-sweetalert';
import GoogleMap, {
  AutomatizeMarker,
  polyCoords,
  milesToMeters,
} from 'components/shared/GoogleMap';
import { Polygon, Polyline } from 'google-maps-react';
import Icon from 'components/shared/Icon';
import { convertTimeWithSeconds } from 'utils/convertTime';
import { locationColors } from 'components/globalInfo/polygonColors';
import { orderStatusMap, jobOrderStatus } from 'config/constants';
import { checkLocationIcon } from './helpers';

const OrderDetailsMap = props => {
  const {
    breadcrumb: { jobDetails, driverJourneyLocationPoints, snappedPoints },
    order,
  } = props;


  const { sandSite, wellSite, driverName } = jobDetails;
  const { status } = order;
  const driverPoints = [...driverJourneyLocationPoints];

  let initialCenter = {};
  if (driverPoints.length) {
    // Set center on last driver' location
    initialCenter = {
      lat: Number(driverPoints[driverPoints.length - 1].latitude),
      lng: Number(driverPoints[driverPoints.length - 1].longitude),
    };
  } else {
    const viewLat = (Number(sandSite.latitude) + Number(wellSite.latitude)) / 2;
    const viewLon =
      (Number(sandSite.longitude) + Number(wellSite.longitude)) / 2;
    // Center between sandsite and wellsite
    initialCenter = { lat: viewLat, lng: viewLon };
  }

  const formattedPoints = driverPoints.length
    ? driverPoints.map(item => ({
      lat: item.latitude,
      lng: item.longitude,
    }))
    : null;
  const wellSiteCoords = {
    lat: order.well_site_latitude,
    lng: order.well_site_longitude,
  };
  const sandSiteCoords = {
    lat: order.sand_site_latitude,
    lng: order.sand_site_longitude,
  };
  let stagingSiteCoords;
  if (order.staging_site_latitude) {
    stagingSiteCoords = {
      lat: order.staging_site_latitude,
      lng: order.staging_site_longitude,
    };
  }

  const locations = [
    {
      position: {
        lat: Number(sandSite.latitude),
        lng: Number(sandSite.longitude),
      },
      type: checkLocationIcon(sandSite.type),
      icon: checkLocationIcon(sandSite.type),
      title: sandSite.name,
      iconHeight: 40,
      iconWidth: 40,
    },
    {
      position: {
        lat: Number(wellSite.latitude),
        lng: Number(wellSite.longitude),
      },
      type: checkLocationIcon(wellSite.type),
      icon: checkLocationIcon(wellSite.type),
      title: wellSite.name,
      iconHeight: 40,
      iconWidth: 40,
    },
  ];
  if (jobDetails.stagingSite) {
    locations.push({
      position: {
        lat: jobDetails.stagingSite.latitude,
        lng: jobDetails.stagingSite.longitude,
      },
      type: 'staging_site',
      icon: 'location_pending',
      title: jobDetails.stagingSite.name,
      iconHeight: 40,
      iconWidth: 40,
    });
  }
  if (driverPoints.length > 0) {
    locations.push({
      position: {
        lat: driverPoints[driverPoints.length - 1].latitude,
        lng: driverPoints[driverPoints.length - 1].longitude,
      },
      type: 'driver',
      icon: 'driver',
      status:
        status === jobOrderStatus.DepartLoadingSite ||
          status === jobOrderStatus.Completed ||
          status === jobOrderStatus.EnteringWellSite ||
          status === jobOrderStatus.EnRouteToStage ||
          status === jobOrderStatus.ArrivedAtStage
          ? 'loaded'
          : 'empty',
      title: driverName,
      iconHeight: 40,
      iconWidth: 40,
    });
    locations.push({
      position: {
        lat: driverPoints[0].latitude,
        lng: driverPoints[0].longitude,
      },
      type: 'start',
      icon: 'driver',
      title: 'Order Accepted',
      content: (
        <span style={{ fontSize: 16 }}>
          Time: {convertTimeWithSeconds(driverPoints[0].date)}
        </span>
      ),
      iconHeight: 40,
      iconWidth: 40,
    });
    driverPoints.shift();
    driverPoints.pop();
    driverPoints.forEach(item => {
      if (item.status === jobOrderStatus.JobAccepted || item.status === jobOrderStatus.EnteringLoadingSite) {
        locations.push({
          position: {
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          },
          type: 'to_origin',
          icon: 'driver',
          title: orderStatusMap[item.status],
          content: (
            <span style={{ fontSize: 16 }}>
              Time: {convertTimeWithSeconds(item.date)}
            </span>
          ),
          iconHeight: 10,
          iconWidth: 10,
          status: item.status
        });
      }
      if (item.status === jobOrderStatus.DepartLoadingSite) {
        locations.push({
          position: {
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          },
          type: 'to_destination',
          icon: 'driver',
          title: jobDetails?.stagingSite ? 'En Route To Stage' : 'En Route to Destination',
          content: (
            <span style={{ fontSize: 16 }}>
              Time: {convertTimeWithSeconds(item.date)}
            </span>
          ),
          iconHeight: 10,
          iconWidth: 10,
          status: item.status
        });
      }
      if (item.status === jobOrderStatus.Completed || item.status === jobOrderStatus.EnteringWellSite || item.status === jobOrderStatus.EnRouteToStage || item.status === jobOrderStatus.ArrivedAtStage) {
        locations.push({
          position: {
            lat: Number(item.latitude),
            lng: Number(item.longitude),
          },
          type: 'to_destination',
          icon: 'driver',
          title: orderStatusMap[item.status],
          content: (
            <span style={{ fontSize: 16 }}>
              Time: {convertTimeWithSeconds(item.date)}
            </span>
          ),
          iconHeight: 10,
          iconWidth: 10,
          status: item.status
        });
      }
    });
  }
  const onReady = (pointData, google, map) => {
    const chunkedPoints = _.chunk(pointData, 24);

    chunkedPoints.forEach((pointArray, index) => {
      const directionsService = new google.maps.DirectionsService();
      const directionsDisplay = new google.maps.DirectionsRenderer({
        map,
        suppressMarkers: true,
        preserveViewport: true,
        polylineOptions: {
          strokeColor: 'green',
        },
      });

      const waypoints = pointArray.map(item => ({
        location: { lat: item.lat, lng: item.lng },
      }));

      const origin = waypoints.shift().location;
      const destination = waypoints.length ? waypoints.pop().location : origin;

      const request = {
        origin,
        destination,
        waypoints,
        travelMode: 'DRIVING',
      };

      setTimeout(() => {
        directionsService.route(request, (response, status) => {
          if (status === 'OK') {
            directionsDisplay.setDirections(response);
          } else {
            swal(`Directions request failed due to ${status}`, '', 'error');
          }
        });
      }, index * index * 100);
    });
  };
  return (
    <div style={{ height: 650, position: 'relative' }}>
      <GoogleMap
        fitMarkers
        initialCenter={initialCenter}
        zoom={12}
        pointData={formattedPoints}
        setInitialCenter>
        <Polyline
          path={snappedPoints}
          strokeColor="#008000"
          strokeOpacity={0.8}
          strokeWeight={2}
        />
        {locations.map((item, index) => (
          <AutomatizeMarker
            position={item.position}
            type={item.type}
            status={item.status}
            key={`${item.type}${index}`}
            iconHeight={item.iconHeight}
            iconWidth={item.iconWidth}
            infoWindow={{
              title: (
                <>
                  <Icon icon={item.icon} colour="white" />
                  <span className="info-window__title--text">
                    {item.title}
                  </span>
                </>
              ),
              content: item.content,
              width: 380,
            }}
          />
        ))}
        <Polygon
          paths={polyCoords(
            milesToMeters(order.well_site_geofence),
            30,
            wellSiteCoords.lat,
            wellSiteCoords.lng,
          )}
          strokeColor={locationColors[wellSite.type].color}
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor={locationColors[wellSite.type].color}
          fillOpacity={0}
        />
        <Polygon
          paths={polyCoords(
            milesToMeters(order.sand_site_geofence),
            30,
            sandSiteCoords.lat,
            sandSiteCoords.lng,
          )}
          strokeColor={locationColors[sandSite.type].color}
          strokeOpacity={0.8}
          strokeWeight={2}
          fillColor={locationColors[sandSite.type].color}
          fillOpacity={0}
        />
        {stagingSiteCoords && (
          <Polygon
            paths={polyCoords(
              milesToMeters(order.staging_site_geofence_range),
              30,
              stagingSiteCoords.lat,
              stagingSiteCoords.lng,
            )}
            strokeColor={locationColors[4].color}
            strokeOpacity={0.8}
            strokeWeight={2}
            fillColor={locationColors[4].color}
            fillOpacity={0}
          />
        )}
      </GoogleMap>
    </div>
  );
};

const mapStateToProps = state => ({
  breadcrumb: state.orderDetails.info.breadcrumb,
});

export default connect(
  mapStateToProps,
  null,
)(OrderDetailsMap);
