import React, { useState, useEffect } from 'react';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Badge from 'components/shared/Badge';
import Select from 'components/shared/Select';
import { orderStatus } from 'components/globalInfo/orderStatus';
import actions from 'store/actions/JobPlans';
import modifyActions from 'store/actions/OrderActions';
import orderStoreActions from 'store/actions/Orders';
import ContestModal from 'components/shared/ContestModal';
import ModalHistory from 'components/views/OrderDetails/Header/HistoryModal';
import UploadTicket from 'components/views/Reconcile/OrderDetails/UploadTicket';
import Authorize, { authorize } from 'components/common/Authorize';
import authService from 'services/auth/service';
import { connect } from 'react-redux';
import Link from 'components/common/Link';
import DropdownButton from 'components/shared/DropdownButton';
import TagsList from 'components/views/Reconcile/OrderDetails/OrderInfoTagsList';
import OrderInfoColumns from 'components/views/Reconcile/OrderDetails/OrderInfoColumns';
import Price from 'components/views/Reconcile/OrderDetails/Price';
import Timeline from 'components/views/Reconcile/OrderDetails/Timeline';
import OrderInfoTicket from 'components/views/Reconcile/OrderDetails/OrderInfoTicket';
import Modify from 'components/views/OrderActions/Modify/index';
import DemurrageReason from 'components/views/OrderActions/DemurrageReason/index';
import { ticketsDownloadAllTickets } from 'store/actions/OrderTickets';
import Icon from 'components/shared/Icon';
import CreateInvoiceModal from 'components/views/Reconcile/ReconcileOrderStatus/CreateInvoiceModal';
import moment from 'moment';
import { billableStatusColors, convertDataForOld } from '../helpers';

const OrdersSlideout = ({
  equipmentRequired,
  orderActions,
  billableOrders,
  getBillableOrders,
  getBillableOrdersDetailsTickets,
  downloadAllTicketsFunc,
  approveOrderFunc,
  isVisible,
  jobId,
  jobName,
  jobPlanId,
  timeFrame,
  openModal,
  jobPlanDetails,
  allBillableTimeBands,
  isLoadingBillableOrders,
  isLoadingOrders,
  setTimeFrameFilter,
  showModalDemurrage,
}) => {
  const user = authService.getUser();
  const [contestModal, setContestModal] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  const [showUploadTicket, turnUploadTicket] = useState(false);
  const [createInvoiceModal, setCreateInvoiceModal] = useState(false);
  const timeBands = allBillableTimeBands.map((time, index) => ({
    label: `${moment.utc(time.start).format('MM-DD-YYYY')} to ${moment
      .utc(time.end)
      .format('MM-DD-YYYY')}`,
    value: index,
  }));
  const refreshTab = data => {
    getBillableOrders({
      id: jobPlanId,
      start: allBillableTimeBands[timeFrame].start,
      end: allBillableTimeBands[timeFrame].end,
    });
    getBillableOrdersDetailsTickets(data.orderId);
  };
  useEffect(() => {
    getBillableOrders({
      id: jobPlanId,
      start: allBillableTimeBands[allBillableTimeBands.length - 1].start,
      end: allBillableTimeBands[allBillableTimeBands.length - 1].end,
    });
    if (allBillableTimeBands && allBillableTimeBands.length > 0) {
      setTimeFrameFilter(allBillableTimeBands.length - 1);
    }
  }, [allBillableTimeBands]);

  const [openSection, setOpenSection] = useState(-1);
  const setActions = data => {
    const actionOptions = [];

    if (
      data.approvalsRequired &&
      data.approvalsRequired.length &&
      data.approvalsRequired.includes(user.role) &&
      data.billing_status <= 20
    ) {
      if (data.review_status !== 2) {
        actionOptions.push({
          name: 'approve',
          label: 'Approve',
          onClick: () => {
            approveOrderFunc([data.orderId], {
              id: jobPlanId,
              start: allBillableTimeBands[timeFrame].start,
              end: allBillableTimeBands[timeFrame].end,
            });
            setOpenSection(-1);
          },
        });
      }
      if (data.review_status !== 4) {
        actionOptions.push({
          name: 'contest',
          label: 'Contest',
          onClick: () => setContestModal(true),
        });
      }
    }

    if (data.status === 4) {
      actionOptions.push({
        name: 'history',
        label: 'History',
        onClick: () => setShowHistory(true),
      });
    }

    if (
      data.status === 2 ||
      data.status === 3 ||
      data.status === 4 ||
      data.status === 5 ||
      data.status === 6
    ) {
      actionOptions.push({
        name: 'uploadTicket',
        label: 'Upload Ticket',
        onClick: () => turnUploadTicket(true),
      });
    }
    return actionOptions;
  };
  useEffect(() => {
    if (isVisible === false) {
      setOpenSection(-1);
    }
    if (isVisible === true) {
      getBillableOrders({
        id: jobPlanId,
        start: allBillableTimeBands[timeFrame].start,
        end: allBillableTimeBands[timeFrame].end,
      });
    }
  }, [isVisible]);
  useEffect(
    () => () => {
      setOpenSection(-1);
    },
    [],
  );
  const toggleAccordion = (index, oldSection, orderId) => {
    if (oldSection === index) {
      setOpenSection(-1);
    } else {
      setOpenSection(index);
    }
    getBillableOrdersDetailsTickets(orderId);
  };
  const { modify } = orderActions;
  const [missingTicketsArray, setMissingTickets] = useState([]);
  const [modifyModalVisible, setModifyModalVisible] = useState(false);
  const handleTimeChange = selection => {
    setTimeFrameFilter(selection.value);
    getBillableOrders({
      id: jobPlanId,
      start: allBillableTimeBands[selection.value].start,
      end: allBillableTimeBands[selection.value].end,
    });
  };
  const isAllReadyToInvoice =
    billableOrders.every(item => item.billableStatus === 3) &&
    Boolean(billableOrders?.length);
  const orderIds = billableOrders.map(o => o.orderId);

  return (
    <div className="marginLeft15 marginBottom20 paddingBottom20">
      <div className="flex">
        <h2 className="marginBottom0">Billable Orders for</h2>
        <div className="width35 marginLR10">
          <Select
            placeholder="Pay period"
            value={timeBands.find(item => item.value === timeFrame)}
            options={timeBands}
            onChange={selection => {
              setOpenSection(-1);
              handleTimeChange(selection);
            }}
          />
        </div>
      </div>
      <div>
        <strong>
          Job #{jobId}:<span style={{ color: '#18a689' }}> {jobName}</span>
        </strong>
      </div>
      <div>
        <strong>{`Job Plan # ${jobPlanId}`}</strong>
      </div>
      {billableOrders && !!billableOrders?.length ? (
        billableOrders.map((data, index) => (
          <div key={`billableOrders ${index}`}>
            <div className="panel">
              <div
                className="panel-heading"
                style={{ padding: 0, borderBottom: 'none' }}>
                <span className="panel-title" style={{ marginTop: 0 }}>
                  <a
                    data-bs-toggle="collapse"
                    data-parent="#accordion"
                    href={`#collapse-billableOrders${index}`}
                    onClick={() =>
                      toggleAccordion(index, openSection, data.orderId)
                    }
                    style={{ textDecoration: 'none', color: 'inherit' }}>
                    <Icon icon="false" className="transform00" />
                    {/* //Note: these two lines needed for styling */}
                    <Icon icon="true" className="transform00" />
                    <div
                      className={`rowContainer ${data?.billableStatus
                        ? `rowContainer--${billableStatusColors[(data?.billableStatus)]
                        }`
                        : ''
                        }`}>
                      <div>
                        <strong>Order Id# </strong>
                        <div>{data.orderId}</div>
                      </div>
                      <div className="subcomponentCenter">
                        <div>
                          <Badge type={orderStatus[data.status].class}>
                            {orderStatus[data.status]
                              ? orderStatus[data.status].name
                              : ''}
                          </Badge>
                        </div>
                      </div>
                      <div>
                        <strong>Carrier Id# </strong>
                        <div>
                          {data.carrierId} {data.carrierName}
                        </div>
                      </div>
                      <div>
                        <strong>Driver Id# </strong>
                        <div>
                          {data.driverId} {data.driverName}
                        </div>
                      </div>
                    </div>
                  </a>
                </span>
              </div>
              {billableOrders.length > 0 &&
                openSection >= 0 &&
                openSection === index &&
                isLoadingOrders === false &&
                isLoadingBillableOrders === false &&
                billableOrders[openSection] &&
                convertDataForOld(data)?.review_status !== undefined && (
                  <div
                    id={`collapse-billableOrders${index}`}
                    className={`panel-collapse collapse ${openSection === index ? 'show' : ''
                      }`}>
                    <div className="panel-body" style={{ paddingTop: 0 }}>
                      {openSection >= 0 &&
                        billableOrders[openSection].orderId &&
                        (isLoadingOrders ? (
                          <Loader />
                        ) : (
                          <div>
                            <div className="marginBottom15">
                              <div className="order-info__header flexBetweenCenter border-bottom-light marginBottom15">
                                <h3 className="order-info__title">
                                  {data.orderId && (
                                    <span className="order-info__title__text textTransformCapitalize">
                                      Order #
                                      {authorize({
                                        abilityPermissions: [
                                          {
                                            resource: 'Orders',
                                            permissions: ['read'],
                                          },
                                        ],
                                      }) ? (
                                        <Link
                                          to="order-details"
                                          id={data.orderId}
                                          target="_blank">
                                          {data.orderId}
                                        </Link>
                                      ) : (
                                        data.orderId
                                      )}
                                    </span>
                                  )}
                                </h3>
                                <div className="flexAlignCenter">
                                  <Authorize
                                    {...{
                                      abilityPermissions: [
                                        {
                                          resource: 'Orders',
                                          permissions: ['update'],
                                        },
                                      ],
                                    }}>
                                    <Button
                                      theme="small marginBottom0"
                                      inverse={modifyModalVisible}
                                      onClick={() => {
                                        openModal(
                                          'modify',
                                          convertDataForOld(data, true),
                                        );
                                      }}
                                      testSelector="jobplans_order-details_info_modify_btn">
                                      Modify
                                    </Button>
                                  </Authorize>
                                  {(data.billableStatus === 3 ||
                                    data.billableStatus === 4) && (
                                      <Authorize
                                        {...{
                                          abilityPermissions: [
                                            {
                                              resource: 'Reconcile',
                                              permissions: ['update'],
                                            },
                                          ],
                                        }}>
                                        <DropdownButton
                                          theme="small"
                                          text="Actions"
                                          icon="down"
                                          options={setActions(
                                            convertDataForOld(data),
                                          )}
                                          noMargin
                                          testSelector="jobplans_order-details_info_actions_dropdown"
                                        />
                                      </Authorize>
                                    )}
                                </div>
                              </div>
                              {openSection >= 0 &&
                                billableOrders[openSection].orderId && (
                                  <div className="marginBottom15">
                                    <h3>Tags</h3>
                                    <TagsList
                                      data={convertDataForOld(
                                        billableOrders[openSection],
                                      )}
                                      equipmentRequired={equipmentRequired}
                                    />
                                  </div>
                                )}

                              <div className="reconcile">
                                <div className="slide-panel__content">
                                  <div className="order-info__info">
                                    <OrderInfoColumns
                                      data={convertDataForOld(
                                        billableOrders[openSection],
                                      )}
                                      tickets={data?.tickets || []}
                                      config={data?.config || []}
                                      jobPlansdata={{
                                        id: jobPlanId,
                                        start:
                                          allBillableTimeBands[timeFrame].start,
                                        end:
                                          allBillableTimeBands[timeFrame].end,
                                      }}
                                    />
                                  </div>
                                  <Price
                                    data={convertDataForOld(
                                      billableOrders[openSection],
                                    )}
                                  />

                                  <div className="order-info__documents-timeline">
                                    <div className="order-info__documents">
                                      <div className="order-info__documents__header">
                                        <h3 className="order-info__documents__title">
                                          Documents
                                        </h3>
                                        <Button
                                          onClick={() =>
                                            downloadAllTicketsFunc(data.orderId)
                                          }
                                          theme="small"
                                          testSelector="job-plans_order-details_info_download-all_btn">
                                          Download All
                                        </Button>
                                      </div>

                                      <div className="order-info__documents__tickets">
                                        {(data?.tickets || [])
                                          .filter(
                                            item =>
                                              item.ticketType !== 1 &&
                                              item.ticketType !== 4,
                                          )
                                          .map(
                                            ticket =>
                                              !ticket.isDeleted && (
                                                <OrderInfoTicket
                                                  key={ticket.id}
                                                  ticket={ticket}
                                                  order={convertDataForOld(
                                                    billableOrders[openSection],
                                                  )}
                                                />
                                              ),
                                          )}
                                      </div>
                                      {missingTicketsArray.length > 0 && (
                                        <>
                                          <br />
                                          <h4 style={{ color: 'red' }}>
                                            Missing Documents
                                          </h4>
                                          <div className="order-info__documents__tickets">
                                            {missingTicketsArray.map(ticket => (
                                              <div
                                                className="ticket"
                                                style={{
                                                  color: 'red',
                                                  borderColor: 'red',
                                                }}
                                                key={ticket}>
                                                <div className="ticket__header">
                                                  <span className="ticket__header__text">
                                                    {ticket}
                                                  </span>
                                                </div>
                                              </div>
                                            ))}
                                          </div>
                                        </>
                                      )}
                                    </div>

                                    <div className="order-info__timeline">
                                      <h3 className="order-info__heading">
                                        Timeline
                                      </h3>
                                      <Timeline
                                        data={convertDataForOld(
                                          billableOrders[openSection],
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                )}
              {billableOrders.length > 0 &&
                openSection >= 0 &&
                openSection === index &&
                (isLoadingOrders === true ||
                  isLoadingBillableOrders === true) && <Loader />}
            </div>
          </div>
        ))
      ) : (
        <div className="font-bold text-danger textCenter">
          No Billable Orders
        </div>
      )}
      {authorize({
        abilityPermissions: [
          { resource: 'Carrier-documents', permissions: ['update'] },
        ],
      }) && (
          <div className="paddingBottom20">
            <Button
              className="float-end"
              disabled={!isAllReadyToInvoice}
              onClick={() => setCreateInvoiceModal(true)}
              testSelector="job-plans_order-details_create-invoice_btn">
              Create invoice
            </Button>
          </div>
        )}

      {modify.openModal &&
        openSection >= 0 &&
        billableOrders[openSection]?.orderId && (
          <Modify
            billableSlideoutOrder={convertDataForOld(
              billableOrders[openSection],
            )}
            openModal={modify.openModal}
            turnModal={() => openModal('modify')}
            jobPlansdata={{
              id: jobPlanId,
              start: allBillableTimeBands[timeFrame].start,
              end: allBillableTimeBands[timeFrame].end,
            }}
            onClose={() => setOpenSection(-1)}
          />
        )}
      {openSection >= 0 && billableOrders[openSection]?.orderId && (
        <ContestModal
          open={contestModal}
          onClose={() => {
            setContestModal(false);
            setOpenSection(-1);
          }}
          orderIds={[billableOrders[openSection].orderId]}
          jobPlansdata={{
            id: jobPlanId,
            start: allBillableTimeBands[timeFrame].start,
            end: allBillableTimeBands[timeFrame].end,
          }}
        />
      )}
      {showHistory &&
        openSection >= 0 &&
        billableOrders[openSection]?.orderId && (
          <ModalHistory
            {...{ showHistory }}
            closeHistory={() => setShowHistory(false)}
            outsideOrderId={billableOrders[openSection].orderId}
          />
        )}
      {showUploadTicket &&
        openSection >= 0 &&
        billableOrders[openSection]?.orderId && (
          <UploadTicket
            openModal={showUploadTicket}
            turnModal={turnUploadTicket}
            order={convertDataForOld(billableOrders[openSection])}
            onClose={() => refreshTab(billableOrders[openSection])}
          />
        )}
      {createInvoiceModal && (
        <CreateInvoiceModal
          open={createInvoiceModal}
          onClose={() => setCreateInvoiceModal(false)}
          orderIds={orderIds}
          usedOutsideReconcile
          customerId={jobPlanDetails.customerId}
        />
      )}
       {showModalDemurrage && <DemurrageReason />}
    </div>
  );
};

const mapStateToProps = state => ({
  equipmentRequired: state.lookUp.equipmentRequired,
  orderActions: state.orderActions,
  billableOrders: state.jobPlans.jobPlanInfo.billableOrders,
  billableOrdersDetails: state.jobPlans.jobPlanInfo.billableOrdersDetails,
  jobPlanDetails: state.jobPlans.jobPlanInfo.jobPlanDetails,
  allBillableTimeBands:
    state.jobPlans.jobPlanInfo.jobPlanDetails.allBillableTimeBands,
  isLoadingBillableOrders: state.jobPlans.jobPlanInfo.isLoadingBillableOrders,
  isLoadingOrders: state.jobPlans.jobPlanInfo.isLoadingOrders,
  timeFrame: state.jobPlans.jobPlanInfo.timeFrame,
  showModalDemurrage: state.orderActions.demurrageReason.openModal,
});

const mapDispatchToProps = dispatch => ({
  getBillableOrders: data => dispatch(actions.getBillableOrders(data)),
  getBillableOrdersDetailsTickets: orderId =>
    dispatch(actions.getBillableOrdersDetailsTickets(orderId)),
  setTimeFrameFilter: timeFrame => {
    dispatch(actions.setTimeFrameFilter(timeFrame));
  },
  downloadAllTicketsFunc: orderId =>
    dispatch(ticketsDownloadAllTickets(orderId)),
  approveOrderFunc: (orderId, data) => {
    dispatch(orderStoreActions.jobPlansApproveOrder(orderId, data));
  },
  openModal: (modal, order) => {
    dispatch(modifyActions.openModal(modal, order));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrdersSlideout);
