import moment from 'moment';
import swal from 'bootstrap-sweetalert';
import { carrier } from 'services/carrier/service';
import sandTicket from 'services/sandTicket/service';
import { certificates } from 'services/certificate/service';
import { district } from 'services/district/service';
import { customer } from 'services/customer/service';
import { jobs } from 'services/jobs/service';
import { wellSite } from 'services/wellSite/service';
import booleanToNumber from 'utils/booleanToNumber';
import { history } from 'helpers/history';
import { userRoleLinks } from 'components/globalInfo/userRoles';
import authService from 'services/auth/service';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import { apiSlice } from 'features/api/apiSlice';
import { queryClient } from 'api';
import { getValidationMessage } from 'helpers/error-handling';

const saveInitData = (data, name) => dispatch => {
  dispatch({ type: 'MODAL_ADD_JOB_SAVE_INIT_DATA', data, name });
};

const initModalAddJob = () => dispatch => {
  sandTicket.types
    .get()
    .then(msg => {
      dispatch(saveInitData(msg.data.data.sandTicketTypes, 'sandTicketTypes'));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));

  certificates
    .get()
    .then(msg => {
      dispatch(saveInitData(msg.data.data, 'certificatesList'));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));

  district
    .get()
    .then(msg => {
      dispatch(saveInitData(msg.data.data.district, 'districts'));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));

  carrier
    .getCarriers()
    .then(carrierResponse => {
      let companies = carrierResponse.data.data.carriers.map(c => ({
        id: `${c.id}/3`,
        name: c.name,
      }));
      customer
        .get()
        .then(customerResponse => {
          const customers = customerResponse.data.data.customers.map(c => ({
            id: `${c.id}/2`,
            name: c.name,
          }));
          dispatch(
            saveInitData(customerResponse.data.data.customers, 'customers'),
          );

          companies = companies.concat(customers);
          dispatch(saveInitData(companies, 'companyArray'));
        })
        .catch(error => {
          swal(
            error.response.data.error || 'Something went wrong.',
            '',
            'error',
          );
        });
    })
    .catch(error => {
      swal(error.response.data.error || 'Something went wrong.', '', 'error');
    });
};

const getOnsiteStorageTypes = () => dispatch => {
  const success = data => ({ type: 'SET_ONSITE_STORAGE_TYPES', data });
  jobs.onsiteStorageTypes
    .get()
    .then(response => {
      dispatch(success(response.data.data));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const setStepValue = step => dispatch => {
  dispatch({ type: 'MODAL_ADD_JOB_SET_STEP', step });
};
const setAddJobValue = (value, name) => dispatch => {
  dispatch({ type: 'MODAL_ADD_JOB_SET_VALUE', value, name });
};

const clearAddJobValue = () => dispatch => {
  dispatch({ type: 'JOB_LIST_CLEAR_ADD_JOB_VALUE' });
};

const emptyArray = name => (dispatch, getState) => {
  const state = getState();
  const array = state.jobsList.jobs.addJob[name];
  array.length = 0;
  dispatch(setAddJobValue(array, name));
};

const addArrayValue = (name, content) => (dispatch, getState) => {
  const state = getState();
  const array = [...state.jobsList.jobs.addJob[name], content || {}];
  dispatch(setAddJobValue(array, name));
};

const validateCustomerJobId = () => async (dispatch, getState) => {
  const state = getState();
  const { customerId } = state.jobsList.jobs.addJob;
  const { customerJobId } = state.jobsList.jobs.addJob;

  if (customerId && customerJobId) {
    try {
      const data = {
        customerId: customerId.toString(),
        customerJobId: customerJobId.toString(),
      };
      const response = await jobs.validateCustomerJobId(data);
      const designData = response.data.data;
      if (designData.length) {
        dispatch(emptyArray('jobDesign'));
        designData.forEach(item => {
          const designInfo = {
            sandTypeId: Number(item.sand_type_id),
            volume: item.sand_volume,
            minVolume: Math.floor(item.alert_volume),
          };
          dispatch(addArrayValue('jobDesign', designInfo));
        });
      }
    } catch (e) {
      // Do nothing
    }
  }
};

const setArrayValue = (value, index, name, arrayName) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const array = state.jobsList.jobs.addJob[arrayName];

  if (name === 'storageId') {
    array[index][name] = value.trim();
  } else {
    array[index][name] = value;
  }
  dispatch(setAddJobValue(array, arrayName));
};

const deleteArrayValue = (index, name) => (dispatch, getState) => {
  const state = getState();
  const array = state.jobsList.jobs.addJob[name];
  array.splice(index, 1);
  dispatch(setAddJobValue(array, name));
};

const setOnsiteStorageType = (selectedType, index) => dispatch => {
  const isLive = !!selectedType.sourceId;

  dispatch(setArrayValue(selectedType.id, index, 'type', 'storageConfig'));
  dispatch(setArrayValue(isLive, index, 'isLive', 'storageConfig'));
  // dispatch(
  //   setArrayValue(undefined, index, 'storageId', 'storageConfig'),
  // );
  // dispatch(
  //   setArrayValue(undefined, index, 'capacity', 'storageConfig'),
  // );
  // dispatch(
  //   setArrayValue(undefined, index, 'commodity', 'storageConfig'),
  // );
  // dispatch(
  //   setArrayValue(undefined, index, 'commodityId', 'storageConfig'),
  // );
};

const selectedCustomer = closeAddJobModal => async (dispatch, getState) => {
  const state = getState();
  const { customerId } = state.jobsList.jobs.addJob;
  dispatch({ type: 'ADD_JOB_CHANGE_CUSTOMER_SELECT' });

  let payments = '';
  let table = '';

  await wellSite
    .getWellsitesForCustomer(customerId)
    .then(msg => {
      dispatch(saveInitData(msg.data.data, 'wellSites'));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
  await customer.billing.payments
    .get(customerId)
    .then(msg => {
      payments = msg.data.data;
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
  await customer.rateTable
    .get(customerId)
    .then(msg => {
      table = msg.data.data;
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
  customer.demurrage
    .get(customerId)
    .then(msg => {
      dispatch(saveInitData(msg.data.data, 'demmurage'));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
  if (table.length === 0 && payments.length === 0) {
    swal(
      "You can't create a Job for this customer. Please create rate table or payment first",
      '',
      'error',
    );
    // closeAddJobModal();
    dispatch(setAddJobValue('', 'customerId'));
  }
  dispatch(saveInitData(table, 'rateTable'));
};
const getBillingProfiles = () => (dispatch, getState) => {
  const state = getState();
  const { customerId } = state.jobsList.jobs.addJob;

  customer.billing.profiles
    .get(customerId)
    .then(msg => {
      dispatch(saveInitData(msg.data.data, 'billingProfiles'));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const saveJob = (isDraft, closeModal) => (dispatch, getState) => {
  const user = authService.getUser();
  const state = getState();
  const data = { ...state.jobsList.jobs.addJob };
  data.rateTableId = 0;
  data.demurrageTableId = 0;
  const {
    startDate,
    endDate,
    phoneNumbers,
    messagesAllowed,
    sandTicketDesign,
    useCommodityPrice,
    sendCompletionCodeToDriver,
    operatorList,
    customerId,
    totalWeightPerJob,
    targetStagesPerDay,
  } = data;

  data.isDraft = Number(isDraft);
  data.startDate = moment(startDate)
    .utc()
    .format('YYYY-MM-DD HH:mm');
  data.endDate = moment(endDate)
    .utc()
    .format('YYYY-MM-DD HH:mm');
  data.certificates = data.certificates
    ? data.certificates.map(item => item.value)
    : undefined;
  data.sandTicketDesign = sandTicketDesign
    ? sandTicketDesign.map(item => ({
        sandTicketTypeId: item.value,
      }))
    : undefined;
  data.sendCompletionCodeToDriver = Number(sendCompletionCodeToDriver);
  data.districtIds = data.districtIds
    ? Array.from(data.districtIds.map(item => item.value))
    : undefined;

  if (data.jobOperationType === 12) {
    data.equipment = [5];
    data.useCommodityPrice = 0;
    data.numberOfStages = 1;
    data.pumpTime = 1;
    data.wirelineTime = 1;
    data.messagesAllowed = 0;
    delete data.storageConfig;
    delete data.jobDesign;
    delete data.phoneNumbers;
  } else {
    data.phoneNumbers = messagesAllowed
      ? phoneNumbers.map(item => `${item.code}${item.number}`)
      : undefined;
    data.useCommodityPrice = booleanToNumber(useCommodityPrice);
    data.messagesAllowed = booleanToNumber(messagesAllowed);
    data.equipment = data.equipment
      ? Array.from(data.equipment.map(item => item.value))
      : undefined;
  }

  data.billingProfileId = data.billingProfileId || 0;
  data.allIn = Number(data.allIn);

  if (!targetStagesPerDay) {
    delete data.targetStagesPerDay;
  }

  if (!totalWeightPerJob) {
    delete data.totalWeightPerJob;
  }

  jobs
    .add(data)
    .then(msg => {
      if (operatorList.length) {
        const filteredCompanies = operatorList.filter(ol => {
          const entityArray = ol.value.split('/');

          if (Number(entityArray[1]) === 2) {
            return Number(entityArray[0]) !== Number(customerId);
          }

          return true;
        });
        const params = {};
        params.jobId = msg.data.data.jobId;
        params.customerId = customerId;
        params.data = filteredCompanies.map(item => {
          const entityArray = item.value.split('/');
          return {
            entityType: Number(entityArray[1]),
            entityId: Number(entityArray[0]),
          };
        });

        jobs.operators
          .add(params)
          .then(() => {
            swal(msg.data.message, '', 'success');
            const id = msg.data.data.jobId;
            dispatch(clearAddJobValue());

            if (!isDraft) {
              history.push(`/${userRoleLinks[user.role]}/job-details/${id}`);
            }
          })
          .catch(error => {
            swal(error.response.data.message, '', 'error');
          });
      } else {
        swal(msg.data.message, '', 'success');
        const id = msg.data.data.jobId;

        if (!isDraft) {
          history.push(`/${userRoleLinks[user.role]}/job-details/${id}`);
        }
      }

      dispatch(apiSlice.util.invalidateTags(['Job']));
      dispatch(clearAddJobValue());

      if (closeModal) {
        closeModal();
      }

      if (isDraft) {
        dispatch(resourceActions.getJobs());
        queryClient.invalidateQueries(['jobs']);
      }
    })
    .catch(error => {
      swal(
        'Unable To Create Job',
        getValidationMessage(error),
        'error',
      );
    });
};

export default {
  initModalAddJob,
  getOnsiteStorageTypes,
  setStepValue,
  setAddJobValue,
  validateCustomerJobId,
  selectedCustomer,
  addArrayValue,
  setArrayValue,
  deleteArrayValue,
  setOnsiteStorageType,
  saveJob,
  getBillingProfiles,
  clearAddJobValue,
};
