import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import eventTarget from 'utils/dispatchWithFieldValue';
import { convertTime } from 'utils/convertTime';
import actions from 'store/actions/OrderDetails';
import Authorize from 'components/common/Authorize';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';

const Comments = ({
  init,
  logs,
  setComment,
  deleteComment,
  postComment,
  order,
}) => {
  useEffect(() => {
    init();
  }, []);

  const { comments, comment } = logs;
  const filteredComments = useMemo(
    () =>
      (comments || []).filter(
        (c, i, array) =>
          i === 0 ||
          c.comment !== 'Driver is Connected.' ||
          array[i - 1].comment !== 'Driver is Connected.',
      ),
    [comments],
  );

  return (
    <>
      <h3>Comments for order</h3>
      {filteredComments.length ? (
        <div className="comment-list">
          {filteredComments.map(item => (
            <div key={item.comment_id} className="comment-list__comment">
              <div className="comment-list__comment--details">
                <span>@{item.character_type} </span>
                <span className="float-end">
                  <Icon icon="clock-o" /> {convertTime(item.created_at)}
                </span>
              </div>
              <div className="comment-list__comment--comment">
                {item.comment}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div>
          <h4>No comments yet</h4>
        </div>
      )}
      <div>
        <Authorize
          {...{
            itemAccessGranted: !order.is_deleted,
            abilityPermissions: [
              {
                resource: 'Orders',
                permissions: ['update'],
              },
            ],
          }}>
          <form role="form" onSubmit={postComment}>
            <Input
              onChange={setComment}
              value={comment}
              label="Write your comment here"
              required
              testSelector="order-details_comments_text_input"
            />
          </form>
        </Authorize>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  logs: state.orderDetails.logs,
});

const mapDispatchToProps = dispatch => ({
  setComment: eventTarget(actions.setComment, dispatch),
  postComment: (e, orderId) => {
    e.preventDefault();
    dispatch(actions.postComment(orderId));
  },
  deleteComment: (commentId, orderId) => {
    dispatch(actions.deleteComment(commentId, orderId));
  },
  init: () => dispatch(actions.initComments()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Comments);
