/* eslint-disable import/prefer-default-export */
import React from 'react';
import Company from '../Company';
import Config from '../Config';
import Billing from '../Billing';
import AddBillingTerms from '../AddBillingTerms';
import SelectCustomer from '../SelectCustomer';

export const SelectAction = (
  setStep,
  addSubmit,
  setCompanyValue,
  setBillValue,
  setConfigValue,
  addCustomer,
  closeAdd,
  shareCustomerWithCompany,
) => {
  if (addCustomer && addCustomer.step) {
    const step = addCustomer && addCustomer.step;
    switch (step) {
      case 1:
        return (
          <SelectCustomer
            setStep={setStep}
            closeAdd={closeAdd}
            submitShareCustomer={shareCustomerWithCompany}
          />
        )
      case 2:
        return (
          <Company
            setStep={setStep}
            setCompanyValue={setCompanyValue}
            addCustomer={addCustomer}
            closeAdd={closeAdd}
          />
        );
      case 3:
        return (
          <Billing
            setStep={setStep}
            setBillValue={setBillValue}
            addCustomer={addCustomer}
            closeAdd={closeAdd}
          />
        );
      case 4:
        return (
          <Config
            setStep={setStep}
            addSubmit={addSubmit}
            setConfigValue={setConfigValue}
            addCustomer={addCustomer}
            closeAdd={closeAdd}
          />
        );
      case 5:
        return (
          <AddBillingTerms
            setStep={setStep}
            addSubmit={addSubmit}
            addCustomer={addCustomer}
            closeAdd={closeAdd}
            showEdit
            // customerId={123}
          />
          // closeEdit, // false close modal function in parent
          // showEdit, // true close modal function in parent
          // customerId, // send this from customer creation
        );
      default:
        return null;
    }
  }
  return null;
};
