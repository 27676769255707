import { useMutation, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import { updateStorageConfig } from './queries';

export const useUpdateStorageConfig = jobId => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: storageConfig => updateStorageConfig({ jobId, storageConfig }),
    onSuccess: () => {
      swal('Success', 'Storage details updated successfully', 'success');
      queryClient.invalidateQueries(['jobs', jobId]);
      setTimeout(
        () => queryClient.invalidateQueries(['jobs', jobId, 'storage']),
        5000,
      );
    },
    onError: error => {
      swal(
        'Error Updating Job Storage',
        error?.response?.data?.message,
        'error',
      );
    },
  });
};
