import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';

import targetValue from 'utils/targetValue';
import actions from 'store/actions/JobsList';
import { filterJobs } from 'store/selectors/jobsList';
import { useDebounce } from 'helpers/hooks';

import { usePaginatedJobs } from 'api/v2/jobs';

import Jobs from './Jobs';
import Filter from './Filters';
import AddJob from './AddJobModal';
import EditJob from './EditJobModal';
import CertificatesModal from './CertificatesModal';
import QuickBooksModal from './QuickBooksModal';
import { useCertModalControls, useEditJobModalControls } from './hooks';

const JobList = ({
  customerId,
  filter,
  setStatus,
  setValue,
  setFilter,
  deleteJob,
  completeJob,
  uncompleteJob,
  clearAddJobValue,
  initEditJob,
  clearEditJobValue,
  initQuickBooksModal,
  initJobStatus,
  clearState,
  getUsers,
  users,
  isDraftJob,
  setIsDraftJob,
}) => {
  const [showAddJob, turnModalAddJob] = useState(false);
  const [showQuickBooks, turnModalQuickBooks] = useState(false);

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const debouncedFilters = useDebounce(filter, 500);
  const { data: jobsData, isLoading } = usePaginatedJobs({
    customerId,
    ...debouncedFilters,
    page,
    pageSize,
  });

  const editModal = useEditJobModalControls();
  const certModal = useCertModalControls();

  const location = useLocation();

  useEffect(() => {
    getUsers();
    if (location.status) {
      initJobStatus(location.status);
    }
    return () => {
      clearState();
    };
  }, [clearState, getUsers, initJobStatus, location.status, isDraftJob]);

  return (
    <>
      <Header title="View Jobs">
        <Authorize
          abilityPermissions={[
            {
              resource: 'Jobs',
              permissions: ['create'],
            },
          ]}>
          <Button
            onClick={() => turnModalAddJob(true)}
            inverse
            testSelector="jobs-list_add-job_btn"
            style={{ float: 'right' }}>
            <Icon className="icon--margin-right" icon="plus-square" />
            Add Job
          </Button>
        </Authorize>
      </Header>

      <Authorize
        abilityPermissions={[
          { resource: 'Data-van-info', permissions: ['read'] },
        ]}>
        <Filter
          setFilter={setFilter}
          page={page}
          setPage={setPage}
          setPageSize={setPageSize}
          filter={filter}
          setStatus={setStatus}
          setValue={setValue}
          setIsDraftJob={setIsDraftJob}
          totalPages={jobsData?.totalPages ?? 0}
        />
        <Jobs
          filter={filter}
          deleteJob={deleteJob}
          completeJob={completeJob}
          uncompleteJob={uncompleteJob}
          users={users}
          openEditJobModal={id => {
            initEditJob(id);
            editModal.open(id);
          }}
          jobs={jobsData?.jobs ?? []}
          isLoading={isLoading}
          openCertificates={certModal.open}
          openQuickBooks={data => {
            initQuickBooksModal(data);
            turnModalQuickBooks(true);
          }}
        />
      </Authorize>

      <AddJob
        showAddJob={showAddJob}
        closeAddJobModal={() => {
          clearAddJobValue();
          turnModalAddJob(false);
        }}
      />
      <EditJob
        jobId={editModal.data.jobId}
        showEditJob={editModal.isOpen}
        closeEditJobModal={() => {
          clearEditJobValue();
          editModal.close();
        }}
      />
      <CertificatesModal
        jobId={certModal.data.jobId}
        isFullEdit={certModal.data.isFullEdit}
        showModal={certModal.isOpen}
        closeModal={certModal.close}
      />
      <QuickBooksModal
        showQuickBooks={showQuickBooks}
        closeQuickBooks={() => {
          turnModalQuickBooks(false);
        }}
      />
    </>
  );
};

const mapStateToProps = state => ({
  jobs: filterJobs(state)(state.jobsList.jobs.filter),
  isLoadedJobs: state.resourceReducer.jobs.isLoadedJobs,
  filter: state.jobsList.jobs.filter,
  users: state.jobsList.jobs.users,
});

const mapDispatchToProps = dispatch => ({
  clearAddJobValue: () => dispatch(actions.clearAddJobValue()),
  clearEditJobValue: () => dispatch(actions.clearEditJobValue()),
  clearState: () => dispatch({ type: 'JOB_LIST_CLEAR_STATE' }),
  setStatus: value => dispatch(actions.setStatus(value)),
  setIsDraftJob: value => dispatch(actions.setIsDraftJob(value)),
  setFilter: (value, name) => dispatch(actions.setValue(value, name)),
  setValue: (e, name) => dispatch(actions.setValue(targetValue(e), name)),
  deleteJob: id => dispatch(actions.deleteJob(id)),
  completeJob: id => dispatch(actions.completeJob(id)),
  uncompleteJob: id => dispatch(actions.uncompleteJob(id)),
  initEditJob: id => dispatch(actions.initEditJob(id)),
  initJobStatus: status => dispatch(actions.setStatus(status)),
  initCertificateModal: id => dispatch(actions.initCertificateModal(id)),
  clearStateCertificateModal: () => dispatch(actions.clearCertificateModal()),
  getUsers: () => dispatch(actions.getUsers()),
  initQuickBooksModal: data => dispatch(actions.initQuickBooksModal(data)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(JobList);
