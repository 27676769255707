import { useCommoditiesByPo, useFreeDrivers, useJobDetails, useJobStages } from 'api/v2/jobs';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

export const usePoCommodities = jobId => {
  const { data: commodities } = useCommoditiesByPo({ jobId });
  const { data: jobDetails } = useJobDetails({ jobId });

  const poCommodities = useMemo(() => {
    if (commodities.length && !isEmpty(commodities[0])) {
      const purchaseOrders = jobDetails?.purchaseOrders || [];
      return commodities
        .map(item => {
          const matched = purchaseOrders.find(
            p => p.poNo.toString() === item.poNo.toString(),
          );
          item.limitExceeded =
            matched.dailyLimit > 0
              ? matched.usedForLast24Hours + matched.loadWeight >
                matched.dailyLimit
              : false;
          return item;
        })
        .filter(item => item.enabled && !item.limitExceeded)
        .map(item => ({
          value: item.poNo,
          label: `${item.poNo} | ${item.sandTypeName} | ${item.originName}`,
          ...item,
          // interstateCertRequired: item.interstateCertRequired,
        }));
    }
    return [];
  }, [commodities, jobDetails?.purchaseOrders]);

  return poCommodities;
};

export const useDriverOptions = ({ jobId, po }) => {
  const { data: drivers } = useFreeDrivers({ locationId: po?.originId });
  const { data: jobDetails } = useJobDetails({ jobId });

  const filteredDrivers = useMemo(() => {
    if (!drivers.length || !jobDetails || !po) {
      return [];
    }

    const filtered = drivers.filter(
      driver =>
        driver &&
        driver.districts &&
        driver.districts.length &&
        driver.districts.some(driverDistrict =>
          jobDetails.districts.find(d => d.id === driverDistrict.id),
        ) &&
        jobDetails.details.equipments.some(
          equipment => equipment === driver.equipment,
        ) &&
        (po.isCertRequired ? driver.interstateCertified : true),
    );
    return filtered.map(driver => ({
      value: driver.id,
      label: `${driver.id} | ${driver.name} | ${(
        driver.distanceFromSand / 1609.344
      ).toFixed(0)} mi away from sand site`,
    }));
  }, [drivers, jobDetails, po]);
  return filteredDrivers;
};

export const useStageOptions = (jobId) => {
  const { data: stages } = useJobStages(jobId);

  const stageOptions = useMemo(
    () => [
      ...(stages ?? []).map(stageItem => ({
        value: stageItem.stageId,
        label: `${stageItem.stageId}`,
      })),
      { value: 0, label: 'Dispatch new' },
    ],
    [stages],
  );
  return stageOptions;
}