import React from 'react';
import { orderStatus } from 'components/globalInfo/orderStatus';
import Link from 'components/common/Link';
import Authorize from 'components/common/Authorize';
import ToolTip from 'components/shared/ToolTip';
import { commodityTypes, image } from 'components/globalInfo/commodityUnits';

const Info = props => {
  const { order } = props;
  const {
    order_id,
    customer_order_id,
    stage,
    status,
    customer_id,
    name,
    job_id,
    job_name,
    po,
    sand_type,
    commodity_name,
    commodityType,
    mileage,
    carrier_id,
    carrier_name,
    truck,
    sand_ticket_no,
    driver_id,
    driver_name,
    weight,
    driver_weight,
    operation_type,
  } = order;
  return (
    <div>
      <h3>
        Order #{order_id} / {customer_order_id || 'unknown '}
        {operation_type != 12 && ` - Stage ${stage || 'unknown '} `}
      </h3>
      <span className={`label label-${orderStatus[status].class} float-end`}>
        {orderStatus[status].name}
      </span>

      <Authorize {...{ access: ['VENDOR', 'JOB_MANAGER', 'CUSTOMER'] }}>
        <div className="row">
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>Customer #{customer_id || 'unknown'}: </b>
                <Link
                  {...{
                    id: customer_id,
                    to: 'customer-details',
                  }}>
                  {name || 'unknown'}
                </Link>
              </li>
              <li>
                <b>Job: </b>
                <Link
                  {...{
                    id: job_id,
                    to: 'job-details',
                  }}>
                  #{job_id || 'unknown'} | {job_name || ''}
                </Link>
              </li>
            </ul>
          </div>
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>PO: </b>
                {po || 'unknown'}
              </li>
            </ul>
          </div>
        </div>
      </Authorize>

      {status != 0 && status != 1 && Boolean(driver_id) && (
        <div className="row">
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <Authorize {...{ access: ['JOB_COORDINATOR'] }}>
                <li>
                  <b>Carrier #{carrier_id || 'unknown'}: </b>
                  <Link
                    {...{
                      id: carrier_id,
                      to: 'carrier-details',
                    }}>
                    {carrier_name || 'unknown'}
                  </Link>
                </li>
              </Authorize>
              <li>
                <b>Truck: </b>
                {truck || 'unknown'}
              </li>
              <li>
                <b>Ticket Number: </b>
                {sand_ticket_no || 'unknown'}
              </li>
            </ul>
          </div>
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>Driver #{driver_id || 'unknown'}: </b>
                <Link
                  {...{
                    id: driver_id,
                    to: 'driver-details',
                  }}>
                  {driver_name || 'unknown'}
                </Link>
              </li>
              <li>
                <b>Quantity (requested/loaded): </b>
                {weight || 'unknown'} /{driver_weight || 'unknown'}
              </li>
            </ul>
          </div>
        </div>
      )}
      <Authorize {...{ access: ['VENDOR', 'JOB_MANAGER', 'CUSTOMER'] }}>
        <div className="row">
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>Origin: </b>
                {linkLocation(order, 'origin')}
              </li>
              <li>
                <b>
                  <ToolTip title={commodityTypes(commodityType).title}>
                    {image(commodityType)}
                  </ToolTip>
                  Commodity Type:{' '}
                </b>
                {commodity_name || 'unknown'}
              </li>
            </ul>
          </div>
          <div className="col-md-6 no-padding">
            <ul className="list-unstyled">
              <li>
                <b>Destination: </b>
                {linkLocation(order, 'destination')}
              </li>
              <li>
                <b>Mileage: </b>
                {mileage || 'unknown'}
              </li>
            </ul>
          </div>
        </div>
      </Authorize>
    </div>
  );
};

const linkLocation = (order, type) => {
  const {
    well_site_id,
    well_site_name,
    loading_site_id,
    loading_site_name,
  } = order;
  switch (type) {
    case 'origin':
      return linkPermission(
        loading_site_id,
        loading_site_name,
        'location-details',
      );
    case 'destination':
      return linkPermission(well_site_id, well_site_name, 'location-details');
    default:
      return null;
  }
};

const linkPermission = (id, name, linkTo) => (
  <span>
    <Authorize {...{ forbidden: ['CUSTOMER'] }}>
      <Link {...{ id, to: linkTo }}>
        {`# ${id || 'unknown'} | ${name || 'unknown'}`}
      </Link>
    </Authorize>
    <Authorize
      {...{
        forbidden: ['VENDOR', 'CARRIER', 'JOB_COORDINATOR', 'JOB_MANAGER'],
      }}>
      {`# ${id || 'unknown'} | ${name || 'unknown'}`}
    </Authorize>
  </span>
);

export default Info;
