import React, { Suspense } from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import Reasons from './Reasons';
import Districts from './Districts';
import Commodities from './Commodities';
import Tags from './Tags';
import Vendors from './Vendors';
import Certificates from './Certificates';
import EquipmentTypes from './EquipmentTypes';
import StorageTypes from './StorageTypes';
import UnitsOfMeasure from './UnitsOfMeasure';

const Database = () => (
  <div>
    <Header title="Manage" />
    <Authorize
      abilityPermissions={[{ resource: 'Database', permissions: ['read'] }]}>
      <Tabs>
        <Tab eventKey="demurrage" title="Reasons for demurrage">
          <Reasons />
        </Tab>
        <Tab eventKey="districts" title="Districts">
          <Districts />
        </Tab>
        <Tab eventKey="commodities" title="Commodities">
          <Commodities />
        </Tab>
        <Tab eventKey="tags" title="Tags">
          <Tags />
        </Tab>
        <Tab eventKey="vendors" title="Vendors">
          <Vendors />
        </Tab>
        <Tab eventKey="certificates" title="Certificates">
          <Certificates />
        </Tab>
        <Tab eventKey="equipment" title="Equipment Types">
          <EquipmentTypes />
        </Tab>
        <Tab eventKey="storage-types" title="Storage Types">
          <Suspense fallback={<Loader />}>
            <StorageTypes />
          </Suspense>
        </Tab>
        <Tab eventKey="uom" title="Units of Measure">
          <UnitsOfMeasure />
        </Tab>
      </Tabs>
    </Authorize>
  </div>
);

export default Database;
