import swal from 'bootstrap-sweetalert';
import moment from 'moment';
import { addOrder } from 'services/addOrder/service';
import { toUTC } from 'utils/convertTime';

import orderListActions from 'store/actions/OrdersList';
import { queryClient } from 'api';
import initActions from './JobDetails';

const sortUnits = units =>
  units.sort((a, b) => {
    if (
      a.priority > b.priority ||
      (a.priority === b.priority && a.poNo > b.poNo)
    ) {
      return 1;
    }
    return -1;
  });

const setInputValue = (value, name) => ({
  type: 'JOB_DETAILS_ADD_ORDER_SET_INPUT_VALUE',
  value,
  name,
});

const setAutoAssignValue = () => ({
  type: 'JOB_DETAILS_ADD_ORDER_SET_CHECK_AUTOASSIGN_VALUE',
});

const setManualValue = () => ({
  type: 'JOB_DETAILS_ADD_ORDER_SET_CHECK_MANUAL_VALUE',
});

const clearModalStore = () => ({
  type: 'JOB_DETAILS_ADD_ORDER_CLEAR_STORE',
});

const getSandTypeByPoData = (jobId, closeAddOrderModal) => dispatch => {
  const success = data => ({
    type: 'JOB_DETAILS_ADD_ORDER_GET_SAND_TYPE_PO_SUCCESS',
    data,
  });
  addOrder
    .getSandTypeByPoData(jobId)
    .then(response => {
      const { po } = response.data.data;
      if (po.length) {
        dispatch(success(po));
      } else {
        swal(
          'No any origins/destinations available. Please add or activate at least one to be able to create order',
          '',
          'error',
        );
        closeAddOrderModal();
      }
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const getStage = jobId => dispatch => {
  const success = data => ({
    type: 'JOB_DETAILS_ADD_ORDER_GET_STAGE_SUCCESS',
    data,
  });
  addOrder
    .getStageByJobId(jobId)
    .then(response => {
      dispatch(success(response.data.data.stages));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const getFreeDrivers = poNo => (dispatch, getState) => {
  const state = getState();
  const { operationType } = state.jobDetails.details.details;
  const success = data => ({
    type: 'JOB_DETAILS_ADD_ORDER_GET_DRIVERS_SUCCESS',
    data,
  });

  let sandId;
  let po = [];
  if (operationType === 12) {
    const { units } = state.jobDetails.details;
    const filteredUnits = units.filter(
      unit => unit.isEnabled && !unit.suspended,
    );

    const sortedUnits = sortUnits(filteredUnits);
    if (sortedUnits.length) {
      sandId = sortedUnits[0].id;
    } else {
      swal('There are no available disposal units.', '', 'error');
    }
  } else {
    const poArray = [...state.jobDetails.addOrderModal.sandTypesByPo];
    po = poArray.filter(item => item.poNo === poNo);
    sandId = po[0].originId;
  }

  addOrder
    .getFreeDrivers(sandId)
    .then(response => {
      dispatch(success(response.data.data.drivers));
      if (operationType !== 12) {
        dispatch(setInputValue(po[0], 'po'));
      }
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const submitAddOrder = (closeAddOrderModal, initFilters, jobId) => (
  dispatch,
  getState,
) => {
  const request = () => ({ type: 'JOB_DETAILS_ADD_ORDER_ADD_REQUEST' });
  const fail = () => ({ type: 'JOB_DETAILS_ADD_ORDER_ADD_FAIL' });
  const success = () => ({ type: 'JOB_DETAILS_ADD_ORDER_ADD_SUCCESS' });
  dispatch(request());
  
  // TODO: Temp while redux actions exist
  const jobDetails = queryClient.getQueryData(['jobs', Number(jobId), 'details'])
  const { operationType, customerId } = jobDetails.details;

  const state = getState();
  const data = { jobId, ...state.jobDetails.addOrderModal.addJob };

  if (moment(data.startDate).isBefore(moment())) {
    swal('Past dates not allowed!', '', 'error');
    dispatch(fail());
  } else if (
    operationType !== 12 &&
    moment(data.startDate).isBefore(moment(data.loadDate))
  ) {
    swal(
      'Pick Up Appointment cannot be after Deliver Appointment',
      '',
      'error',
    );
    dispatch(fail());
  } else if (operationType !== 12 && !data.loadDate) {
    swal('Pick Up Appointment cannot be Empty', '', 'error');
    dispatch(fail());
  } else if (
    moment(data.loadDate).isAfter(moment().add(10, 'hours')) ||
    moment(data.loadDate).isBefore(moment().subtract(10, 'hours'))
  ) {
    swal(
      'Pick Up Appointment Must be 10 hours before or after Now.',
      '',
      'error',
    );
    dispatch(fail());
  } else if (!data.autoAssign && !data.driverId) {
    swal('You must either select a driver or select auto assign!', '', 'error');
    dispatch(fail());
  } else {
    if (operationType === 12) {
      const { units } = jobDetails;
      const filteredUnits = units.filter(
        unit => unit.isEnabled && !unit.suspended,
      );

      const sortedUnits = sortUnits(filteredUnits);

      if (sortedUnits.length) {
        const sandId = sortedUnits[0].id;
        // TODO: Fix for water jobs
        const { sandTypesByPo } = state.jobDetails.addOrderModal;
        const po = sandTypesByPo.find(type => type.ssId === sandId);
        data.purchaseOrderId = po.poNo;
        data.originId = po.originId;
        data.destinationId = data.po.destinationId;
        data.sandTypeId = po.sandType;
      } else {
        swal(
          'You do not have any enabled units. Please enable a unit in the units tab.',
          '',
          'error',
        );
        dispatch(fail());
      }
    } else {
      data.purchaseOrderId = data.po.poNo;
      data.originId = data.po.originId;
      data.destinationId = data.po.destinationId;
      data.sandTypeId = data.po.sandType;
    }

    data.stage = data.stage || 0;
    data.unloadAppointment = toUTC(data.startDate);
    data.loadAppointment = operationType === 12 ? '' : toUTC(data.loadDate);
    data.customerId = customerId;

    if (!data.autoAssign && data.count === '1') {
      data.driverId = +data.driverId;
    } else {
      data.driverId = 0;
    }

    data.manualDispatchEnabled = data.manualDispatchEnabled ? '1' : '0';

    if (!data.stagingSite) {
      delete data.stagingSite;
    }

    addOrder
      .addAndStartOrder(data)
      .then(() => {
        swal('Success!', '', 'success');
        dispatch(success());
        closeAddOrderModal();
        dispatch(initActions.getDetails(data.jobId));
        dispatch(orderListActions.init(initFilters));
        queryClient.invalidateQueries(['jobs', jobId])
      })
      .catch(error => {
        dispatch(fail());
        swal(error.response.data.message, '', 'error');
      });
  }
};

const getCalculatedPickUp = passedData => dispatch => {
  addOrder
    .getCalculatedPickUp(passedData)
    .then(response => {
      dispatch(setInputValue(moment(response.data.data).local(), 'loadDate'));
    })
    .catch(() => {});
};

export default {
  getSandTypeByPoData,
  getStage,
  setInputValue,
  setAutoAssignValue,
  setManualValue,
  getFreeDrivers,
  submitAddOrder,
  clearModalStore,
  getCalculatedPickUp,
};
