import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';

import Authorize from 'components/common/Authorize';
import Header from 'components/common/Header';

import QueueMark from './QueueMark';
import ChooseSettings from './ChooseSettings';
import CompanyAddress from './CompanyAddress';
import AuditSettings from './AuditSettings';
import AlertsSettings from './AlertsSettings';

const Settings = () => (
  <div>
    <Header title="Settings" smallTitle="Settings" />
    <Authorize
      abilityPermissions={[{ resource: 'Admin', permissions: ['read'] }]}>
      <div className="row m-t-sm">
        <div className="col-md-12">
          <div className="tabs-container">
            <Tabs>
              <Tab eventKey="system" title="System Settings">
                <div>
                  <ChooseSettings />
                  <QueueMark />
                </div>
              </Tab>
              {/* Temporary commented, it may to bring back later */}
              {/* <Tab eventKey={2} title="Fuel Price">
                  {tabKey === 2 &&  (
                    <FuelPrice />
                  )}
                  {tabKey === 2 &&  (
                    <FuelList />
                  )}
                </Tab> */}
              <Tab eventKey="address" title="Company address">
                <CompanyAddress />
              </Tab>
              <Tab eventKey="audit" title="Audit Settings">
                <AuditSettings />
              </Tab>
              <Tab eventKey="alerts" title="Alerts Settings">
                <AlertsSettings />
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </Authorize>
  </div>
);

export default Settings;
