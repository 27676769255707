import React, { useEffect, useState } from 'react';
import Authorize from 'components/common/Authorize';
import { connect } from 'react-redux';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import manageCustomersActions from 'store/actions/ManageCustomers';
import dispatchEventValue from 'utils/dispatchWithFieldValue';
import { Card, Col, Row } from 'react-bootstrap';
import { filteredCustomers as FILTERCustomers, filteredWell } from './helpers';
import AdminCustomerHeader from './AdminCustomerHeader';
import CustomerList from './CustomerList';
import WellSiteList from './WellsiteList';

const ManageCustomers = ({
  customers,
  wellSites,
  isLoadedCustomers,
  setCustomerName,
  filteredCustomers,
  clearStoreManageCustomers,
  getCustomers,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(true);

  useEffect(() => {
    getCustomers();
    return () => {
      clearStoreManageCustomers();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <AdminCustomerHeader customers={customers} />
      <Authorize
        abilityPermissions={[{ resource: 'Customers', permissions: ['read'] }]}>
        <Card>
          <Card.Header>
            <Row>
              <Col md={3} className="form-group has-feedback">
                <Input
                  onChange={setCustomerName}
                  label="Search name"
                  testSelector="admin-customers_manage_search-name_input"
                />
              </Col>
              <Col md={9}>
                <span className="text-label float-end">
                  All customers
                  <Icon
                    onClick={() => {
                      setSelectedCategory(s => !s);
                    }}
                    data-testid="wellsite-toggle"
                    icon={
                      selectedCategory
                        ? 'toggle-off inactive'
                        : 'toggle-on active'
                    }
                  />
                  All Locations
                </span>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body>
            {selectedCategory ? (
              isLoadedCustomers && (
                <CustomerList
                  customers={filteredCustomers}
                  wellSites={wellSites}
                />
              )
            ) : (
              <Authorize
                {...{
                  abilityPermissions: [
                    {
                      resource: 'Locations',
                      permissions: ['read'],
                    },
                  ],
                }}>
                <WellSiteList customers={customers} wellSites={wellSites} />
              </Authorize>
            )}
          </Card.Body>
        </Card>
      </Authorize>
    </div>
  );
};

const mapStateToProps = state => ({
  isLoadedCustomers: state.resourceReducer.customers.isLoadedCustomers,
  customers: state.resourceReducer.customers.customers,
  wellSites: filteredWell(state),
  filteredCustomers: FILTERCustomers(state),
});

const mapDispatchToProps = dispatch => ({
  getCustomers: () => dispatch(resourceActions.getCustomers()),
  setCustomerName: dispatchEventValue(
    manageCustomersActions.setCustomerName,
    dispatch,
  ),
  clearStoreManageCustomers: () =>
    dispatch(manageCustomersActions.clearStoreManageCustomers()),
});

export const ManageCustomersTest = ManageCustomers; // for Tests

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ManageCustomers);
