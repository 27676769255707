import React, { useState } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderDetails';
import { deleteAlert } from 'components/sweetalert';
import { priceTypes } from 'components/globalInfo/priceTypes';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Authorize, { authorize } from 'components/common/Authorize';
import {
  getFormattedCustomPrice,
  getFormattedCarrierPrice,
} from 'utils/convertNumberToCurrency';
import { filterBreakdown } from 'utils/paymentItemCalculations';
import Demurrage from './Demurrage';
import PriceInfo from './PriceInfo';
import AddPrice from './AddPrice';


const Pricing = props => {
  const [isModalAddVisbile, setModalAdd] = useState(false);
  const [isModalDemurrageVisible, setModalDemurrage] = useState(false);
  const [isModalPriceInfoVisible, setModalPriceInfo] = useState(false);

  const openDemurrage = item => {
    setModalDemurrage(true);
    props.openDemurrageTicket(item);
  };

  const {
    deleteAdditionalPrice,
    info: { pricebreakdown, order, totalPrice, carrierPrice },
    downloadInvoicePdf,
  } = props;

  const roundedPrice = Math.round((totalPrice ?? 0) * 100) / 100;
  const filteredBreakdown = filterBreakdown(pricebreakdown);

  return (
    <>
      {isModalAddVisbile && (
        <AddPrice
          showModalAdd={isModalAddVisbile}
          closeAdd={() => setModalAdd(false)}
        />
      )}
      {isModalPriceInfoVisible && (
        <PriceInfo
          showModalPriceInfo={isModalPriceInfoVisible}
          closeInfo={() => setModalPriceInfo(false)}
        />
      )}
      {isModalDemurrageVisible && (
        <Demurrage
          showModalDemurrage={isModalDemurrageVisible}
          closeDemurr={() => setModalDemurrage(false)}
        />
      )}
      <section className="margin-auto max-px600">
        <table className="table table--compressed table--no-borders">
          <thead>
            <tr>
              <th>Title</th>
              <th className="align--right">Price</th>
            </tr>
          </thead>
          <tbody>
            {filteredBreakdown.map((item, index) => (
              <tr key={index}>
                {!!item.price && (
                  <>
                    <td>
                      {!order.is_deleted && !order.invoice_id && (
                        <>
                          {item.type > 6 && (
                            <Authorize
                              {...{
                                abilityPermissions: [
                                  {
                                    resource: 'Pricing',
                                    permissions: ['delete'],
                                  },
                                ],
                              }}>
                              <Icon
                                onClick={() =>
                                  deleteAlert(
                                    deleteAdditionalPrice,
                                    order.order_id,
                                    item.id,
                                  )
                                }
                                style={{ cursor: 'pointer' }}
                                className="text-danger"
                                icon="trash"
                              />
                            </Authorize>
                          )}
                          {[2, 3].includes(item.type) && (
                            <Icon
                              onClick={() => openDemurrage(item)}
                              style={{ cursor: 'pointer' }}
                              className="text-navy"
                              icon="pencil"
                            />
                          )}
                        </>
                      )}
                      {priceTypes[item.type].title}
                      {item.name && (
                        <span className="text-navy">
                          {` - ${item.name}`}{' '}
                        </span>
                      )}
                      {item.comment && (
                        <div className="text-navy">
                          {` (${item.comment})`}{' '}
                        </div>
                      )}
                      {[2, 3].includes(item.type) && (
                        <span className="text-navy">
                          {` - ${
                            item.type === 2
                              ? order.sandsite_demurrage_reason_name ||
                                'no reason'
                              : order.wellsite_demurrage_reason_name ||
                                'no reason'
                          }`}
                        </span>
                      )}
                    </td>
                    <td className="align--right">
                      <Authorize {...{ access: ['JOB_MANAGER', 'CUSTOMER'] }}>
                        {getFormattedCustomPrice(item, order)}
                        {getFormattedCarrierPrice(item, order)}
                      </Authorize>
                      <Authorize {...{ access: ['CARRIER'] }}>
                        <span className="text-navy">
                          {getFormattedCarrierPrice(item, order)}
                        </span>
                      </Authorize>
                    </td>
                  </>
                )}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr style={{ fontWeight: 'bolder' }}>
              <td className="align--right">Total Price</td>
              <td className="align--right">
                <Authorize
                  {...{
                    itemAccessGranted: !order.is_deleted,
                    forbidden: ['CARRIER'],
                  }}>
                  <span>${roundedPrice} </span>
                </Authorize>
                {authorize({
                  access: ['JOB_MANAGER', 'CUSTOMER'],
                }) && <span className="text-navy">( ${carrierPrice} )</span>}
                {authorize({
                  access: ['CARRIER'],
                }) && <span>${carrierPrice} </span>}
                <Authorize
                  {...{
                    itemAccessGranted: !order.is_deleted,
                    forbidden: ['CARRIER'],
                  }}>
                  <Icon
                    icon="info-circle"
                    className="text-navy"
                    onClick={() => setModalPriceInfo(true)}
                    style={{ cursor: 'pointer' }}
                  />
                </Authorize>
              </td>
            </tr>
            <tr>
              <td colSpan={2} className="align--right">
                {!order.invoice_id && (
                  <Authorize
                    {...{
                      itemAccessGranted: !order.is_deleted,
                      abilityPermissions: [
                        { resource: 'Pricing', permissions: ['update'] },
                      ],
                    }}>
                    {(order.accessLevel === 'owner' ||
                      order.accessLevel === 'shared' ||
                      order.accessLevel === 'shared_creator') && (
                      <Button
                        theme="small"
                        colour="blue"
                        testSelector="order-details_pricing_add_btn"
                        onClick={() => setModalAdd(true)}>
                        Add Price
                      </Button>
                    )}
                  </Authorize>
                )}
                <Button
                  onClick={() => downloadInvoicePdf(order.order_id)}
                  theme="small"
                  colour="green"
                  testSelector="order-details_pricing_download-invoice_btn"
                  style={{ margin: 0 }}>
                  Download Invoice #{order.bol || 'unknown'}
                </Button>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                {order.invoice_id && `Invoice ID #${order.invoice_id}`}
              </td>
            </tr>
          </tfoot>
        </table>
      </section>
    </>
  );
};

const mapStateToProps = state => ({
  info: state.orderDetails.info,
});

const mapDispatchToProps = dispatch => ({
  downloadInvoicePdf: () => dispatch(actions.downloadInvoicePdf()),
  deleteAdditionalPrice: (orderId, priceId) =>
    dispatch(actions.deleteAdditionalPrice(orderId, priceId)),
  openDemurrageTicket: item => dispatch(actions.openDemurrageTicket(item)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Pricing);
