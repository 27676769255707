import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import { Modal } from 'react-bootstrap';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import actions from 'store/actions/CustomerEditBillingTerms';
import actionsCustomerDetails from 'store/actions/CustomerDetails';
import {
  billOptions,
  auditOptions,
} from 'components/globalInfo/customerBillings';
import Loader from 'components/shared/Loader';
import { billableOptions } from '../../CustomerDetails/Info/EditBillingTerms/helpers';

const AddBillingTerms = ({
  terms,
  setValue,
  updateJobOpValue,
  saveEdit,
  clear,

  showEdit,
  customerDetails,
  initCustomerDetails,
  initBillingTerms,
  closeAdd,
}) => {
  const [custId, setCustId] = useState(customerDetails.details.id);
  const { details } = customerDetails;

  const {
    dueDays,
    billingFrequency,
    billTo,
    bulkInvoiceEnabled,
    packetInvoiceEnabled,
    csvInvoiceEnabled,
    nonBillable,
    billableOperationTypeStatus,
  } = terms;

  useEffect(() => {
    clear();
    return () => {
      clear();
    };
  }, []);

  useEffect(() => {
    if (details.id) {
      initBillingTerms(details.id);
      setCustId(details.id);
    }
  }, [details.id]);

  useEffect(() => {
    if (custId) {
      initCustomerDetails(custId);
    }
  }, [custId]);

  const updateBillableOpType = value => {
    const opType = billableOperationTypeStatus.find(
      type => type.operationType === value,
    );
    opType.billableStatus = !opType.billableStatus;
    updateJobOpValue(opType);
  };

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showEdit} onHide={closeAdd}>
        <form
          onSubmit={e => saveEdit(e, closeAdd, custId)}
          className="m-t"
          role="form"
          encType="multipart/form-data">
          <Modal.Header>
            <h3>Save Billing Details</h3>
          </Modal.Header>
          <Modal.Body>
            <div className="edit-billing-details-modal">
              <div className="edit-billing-details-modal__column">
                <Input
                  onChange={value => setValue(value, 'dueDays')}
                  value={dueDays}
                  label="Due date (days)"
                  required
                  testSelector="admin-customers_add-billing-terms_due-date_input"
                />
                <Input
                  onChange={value => setValue(value, 'billingFrequency')}
                  value={billingFrequency}
                  label="Billing frequency (days)"
                  required
                  testSelector="admin-customers_add-billing-terms_billing-frequency_input"
                />
                <label className="small font-noraml">Bill to</label>
                <Select
                  value={billOptions.find(item => item.value === billTo)}
                  onChange={item => setValue(item.value, 'billTo')}
                  placeholder="Bill to"
                  options={billOptions}
                  required
                  testSelector="admin-customers_add-bill-terms_bill-to_select"
                />
                <Select
                  placeholder="Audit required"
                  options={auditOptions}
                  onChange={selectedAuditRequirements =>
                    setValue(
                      selectedAuditRequirements.map(item => item.value),
                      'approvalsRequired',
                    )
                  }
                  defaultValue={auditOptions.filter(
                    option =>
                      (details &&
                        details.approvalsRequired &&
                        details.approvalsRequired.includes(option.value)) ||
                      false,
                  )}
                  closeMenuOnSelect={false}
                  isDisabled={nonBillable}
                  isMulti
                  testSelector="admin-customers_add-bill-terms_audit-required_select"
                />
              </div>

              <div className="edit-billing-details-modal__column">
                <Input
                  type="checkbox"
                  testSelector="admin-customers_add-billing-terms_bulk-invoice_input"
                  isChecked={bulkInvoiceEnabled}
                  onChange={e => setValue(e, 'bulkInvoiceEnabled')}
                  label="Attach bulk invoice"
                />
                <Input
                  type="checkbox"
                  testSelector="admin-customers_add-billing-terms_packet-invoice_input"
                  isChecked={packetInvoiceEnabled}
                  onChange={e => setValue(e, 'packetInvoiceEnabled')}
                  label="Attach packet invoice"
                />
                <Input
                  type="checkbox"
                  testSelector="admin-customers_add-billing-terms_csv-invoice_input"
                  isChecked={csvInvoiceEnabled}
                  onChange={e => setValue(e, 'csvInvoiceEnabled')}
                  label="Attach csv invoice"
                />
                <label className="small font-noraml" style={{ marginTop: 5 }}>
                  Billable status per Job Type
                </label>
                {billableOperationTypeStatus?.length ? (
                  billableOptions(billableOperationTypeStatus).map(option => (
                    <Input
                      key={option.value}
                      type="checkbox"
                      testSelector={`admin-customers_add-billing-terms_status-per-job-type_${
                        option.value
                      }`}
                      isChecked={option.isChecked}
                      onChange={e => {
                        updateBillableOpType(option.value);
                      }}
                      label={option.label}
                    />
                  ))
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              onClick={closeAdd}
              colour="white"
              testSelector="admin-customers_billing-terms_close_btn">
              Close
            </Button>
            <Button
              type="submit"
              testSelector="admin-customers_billing-terms_save_btn">
              Save
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};
const mapStateToProps = state => ({
  terms: state.customerDetails.editBillingTerms,
  customerDetails: state.customerDetails.customer,
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(actions.init()),
  setValue: (e, name) => dispatch(actions.setValue(targetValue(e), name)),
  updateJobOpValue: data => dispatch(actions.updateJobOpValue(data)),
  saveEdit: (e, closeAdd, passedId) => {
    e.preventDefault();
    dispatch(actions.saveEditWithId(closeAdd, passedId));
  },
  clear: () => dispatch(actions.clear()),

  initCustomerDetails: customerId =>
    dispatch(actionsCustomerDetails.init(customerId)),

  initBillingTerms: customerId =>
    dispatch(actionsCustomerDetails.initBillingTerms(customerId)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddBillingTerms);
