import React from 'react';
import IboxTools from 'components/common/IboxTools';
import { timeZones } from 'components/globalInfo/timeZones';
import { sortList, formatList } from 'components/globalInfo/export';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import { Col, Form, Row } from 'react-bootstrap';

const Export = ({
  setFilterValue,
  templates,
  isLoadedTemplates,
  exportSettings,
  download,
}) => {
  const { format, sortBy, timeZone, invoiceId, template } = exportSettings;

  let selectedBtn = '';
  const setSelectedBtn = value => {
    selectedBtn = value;
  };

  const timeZoneList =
    timeZones.map(data => ({
      value: data.value,
      label: data.name,
    })) || [];

  const templateList =
    (isLoadedTemplates &&
      templates.map((item, index) => ({
        value: index,
        label: item.name,
      }))) ||
    [];

  return (
    <Col className="ibox float-e-margins">
      <div className="ibox-title">
        <h5>Export</h5>
        <IboxTools />
      </div>
      <Form onSubmit={e => download(e, selectedBtn)} className="ibox-content">
        <Row className="form-group has-feedback">
          <Col md={6} className="form-group has-feedback">
            <Input
              type="number"
              onChange={e => setFilterValue(e, 'invoiceId')}
              value={invoiceId}
              label="Invoice ID"
              testSelector="export_orders_invoice-id_input"
            />
          </Col>
          <Col md={6} className="form-group has-feedback">
            <Select
              label="Sort by"
              value={sortList.find(sortItem => sortItem.value === sortBy)}
              onChange={item => setFilterValue(item.value, 'sortBy')}
              options={sortList}
              required
              testSelector="export_orders_sort-by_select"
            />
          </Col>
          <Col md={6} className="form-group has-feedback">
            <Select
              label="Time zone"
              value={timeZoneList.find(zone => zone.value === timeZone)}
              onChange={item => setFilterValue(item.value, 'timeZone')}
              options={timeZoneList}
              required
              testSelector="export_orders_time-zone_select"
            />
          </Col>
          <Col md={6} className="form-group has-feedback">
            <Select
              label="Format"
              value={formatList.find(option => option.value === format)}
              onChange={item => setFilterValue(item.value, 'format')}
              options={formatList}
              required
              testSelector="export_orders_format_select"
            />
          </Col>
          {['csv', 'json'].includes(format) && (
            <Col md={6} className="form-group has-feedback">
              <Select
                label="Template"
                name="template"
                options={templateList}
                placeholder="Please Select Template"
                value={templateList.find(item => item.value === template)}
                onChange={item => setFilterValue(item.value, 'template')}
                required
                testSelector="export_orders_template_select"
              />
            </Col>
          )}
        </Row>
        <Row className="form-group has-feedback">
          <Button
            type="submit"
            onClick={() => setSelectedBtn('download')}
            theme="small"
            testSelector="export_orders_exoirt_download_btn">
            Download
          </Button>
          {(format === 'csv' || format === 'json') && (
            <Button
              type="submit"
              onClick={() => setSelectedBtn('preview')}
              theme="small"
              colour="green"
              testSelector="export_orders_exoirt_preview_btn">
              Preview
            </Button>
          )}
        </Row>
      </Form>
    </Col>
  );
};
export default Export;
