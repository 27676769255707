import React from 'react';
import { Tabs, Tab } from 'react-bootstrap';
import { connect } from 'react-redux';
import authService from 'services/auth/service';
import actions from 'store/actions/OrderDetails';
import { authorize } from 'components/common/Authorize';
import Timeline from './Timeline';
import Comments from './Comments';
import Transfers from './Transfers';
import Pricing from './Pricing';
import SandTickets from './SandTickets';
import OrderDetailsMap from './Map';
import Financial from './Financial';
// import Chat from './Chat';

const OrderTabs = ({changeKey, tabKey, info}) => {
  const { order, containers, transfers } = info;
  const blockStyle = { paddingTop: '15px', minHeight: '300px' };
  const user = authService.getUser();
  const isPricePermission = user.entityType === 3
    ? user.entityId === order.carrier_id
    : true;

  return (
    <Tabs activeKey={tabKey} onSelect={changeKey} id="controlled-tab-example">
      <Tab eventKey={1} title="Geofence">
        <div style={blockStyle}>
          {tabKey == 1 && <Timeline {...{ order, containers }} />}
        </div>
      </Tab>
      {authorize({
        forbidden: ['JOB_COORDINATOR'],
        abilityPermissions: [{ resource: 'Pricing', permissions: ['read'] }],
      }) &&
        isPricePermission && (
          <Tab eventKey={2} title="Pricing">
            <div style={blockStyle}>{tabKey == 2 && <Pricing />}</div>
          </Tab>
        )}
      <Tab eventKey={3} title="Documents">
        <div style={blockStyle}>{tabKey == 3 && <SandTickets />}</div>
      </Tab>
      <Tab eventKey={4} title="Map">
        <div style={blockStyle}>
          {tabKey == 4 && <OrderDetailsMap {...{ order }} />}
        </div>
      </Tab>
      {/* {authorize({
        abilityPermissions: [{ resource: 'Chat', permissions: ['read'] }],
      }) && (
        <Tab eventKey={5} title="Chat">
          <div style={blockStyle}>{tabKey == 5 && <Chat {...{ order }} />}</div>
        </Tab>
      )} */}
      <Tab eventKey={6} title="Logs">
        <div style={blockStyle}>
          {tabKey == 6 && <Comments {...{ order }} />}
        </div>
      </Tab>
      <Tab eventKey={7} title="Transfers">
        <div style={blockStyle}>
          {tabKey == 7 && <Transfers {...{ transfers }} />}
        </div>
      </Tab>
      {authorize({
        abilityPermissions: [
          { resource: 'Carrier-documents', permissions: ['update'] },
        ],
      }) && (
        <Tab eventKey={8} title="Financial">
          <div style={blockStyle}>
            {tabKey == 8 && (
              <Financial
                orderId={order.order_id}
                billingStatus={order.billing_status.toString()}
              />
            )}
          </div>
        </Tab>
      )}
    </Tabs>
  );
};

const mapStateToProps = state => ({
    info: state.orderDetails.info,
    tabKey: state.orderDetails.tab.key,
  });
  
  const mapDispatchToProps = dispatch => ({
    changeKey: value => dispatch(actions.changeKey(value)),
  });
  
  export default
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(OrderTabs);