import { client } from 'api/client';

export const getCustomers = async () => {
  const { data } = await client.get('v2/customer');
  return data.data.customers;
};

export const getCustomerBillingProfiles = async ({ customerId, status }) => {
  const numStatus = Number(status);
  const isValid = !Number.isNaN(numStatus);
  const queryItem = isValid ? numStatus : 1;

  const { data } = await client.get(
    `v2/billing/customer/${customerId}/profiles?active=${queryItem}`,
  );
  return data.data;
};
