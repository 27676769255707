import React from 'react';
import Link from 'components/common/Link';
import { authorize } from 'components/common/Authorize';

export const linkPermission = (id, name, link, resource) => (
  <span>
    {!authorize({
      abilityPermissions: [
        {
          resource: resource,
          permissions: ['read'],
        },
      ],
    }) ? (
      <>{`#${id || 'unknown'} | ${name || 'unknown'}`}</>
    ) : (
      <>
        <Link {...{ id, to: link }}>
          {`#${id || 'unknown'} | ${name || 'unknown'}`}
        </Link>
      </>
    )}
  </span>
);
