import React, { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import Loader from 'components/shared/Loader';
import Authorize from 'components/common/Authorize';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';

import LineGraph from './graph';
import StorageRules from './StorageRules';
import StorageUnit from './StorageUnit';
import StorageHistoryModal from './StorageHistoryModal';
import AddInventoryModal from './AddInventoryModal';
import StorageImportModal from './StorageImportModal';
import AddUomOnsiteStorageSectionType from './AddUomStorageSection';
import {
  useStorageColors,
  useStorageGroups,
  useUnitOfMeasureOptions,
} from './hooks';
import StorageGroupStatus from './StorageGroupStatus';
import { useJobPermission } from '../../hooks';
import { useJobDetails } from 'api/v2/jobs';

/**
 * @typedef {{
 *  externalId: number;
 *  externalProvider: string;
 *  jobId: number;
 *  lastSyncTimestamp: string;
 *  storageId: number;
 *  units: {
 *    beltSpeedPercent: number;
 *    capacityWeight: number;
 *    commodityType: string;
 *    commodityTypeId: number;
 *    currentWeight: number;
 *    flowStatus: 1 | 0 | -1;
 *    id: number;
 *    levelPercentage: number;
 *    storageType: string;
 *    weightDifference;
 *    details: object;
 *  }[];
 *  details: object;
 * }} StorageGroup
 */

const OnsiteStorage = ({ jobId, hideStorageRules, hideOnNoData }) => {
  const isFullEdit = useJobPermission(jobId);
  const [includeDeleted, setIncludeDeleted] = useState(false);
  const [unitOfMeasureId, setUnitOfMeasureId] = useState(null);
  const { storageGroups, storageConfig, isLoading } = useStorageGroups(
    jobId,
    unitOfMeasureId,
    includeDeleted,
  );
  const unitOfMeasureOptions = useUnitOfMeasureOptions(jobId);
  const { data: jobDetails } = useJobDetails({ jobId });
  const customerId = jobDetails?.details?.customerId;
  const [addUomStorageModalOpen, setAddUomStorageModalOpen] = useState(false);
  const toggleAddUomStorageModalOpen = () =>
    setAddUomStorageModalOpen(!addUomStorageModalOpen);

  const { commodityColors, jobCommodityTypes } = useStorageColors(
    storageGroups,
  );

  /**
   * @param {StorageGroup} storageGroup
   * @returns {JSX.Element[]}
   */
  const renderStorageGroupDetails = storageGroup => (
    <Row>
      <Col>
        <Row>
          {storageGroup.units.map(unit => (
            <Col
              sm={4}
              md={4}
              lg={4}
              style={{ textAlign: 'center' }}
              key={`${storageGroup.externalId}-${unit.id}`}>
              <StorageUnit
                name={storageGroup.name}
                unit={unit}
                color={commodityColors[unit.commodityTypeId].color}
                totalUnits={storageGroup.units.length}
                customerId={customerId}
              />
            </Col>
          ))}
          {storageGroup.externalProvider === 'manual' &&
          !storageGroup.details.isDeleted ? (
            <Col md={8}>
              <Row style={{ paddingTop: 85 }}>
                <Col>
                  <Authorize
                    abilityPermissions={[
                      { resource: 'Scada', permissions: ['update'] },
                    ]}
                    itemAccessGranted={isFullEdit}>
                    <AddInventoryModal storageGroup={storageGroup} />
                  </Authorize>
                </Col>
                <Col>
                  <Authorize
                    abilityPermissions={[
                      { resource: 'Scada', permissions: ['read'] },
                    ]}>
                    <StorageHistoryModal storageGroup={storageGroup} jobId={jobId} />
                  </Authorize>
                </Col>
              </Row>
            </Col>
          ) : null}
        </Row>
      </Col>
      <Col>
        <LineGraph
          sandColors={commodityColors}
          jobId={jobId}
          storageId={storageGroup.storageId}
        />
        <StorageGroupStatus storageGroup={storageGroup} />
      </Col>
    </Row>
  );

  if (isLoading) {
    return <Loader />;
  }

  if (!storageGroups?.length && hideOnNoData) {
    return <div className="font-bold text-danger textCenter">No Data</div>;
  }

  return (
    <Container>
      <Row>
        <Col>
          <Button
            onClick={() => setIncludeDeleted(!includeDeleted)}
            colour={includeDeleted ? 'yellow' : ''}
            inverse={!includeDeleted}>
            {includeDeleted ? 'Showing' : 'Show'} Discontinued Storage
          </Button>
        </Col>
        <Col>
          <Select
            placeholder="UOM"
            options={unitOfMeasureOptions.filter(
              x => x.value !== unitOfMeasureId,
            )}
            value={unitOfMeasureOptions.find(x => x.value === unitOfMeasureId)}
            onChange={e => setUnitOfMeasureId(e?.value)}
          />
        </Col>
        <Col>
          <Authorize
            abilityPermissions={[{ resource: 'Jobs', permissions: ['update'] }]}
            itemAccessGranted={isFullEdit}>
            <div className="float-end">
              <Button onClick={toggleAddUomStorageModalOpen}>
                add new uom storage +
              </Button>
            </div>
          </Authorize>
          <Authorize
            abilityPermissions={[{ resource: 'Scada', permissions: ['update'] }]}
            itemAccessGranted={isFullEdit}>
            <div className="float-end">
              <StorageImportModal jobId={jobId} />
            </div>
          </Authorize>
        </Col>
      </Row>
      <Row>
        {!!storageGroups.length && (
          <Row>{storageGroups.map(renderStorageGroupDetails)}</Row>
        )}
      </Row>
      {!hideStorageRules && (
        <Authorize
          abilityPermissions={[{ resource: 'Jobs', permissions: ['read'] }]}>
          <StorageRules
            sandTypes={jobCommodityTypes}
            jobId={jobId}
            unitGroups={storageGroups}
          />
        </Authorize>
      )}

      <AddUomOnsiteStorageSectionType
        jobId={jobId}
        closeModal={toggleAddUomStorageModalOpen}
        showModal={addUomStorageModalOpen}
        storageConfig={storageConfig}
        storageGroups={storageGroups}
      />
    </Container>
  );
};
export default OnsiteStorage;
