import _ from 'lodash';
import { customer } from 'services/customer/service';
import swal from 'bootstrap-sweetalert';
import initActions from './CustomerDetails';

const clear = () => ({ type: 'CUSTOMER_DETAILS_BILLING_TERMS_CLEAR' });

const init = () => (dispatch, getState) => {
  const details = _.cloneDeep(getState().customerDetails.customer.details);

  let terms = details.billingTerms;
  terms.bulkInvoiceEnabled = Boolean(terms.bulkInvoiceEnabled);
  terms.csvInvoiceEnabled = Boolean(terms.csvInvoiceEnabled);
  terms.nonBillable = Boolean(terms.nonBillable);
  terms.packetInvoiceEnabled = Boolean(terms.packetInvoiceEnabled);

  terms = {
    ...terms,
    approvalsRequired: getState().customerDetails.customer.details
      .approvalsRequired,
    billableOperationTypeStatus: details.billableOperationTypes,
  };
  dispatch({ type: 'CUSTOMER_DETAILS_INIT_BILLING_TERMS', terms });
};

const setValue = (value, name) => ({
  type: 'CUSTOMER_DETAILS_BILLING_TERMS_SET_VALUE',
  value,
  name,
});

const updateJobOpValue = data => ({
  type: 'CUSTOMER_DETAILS_BILLING_TERMS_UPDATE_JOB_OP_VALUE',
  data,
});

const saveEdit = closeEdit => (dispatch, getState) => {
  const state = getState();
  const {
    customerDetails: {
      editBillingTerms,
      customer: {
        details: { id },
      },
    },
  } = state;

  const data = {
    billTo: editBillingTerms.billTo,
    billingFrequency: editBillingTerms.billingFrequency,
    bulkInvoiceEnabled: editBillingTerms.bulkInvoiceEnabled,
    bulkInvoiceTemplateId: editBillingTerms.bulkInvoiceTemplateId,
    csvInvoiceEnabled: editBillingTerms.csvInvoiceEnabled,
    nonBillable: editBillingTerms.nonBillable,
    dueDays: editBillingTerms.dueDays,
    packetInvoiceEnabled: editBillingTerms.packetInvoiceEnabled,
    id,
  };

  if (editBillingTerms.approvalsRequired.length) {
    data.approvalsRequired = editBillingTerms.approvalsRequired;
  }
  if (editBillingTerms.billableOperationTypeStatus.length) {
    data.billingOperationTypes = editBillingTerms.billableOperationTypeStatus;
  }

  customer.billingTerms
    .edit(data)
    .then(msg => {
      swal('Success!', '', 'success');
      closeEdit();
      dispatch(initActions.init(data.id));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const saveEditWithId = (closeEdit, passedId) => (dispatch, getState) => {
  const state = getState();
  const {
    customerDetails: {
      editBillingTerms,
      customer: {
        details: { id },
      },
    },
  } = state;

  const data = {
    billTo: editBillingTerms.billTo,
    billingFrequency: editBillingTerms.billingFrequency,
    bulkInvoiceEnabled: editBillingTerms.bulkInvoiceEnabled,
    bulkInvoiceTemplateId: editBillingTerms.bulkInvoiceTemplateId,
    csvInvoiceEnabled: editBillingTerms.csvInvoiceEnabled,
    nonBillable: editBillingTerms.nonBillable,
    dueDays: editBillingTerms.dueDays,
    packetInvoiceEnabled: editBillingTerms.packetInvoiceEnabled,
    id: passedId,
  };

  if (editBillingTerms.approvalsRequired.length) {
    data.approvalsRequired = editBillingTerms.approvalsRequired;
  }
  if (editBillingTerms.billableOperationTypeStatus.length) {
    data.billingOperationTypes = editBillingTerms.billableOperationTypeStatus;
  }

  customer.billingTerms
    .edit(data)
    .then(msg => {
      swal('Success!', '', 'success');
      closeEdit();
      dispatch(initActions.init(data.id));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

export default {
  init,
  clear,
  setValue,
  updateJobOpValue,
  saveEdit,
  saveEditWithId,
};
