import React, { useEffect } from 'react';
import { Modal, Image } from 'react-bootstrap';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderDetails';
import { convertTimeWithSeconds } from 'utils/convertTime';
import orderDetailsModel from 'models/OrderDetails';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';

const PreviewSandTicket = ({
  showPreview,
  closePreview,
  downloadSandTicket,
  img,
  info,
  selectedTicket,
  getSandTicket,
}) => {
  useEffect(() => {
    getSandTicket();
  }, []);

  const { order, sandTicketIds } = info;
  const { load_arrival, load_depart, well_arrival, well_depart } = order;
  const { toImgUrl } = orderDetailsModel;

  const imgUrl = toImgUrl(img);

  return (
    <div className="inmodal">
      <Modal
        style={{ top: `${5}%` }}
        className="modal-container"
        show={showPreview}
        onHide={closePreview}>
        <Modal.Header>
          <h4 className="modal-title">Preview Ticket</h4>
        </Modal.Header>
        <Modal.Body>
          <div className="animated fadeInRight">
            <div className="ibox-content ">
              <div>
                {img ? (
                  <Image src={imgUrl} width="100%" alt="preview" />
                ) : (
                  <Loader />
                )}
              </div>
            </div>
          </div>
          <hr style={{ borderColor: 'rgb(170, 170, 170)' }} />
          <div className="row" style={{ marginTop: '10px' }}>
            <div className="col-lg-3">
              <span> Origin arrival time </span>
              <h5 className="font-bold">
                <strong>{convertTimeWithSeconds(load_arrival)}</strong>
              </h5>
            </div>
            <div className="col-lg-3">
              <span> Origin departure time </span>
              <h5 className="font-bold">
                <strong>{convertTimeWithSeconds(load_depart)}</strong>
              </h5>
            </div>
            <div className="col-lg-3">
              <span> Destination arrival time </span>
              <h5 className="font-bold">
                <strong>{convertTimeWithSeconds(well_arrival)}</strong>
              </h5>
            </div>
            <div className="col-lg-3">
              <span> Destination departure time </span>
              <h5 className="font-bold">
                <strong>{convertTimeWithSeconds(well_depart)}</strong>
              </h5>
            </div>
          </div>
          <div className="row">
            {sandTicketIds
              .filter(ticket => ticket.id === selectedTicket)
              .map(ticket => (
                <div key={ticket.id}>
                  <div className="col-lg-3">
                    <span> ocr arrival: </span>
                    <strong>{ticket.ocr_arrival || ' - '}</strong>
                  </div>
                  <div className="col-lg-3">
                    <span> ocr departure: </span>
                    <strong>{ticket.ocr_departure || ' - '}</strong>
                  </div>
                  <div className="col-lg-3">
                    <span> ocr gross: </span>
                    <strong>{ticket.ocr_gross}</strong>
                    <br />
                    <span> ocr tare: </span>
                    <strong>{ticket.ocr_tare}</strong>
                  </div>
                  <div className="col-lg-3">
                    <span> ocr net: </span>
                    <strong>{ticket.ocr_net}</strong>
                    <br />
                    <span> ocr bol: </span>
                    <strong>{ticket.ocr_bol || ' - '}</strong>
                  </div>
                </div>
              ))}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            onClick={closePreview}
            colour="#6fc6d9"
            testSelector="order-details_sand-tickets_preview_close_btn">
            Close
          </Button>
          <Button
            onClick={() => downloadSandTicket()}
            testSelector="order-details_sand-tickets_preview_download_btn">
            Download
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  img: state.orderDetails.modalPreviewST.img,
  info: state.orderDetails.info,
  selectedTicket: state.orderDetails.sandTicket.selectedTicket,
});

const mapDispatchToProps = dispatch => ({
  getSandTicket: () => dispatch(actions.getSandTicket()),
  deleteSandTicket: closePreview =>
    dispatch(actions.deleteSandTicket(closePreview)),
  downloadSandTicket: () => {
    dispatch(actions.downloadSandTicket());
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PreviewSandTicket);
