import React, { useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import swal from 'bootstrap-sweetalert';
import moment from 'moment';

import DatePicker from 'components/shared/DatePicker';
import Input from 'components/shared/Input';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Select from 'components/shared/Select';
import Loader from 'components/shared/Loader';
import ToolTip from 'components/shared/ToolTip';

import targetValue from 'utils/targetValue';
import { useJobDetails, useUpsertCarrierCommitments } from 'api/v2/jobs';
import { useCarriers } from 'api/v2/carriers';
import {
  calculateRequiredTrucks,
  isDateFormatValid,
  validateCommitmentTimes,
} from './helpers';

const CarrierCommitmentModal = ({ show, closeModal, job }) => {
  const { data: jobDetails, isLoading } = useJobDetails({ jobId: job?.id });
  const { mutateAsync: upsertCarrierCommitments } = useUpsertCarrierCommitments(
    job?.id,
  );

  const { data: carriers } = useCarriers();
  const carrierOptions = useMemo(
    () =>
      (carriers || []).map(carrier => ({
        label: carrier.name,
        value: carrier.id,
      })),
    [carriers],
  );

  const [carrierCommitments, setCarrierCommitments] = useState([]);
  const [saveDisabled, disableSave] = useState(false);
  const [isBadTimeFormat, setBadTimeFormat] = useState(false);

  useEffect(() => {
    if (!job) {
      setCarrierCommitments([]);
    } else if (jobDetails?.carrierCommitments) {
      setCarrierCommitments(
        validateCommitmentTimes(jobDetails.carrierCommitments, job),
      );
    }
  }, [jobDetails, job, show]);

  useEffect(() => {
    disableSave(
      carrierCommitments.some(
        c => c.isDanger || !c.carrierId || !c.totalTrucks,
      ),
    );
    setBadTimeFormat(
      carrierCommitments.some(
        c => !(isDateFormatValid(c.startDate) && isDateFormatValid(c.endDate)),
      ),
    );
  }, [carrierCommitments]);

  const requiredTrucks = useMemo(() => {
    if (jobDetails) {
      return calculateRequiredTrucks(jobDetails);
    }
    return null;
  }, [jobDetails]);

  const handleUpdateCarrierCommitments = (index, name, value) => {
    const newArr = [...carrierCommitments];
    if (name === 'add') {
      newArr.push({
        carrierId: 1,
        totalTrucks: 0,
        startDate: new Date(job.start_date),
        endDate: new Date(job.end_date),
      });
    } else if (name === 'remove') {
      newArr.splice(index, 1);
    } else {
      newArr[index] = {
        ...carrierCommitments[index],
        [name]: value,
      };
    }
    setCarrierCommitments(validateCommitmentTimes(newArr, job));
  };

  const handleSubmit = async e => {
    e.preventDefault();
    try {
      const newCarrierCommitments = carrierCommitments.map(c => ({
        ...c,
        startDate: moment(c.startDate).toISOString(),
        endDate: moment(c.endDate).toISOString(),
      }));
      await upsertCarrierCommitments(newCarrierCommitments);

      swal('Success!', '', 'success');
      closeModal();
    } catch (error) {
      swal(
        'Error Updating Carrier Commitments',
        error?.data?.data?.message || '',
        'error',
      );
    }
  };

  const renderCommitmentInput = (commitment, index) => (
    <div className="row">
      <div className="form-group has-feedback col-md-3 no-padding">
        <Select
          onChange={e =>
            handleUpdateCarrierCommitments(index, 'carrierId', e.value)
          }
          value={carrierOptions.find(i => i.value === commitment.carrierId)}
          options={carrierOptions}
          label="Carrier"
          required
          testSelector="customer-details_locations_add-edit_name_input"
        />
      </div>
      <div
        className="form-group has-feedback col-md-2"
        style={{ paddingRight: 0 }}>
        <Input
          type="number"
          min={1}
          onChange={e =>
            handleUpdateCarrierCommitments(
              index,
              'totalTrucks',
              Number(targetValue(e)),
            )
          }
          value={commitment.totalTrucks}
          label="Trucks"
          required
          testSelector="customer-details_locations_add-edit_name_input"
        />
      </div>
      <div
        className="form-group has-feedback col-md-3"
        style={{ paddingRight: 0 }}>
        <DatePicker
          className={`${
            commitment.isDanger ? 'red-border' : ''
          } form-input__calendar`}
          dateFormat="MM/DD/YY"
          timeFormat="hh:mm A"
          value={commitment.startDate}
          onChange={e =>
            handleUpdateCarrierCommitments(index, 'startDate', targetValue(e))
          }
          label="Start Date"
          required
          testSelector="customer-details_locations_add-edit_name_input"
        />
      </div>
      <div
        className="form-group has-feedback col-md-3"
        style={{ paddingRight: 0 }}>
        <DatePicker
          className={`${
            commitment.isDanger ? 'red-border' : ''
          } form-input__calendar`}
          dateFormat="MM/DD/YY"
          timeFormat="hh:mm A"
          value={commitment.endDate}
          onChange={e =>
            handleUpdateCarrierCommitments(index, 'endDate', targetValue(e))
          }
          label="End Date"
          required
          testSelector="customer-details_locations_add-edit_name_input"
        />
      </div>
      <div
        className="form-group has-feedback col-md-1"
        style={{ marginTop: 10, paddingRight: 0 }}>
        <Button
          onClick={() => handleUpdateCarrierCommitments(index, 'remove')}
          theme="small"
          colour="red">
          <Icon icon="trash-o" />
        </Button>
      </div>
    </div>
  );

  if (!job) {
    return null;
  }

  return (
    <Modal style={{ top: '10%' }} show={show} onHide={closeModal}>
      <Modal.Header>
        <Modal.Title as="h4">
          Carrier Commitments for Job #{job.id} | {job.job_name}
          <br />
          <h6 className="text-subtitle">
            <strong>Duration: </strong>
            {moment(job.start_date).format('MM/DD/YY hh:mm A')} -{' '}
            {moment(job.end_date).format('MM/DD/YY hh:mm A')}
          </h6>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          {isLoading ? (
            <Loader />
          ) : (
            <>
              {isBadTimeFormat && (
                <div className="alert-input-msg">
                  Time format should be &quot;MM/DD/YY hh:mm AM/PM&quot;
                </div>
              )}
              {carrierCommitments.map(renderCommitmentInput)}
              <div className="row">
                <Button
                  className="float-end"
                  onClick={() => handleUpdateCarrierCommitments(0, 'add')}
                  theme="small"
                  testSelector="locations_edit-modal_edit-days_btn">
                  + Add Commitment
                </Button>
              </div>
              <div className="row">
                <div>
                  <h5>
                    Trucks Committed:{' '}
                    {carrierCommitments.reduce(
                      (total, commitment) =>
                        total + Number(commitment.totalTrucks),
                      0,
                    )}
                  </h5>
                </div>
                <div>
                  <h5>
                    Estimated Trucks Needed:{' '}
                    {requiredTrucks ?? (
                      <ToolTip
                        title="Requires at least one PO, target stages per day, and total weight for job to calculate."
                        className="marginLeft5">
                        N/A{' '}
                        <Icon
                          icon="info-circle"
                          className="text-navy"
                          style={{ cursor: 'pointer' }}
                        />
                      </ToolTip>
                    )}
                  </h5>
                </div>
              </div>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeModal}
            colour="white"
            testSelector="customer-details_locations_add-edit_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="customer-details_locations_add-edit_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
export default CarrierCommitmentModal;
