import { createSelector } from 'reselect';
import moment from 'moment';
import { convertTimeForTimeline } from 'utils/convertTime';

const order = state => state.orderList.modalOrderInfo.order;

export const orderSelector = createSelector(
  order,
  order => {
    order.accepted_minutes = moment(order.accepted_at).diff(
      moment(order.load_arrival),
      'minutes',
    );
    order.loading_minutes = moment(order.load_depart).diff(
      moment(order.load_arrival),
      'minutes',
    );
    order.loading_late = moment(order.load_arrival).diff(
      moment(order.expected_pickup_time),
      'minutes',
    );
    order.unloading_minutes = moment(order.well_depart).diff(
      moment(order.well_arrival),
      'minutes',
    );
    order.unloading_late = moment(order.well_arrival).diff(
      moment(order.unload_appt),
      'minutes',
    );

    return order;
  },
);

export const checkTime = time =>
convertTimeForTimeline(time) != 'Invalid date'
  ? convertTimeForTimeline(time)
  : 'unknown';

  export const checkIsNaN = value => (isNaN(value) ? `-` : `${value} m`);