import React from 'react';
import { connect } from 'react-redux';
import { orderSelector, checkTime, checkIsNaN } from './helpers';
import Icon from 'components/shared/Icon';
import Label from 'components/shared/Label';

const Line = (title, value) => {
  return (
    <span>
      <strong className="stats-label">{title} - </strong>
      <span className="stats-label">{checkTime(value)}</span>
    </span>
  );
};

const timeLateFaster = value => {
  return (
    <span>
      {isNaN(value) ? (
        <span>-</span>
      ) : value < 0 ? (
        <strong className="stats-label text-danger">
          {Math.abs(value)}m late
        </strong>
      ) : (
        <strong className="stats-label text-navy">{value}m faster</strong>
      )}
    </span>
  );
};

const Timeline = props => {
  const { order } = props;

  return (
    <>
      <div>
        <h3 className="col-md-7 no-padding">
          {Line('ACCEPTED', order.order_accepted_at)}
        </h3>
        <div className="col-md-5 text-right no-padding">
          <h3 className="text-navy">
            {checkIsNaN(order.accepted_minutes)}
          </h3>
          <Label title="Deadhead miles/Minutes">
            <Icon icon="info" />
          </Label>
        </div>
      </div>
      <div>
        <div className="col-md-6 no-padding">
          <h4 className="text-navy">LOADING: </h4>
        </div>
        <div className="col-md-6 text-right no-padding">
          <h4
            className={`${
              order.loading_minutes >= 0 && order.loading_minutes <= 120
                ? 'text-navy'
                : 'text-danger'
            }`}>
            {checkIsNaN(order.loading_minutes)}
          </h4>
          <Label title="Loading time">
            <Icon icon="info" />
          </Label>
        </div>

        <div className="col-md-12 no-padding">
          <div className="col-md-6 no-padding">
            {Line('Arrived', order.load_arrival)}
            <br />
            {Line('Departed', order.load_depart)}
          </div>
          <div className="col-md-6 no-padding">
            {Line('Scheduled', order.expected_pickup_time)}
            <br />
            {timeLateFaster(order.loading_late)}
          </div>
        </div>
      </div>
      <div>
        <div className="col-md-12 no-padding" style={{ marginTop: '22px' }}>
          <div className="col-md-6 no-padding">
            <h4 className="text-navy">UNLOADING: </h4>
          </div>
          <div className="col-md-6 text-right no-padding">
            <h4
              className={`${
                order.unloading_minutes >= 0 && order.unloading_minutes <= 120
                  ? 'text-navy'
                  : 'text-danger'
              }`}>
              {checkIsNaN(order.unloading_minutes)}
            </h4>
            <Label title="Unloading time">
              <Icon icon="info" />
            </Label>
          </div>
        </div>
        <div className="col-md-12 no-padding">
          <div className="col-md-6 no-padding">
            {Line('Arrived', order.well_arrival)}
            <br />
            {Line('Departed', order.well_depart)}
          </div>
          <div className="col-md-6 no padding">
            {Line('Scheduled', order.unload_appt)}
            <br />
            {timeLateFaster(order.unloading_late)}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = state => ({
  order: orderSelector(state),
});

export default connect(
  mapStateToProps,
  null,
)(Timeline);
