import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/OrdersList';
import { convertTime } from 'utils/convertTime';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';

const Comments = ({ init, logs, setComment, deleteComment, postComment }) => {
  const { comments, comment, isLoaded } = logs;
  const scrollTop = () => {
    setTimeout(() => {
      const block = document.querySelector('.content-comments-order');
      if(block){
        block.scrollTop = block.scrollHeight;
      }
    }, 0);
  };
  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    scrollTop();
  }, [comments]);

  return (
    <div>
      <h3>Comments for order</h3>
      {isLoaded ? (
        <div
          className="content-comments-order no-padding"
          style={{ minHeight: '300px' }}>
          <ul className="list-group">
            {comments.map(item => (
              <li key={item.comment_id} className="list-group-item">
                <h5 className="text-navy">@{item.character_type}</h5>
                <span className="float-end">
                  <Icon
                    icon="trash del-comment-order"
                    onClick={() => deleteComment(item.comment_id)}
                  />
                </span>
                <p>{item.comment}</p>
                <small className="text-muted">
                  {convertTime(item.created_at)}
                </small>
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <div>There are no comments on this order yet.</div>
      )}
      <div className="order-comments-footer">
        <div className="form-group">
          <form role="form" onSubmit={postComment}>
            <div className="col-xs-12 no-padding">
              <Input
                onChange={setComment}
                value={comment}
                label="Write your comment here"
                required
                testSelector="orders_info_comments_text_input"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  logs: state.orderList.modalOrderInfo.logs,
});

const mapDispatchToProps = dispatch => ({
  setComment: e => dispatch(actions.setComment(targetValue(e))),
  postComment: e => {
    e.preventDefault();
    dispatch(actions.postComment());
  },
  deleteComment: (commentId, orderId) =>
    dispatch(actions.deleteComment(commentId, orderId)),
  init: () => dispatch(actions.initComments()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Comments);
