import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Col, Modal, Row } from 'react-bootstrap';
import actions from 'store/actions/OrderActions';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';

import { useJobPurchaseOrders } from 'api/v2/purchase-orders';

import { filterOptions } from 'utils/selectUtils';
import targetValue from 'utils/targetValue';
import { isDateFormatValid } from 'utils/datePickersFunctions';
import moment from 'moment';
import DatePicker from 'components/shared/DatePicker';

const Requeue = props => {
  const {
    order,
    init,
    clearState,
    openModal,
    turnModal,
    requeue,
    setInputValue,
    submit,
    setTitle,
  } = props;
  useEffect(() => {
    init(order);
    return () => clearState();
  }, []);
  const { data: pos, isLoading } = useJobPurchaseOrders(order.job_id);

  const [isDateAlert, turnDateAlert] = useState(false);
  const { input, isLoaded } = requeue;
  const { po, unloadAppointment, expectedPickupTime } = input;

  const posList = useMemo(() => {
    const formattedPosList = pos?.map(item => {
      item.label =
        order.operation_type !== 12
          ? `${item.poNo} | ${item.sandTypeName} | ${item.originName}`
          : item.originName;
      item.disabled = !item.enabled;
      return item;
    }) ?? [];

    return filterOptions(formattedPosList, null, null, 'poNo', 'label')
  }, [pos, order.operation_type]);

  return (
    <Modal className="modal-container" show={openModal} onHide={turnModal}>
      <Modal.Header>
        <h3 className="modal-title">{setTitle(order.status)} order</h3>
      </Modal.Header>
      <form onSubmit={submit}>
        <Modal.Body>
          {!isLoaded && !isLoading ? (
            <Row>
              <Col md={6} className="form-group has-feedback">
                <Select
                  label="PO / Commodity / Location"
                  onChange={item => {
                    setInputValue(item.value, 'po');
                    setInputValue(null, 'expectedPickupTime');
                  }}
                  options={posList}
                  isOptionDisabled={option => option.disabled}
                  value={posList.find(item => item.value === po)}
                  required
                  testSelector="order-actions_requeue_po_select"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <DatePicker
                  label="Unload Appointment"
                  value={unloadAppointment}
                  onChange={e => {
                    isDateFormatValid(e, turnDateAlert);
                    setInputValue(e, 'unloadAppointment');
                    setInputValue(null, 'expectedPickupTime');
                  }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="hh:mm A"
                  isValidDate={current =>
                    current.isSameOrAfter(
                      moment(order.created_at)
                        .utc()
                        .format('YYYY-MM-DD'),
                    )
                  }
                  required
                  testSelector="order-actions_requeue_unload-appt_dp"
                />
              </Col>
              <Col md={6} className="form-group has-feedback">
                <DatePicker
                  label="Expected Pickup Time"
                  value={expectedPickupTime}
                  onChange={e => {
                    isDateFormatValid(e, turnDateAlert);
                    setInputValue(e, 'expectedPickupTime');
                  }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat="hh:mm A"
                  isValidDate={current =>
                    current.isSameOrAfter(
                      moment(order.created_at)
                        .utc()
                        .format('YYYY-MM-DD'),
                    )
                  }
                  required
                  testSelector="order-actions_requeue_expected-pickup_dp"
                />
              </Col>
              {isDateAlert && (
                <div
                  className="nested-no-outer-gutter"
                  style={{ color: 'red' }}>
                  <p>* Incorrect date format</p>
                </div>
              )}
            </Row>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            colour="white"
            onClick={turnModal}
            testSelector="order-actions_requeue_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={isLoaded || isDateAlert}
            testSelector="order-actions_requeue_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  requeue: state.orderActions.requeue,
  order: state.orderActions.order,
});

const mapDispatchToProps = dispatch => ({
  init: order => dispatch(actions.initRequeue(order)),
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueRequeue(targetValue(e), name)),
  submit: e => {
    e.preventDefault();
    dispatch(actions.submitRequeue());
  },
  clearState: () => dispatch(actions.clearStateRequeue()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Requeue);
