import React, { useEffect, useState } from 'react';
import { Modal, Image, Row } from 'react-bootstrap';
import ReactCrop from 'react-image-crop';
import { connect } from 'react-redux';

import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

import authService from 'services/auth/service';
import actions from 'store/actions/OrderActions';
import multiSelectOptions from 'utils/multiSelectOptions';
import targetValue from 'utils/targetValue';

const UploadST = ({
  order,
  initAction,
  clearState,
  openModal,
  turnModal,
  selectFile,
  uploadST,
  cropComplete,
  onCropChange,
  setInputValue,
  saveST,
  selectData,
}) => {
  useEffect(() => {
    initAction(order);
    return () => clearState();
  }, []);
  const user = authService.getUser();

  const [isCropping, turnCropping] = useState(false);
  const { input, init, isLoaded } = uploadST;
  const { containers, sandTicketsTypes, containersPerTruck } = init;
  const {
    sandTicketNo,
    weight,
    sandTicketType,
    containerIds,
    url,
    crop,
    compartment,
    king,
  } = input;

  const ticketTypes = sandTicketsTypes.map(ticketType => ({
    value: ticketType.sandTicketTypeId,
    label: ticketType.name,
    disabled: Boolean(ticketType.isRequired),
  }));

  const isDisable = () => {
    if (
      !sandTicketNo.trim().length ||
      !sandTicketType ||
      (isCropping && !crop.width) ||
      weight > 85000 ||
      weight < 100
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal className="modal-container" show={openModal} onHide={turnModal}>
      <Modal.Header>
        <Modal.Title as="h4">Upload Ticket</Modal.Title>
      </Modal.Header>
      <form onSubmit={e => saveST(e, order)}>
        <Modal.Body>
          {!isLoaded ? (
            <Row>
              {(weight > 85000 || weight < 100) && (
                <div className="alert-input-msg">
                  Quantity should be more than 1,000 and less than 65,000
                </div>
              )}
              <div className="form-group has-feedback col-md-6">
                <label className="small font-noraml">Ticket № </label>
                <Input
                  onChange={e => setInputValue(e, 'sandTicketNo')}
                  value={sandTicketNo}
                  label="Ticket number"
                  required
                  testSelector="order-actions_upload-st_ticket-number_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <label className="small font-noraml">Quantity</label>
                <Input
                  type="number"
                  onChange={e => setInputValue(e, 'weight')}
                  value={weight}
                  label="Quantity"
                  min="0"
                  // max={order.weight}
                  max={85000}
                  required
                  testSelector="order-actions_upload-st_quantity_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <label className="small font-noraml">File</label>
                <Input
                  type="file"
                  label="Ticket (jpg/png)"
                  onChange={selectFile}
                  required
                  accept="image/*"
                  testSelector="order-actions_upload-st_ticket_file-input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <label className="small font-noraml">Ticket Type</label>
                <Select
                  placeholder="Please select Ticket Type"
                  value={ticketTypes.find(ticketType => {
                    ticketType.value === sandTicketType;
                  })}
                  onChange={item => selectData(item.value, 'sandTicketType')}
                  options={ticketTypes}
                  required
                  testSelector="order-actions_upload-st_sand-ticket-type_select"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                {order.equipment == 3 && (
                  <span>
                    <Select
                      isMulti
                      closeMenuOnSelect={false}
                      onChange={selected =>
                        selectData(selected, 'containerIds')
                      }
                      options={multiSelectOptions(
                        containers,
                        'id',
                        'containerNumber',
                      )}
                      placeholder="Select containers"
                      value={containerIds}
                      testSelector="order-actions_upload-st_container_select"
                    />
                    {containersPerTruck != containerIds.length && (
                      <span>
                        Need to select {containersPerTruck}{' '}
                        {containersPerTruck == 1 ? 'container' : 'containers'}
                      </span>
                    )}
                  </span>
                )}
              </div>
              {url && (
                <div className="form-group has-feedback col-md-12">
                  {isCropping ? (
                    [
                      <Button
                        onClick={() => {
                          turnCropping(false);
                          setInputValue(undefined, 'pixelCrop');
                          setInputValue(
                            {
                              x: 10,
                              y: 10,
                            },
                            'crop',
                          );
                        }}
                        colour="white"
                        children="Use full size"
                        testSelector="order-actions_upload-st_full-suze_btn"
                      />,
                      !crop.width && (
                        <p>
                          <small className="text-danger">
                            * please select a crop region before saving Ticket
                          </small>
                        </p>
                      ),
                    ]
                  ) : (
                    <Button
                      onClick={() => turnCropping(true)}
                      colour="white"
                      children="Crop a region"
                      testSelector="order-actions_upload-st_crop_btn"
                    />
                  )}
                  <div
                    className="form-group has-feedback col-md-12"
                    style={{ display: 'block', textAlign: 'center' }}>
                    {isCropping ? (
                      <ReactCrop
                        src={url}
                        crop={crop}
                        onComplete={cropComplete}
                        onChange={onCropChange}
                      />
                    ) : (
                      <Image src={url} width="100%" alt="preview" />
                    )}
                  </div>
                </div>
              )}
            </Row>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={turnModal}
            colour="white"
            children="Close"
            testSelector="order-actions_upload-st_close_btn"
          />
          <Button
            type="submit"
            children="Save"
            disabled={isLoaded || isDisable()}
            testSelector="order-actions_upload-st_save_btn"
          />
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  uploadST: state.orderActions.uploadST,
  order: state.orderActions.order,
});

const mapDispatchToProps = dispatch => ({
  selectFile: e => dispatch(actions.selectFileST(e)),
  cropComplete: (e, pixelCrop) =>
    dispatch(actions.setInputValueST(pixelCrop, 'pixelCrop')),
  selectData: (selected, name) =>
    dispatch(actions.setInputValueST(selected, name)),
  onCropChange: e => dispatch(actions.setInputValueST(e, 'crop')),
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueST(targetValue(e), name)),
  initAction: id => dispatch(actions.initST(id)),
  saveST: (e, order) => {
    e.preventDefault();
    dispatch(actions.saveST(order));
  },
  clearState: () => dispatch(actions.clearStateST()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(UploadST);
