import moment from 'moment';
import swal from 'bootstrap-sweetalert';
import { jobs } from 'services/jobs/service';
import authService from 'services/auth/service';
import { users } from 'services/users/service';
import { resourceActions } from 'store/modules/resourceReducer/actions';

const setStatus = value => ({ type: 'JOB_LIST_SET_STATUS', value });
const setIsDraftJob = value => ({ type: 'JOB_LIST_SET_IS_DRAFT_JOB', value });

const setValue = (value, name) => ({
  type: 'JOB_LIST_SET_FILTER_VALUE',
  value,
  name,
});
const setAddJobValue = (value, name) => ({
  type: 'JOB_LIST_SET_ADD_JOB_VALUE',
  value,
  name,
});
const clearAddJobValue = () => ({
  type: 'JOB_LIST_CLEAR_ADD_JOB_VALUE',
});
const clearEditJobValue = () => ({
  type: 'JOB_LIST_CLEAR_EDIT_JOB_VALUE',
});

const deleteJob = id => dispatch => {
  jobs
    .delete(id)
    .then(msg => {
      dispatch(resourceActions.getJobs());
      swal(msg.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const completeJob = id => dispatch => {
  swal(
    {
      title: '',
      text: 'Are you sure you want complete this job?',
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: "Yes, I'm sure!",
      cancelButtonText: "No, I'm not",
      closeOnConfirm: false,
      closeOnCancel: true,
      showLoaderOnConfirm: true,
    },
    isConfirm => {
      if (isConfirm) {
        jobs
          .complete(id)
          .then(msg => {
            dispatch(resourceActions.getJobs());
            swal(msg.data.message, '', 'success');
          })
          .catch(error => {
            swal(error.response.data.message, '', 'error');
          });
      }
    },
  );
};

const uncompleteJob = id => dispatch => {
  swal(
    {
      title: '',
      text: 'Are you sure you want uncomplete this job?',
      type: 'info',
      showCancelButton: true,
      confirmButtonColor: '#DD6B55',
      confirmButtonText: "Yes, I'm sure!",
      cancelButtonText: "No, I'm not",
      closeOnConfirm: false,
      closeOnCancel: true,
      showLoaderOnConfirm: true,
    },
    isConfirm => {
      if (isConfirm) {
        jobs
          .uncomplete(id)
          .then(msg => {
            dispatch(resourceActions.getJobs());
            swal(msg.data.message, '', 'success');
          })
          .catch(error => {
            swal(error.response.data.message, '', 'error');
          });
      }
    },
  );
};

const initEditJob = id => ({
  type: 'MODAL_EDIT_JOB_SAVE_INIT_JOB',
  data: { id },
});

const displayJobApi = jobApi => dispatch => {
  const message = jobApi || 'This Job has no token';
  swal('Api Token', message, 'info');
};

const clearCertificateModal = () => ({
  type: 'JOB_LIST_CERTIFICATES_MODAL_CLEAR',
});

const initCertificateModal = params => dispatch => {
  const { id, isFullEdit } = params;
  const success = (array, jobId, isFullEdit) => ({
    type: 'JOB_LIST_INIT_CERTIFICATES_MODAL',
    array,
    jobId,
    isFullEdit,
  });
  const data = {
    id,
  };
  jobs.certificate
    .get(data)
    .then(msg => {
      dispatch(success(msg.data.data, id, isFullEdit));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const addCertificate = id => (dispatch, getState) => {
  const success = () => ({ type: 'JOB_LIST_CERTIFICATES_ADD_TO_JOB' });
  const state = getState();
  const {
    jobsList: {
      certificatesModal: { jobId, isFullEdit },
    },
  } = state;
  const data = {
    certId: id,
    jobId,
  };
  jobs.certificate
    .add(data)
    .then(msg => {
      dispatch(success());
      swal('Success!', '', 'success');
      dispatch(initCertificateModal({ id: jobId, isFullEdit }));
    })
    .catch(error => swal(error.message, '', 'error'));
};

const deteteCertificate = id => (dispatch, getState) => {
  const success = () => ({
    type: 'JOB_LIST_CERTIFICATES_DELETE_TO_JOB',
  });

  const state = getState();
  const {
    jobsList: {
      certificatesModal: { jobId, isFullEdit },
    },
  } = state;
  const data = {
    certId: id,
    jobId,
  };
  jobs.certificate
    .delete(data)
    .then(msg => {
      dispatch(success());
      dispatch(initCertificateModal({ id: jobId, isFullEdit }));
      swal('Success!', '', 'success');
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

// Edit job
const saveInitDataEdit = (data, name) => ({
  type: 'MODAL_EDIT_JOB_SAVE_INIT_DATA',
  data,
  name,
});
const setEditJobValue = (value, name) => ({
  type: 'MODAL_EDIT_JOB_SET_VALUE',
  value,
  name,
});

const setArrayValue = (value, index, name, arrayName) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const array = [...state.jobsList.jobs.editJob[arrayName]];

  if (!array[index]) {
    array[index] = {};
  }

  if (name === 'storage_id') {
    array[index][name] = value.trim();
  } else {
    array[index][name] = value;
  }

  dispatch(setEditJobValue(array, arrayName));
};

const addArrayValue = (name, content) => (dispatch, getState) => {
  const state = getState();
  const array = [...state.jobsList.jobs.editJob[name], content || {}];
  dispatch(setEditJobValue(array, name));
};

const deleteArrayValue = (index, name) => (dispatch, getState) => {
  const state = getState();
  const array = [...state.jobsList.jobs.editJob[name]];
  array.splice(index, 1);
  dispatch(setEditJobValue(array, name));
};

const setOnsiteStorageType = (selectedTypeId, index, allTypes) => dispatch => {
  allTypes.forEach(type => {
    if (type.id === selectedTypeId) {
      dispatch(setArrayValue(type.id, index, 'type', 'onsiteStorageConfig'));
      dispatch(
        setArrayValue(
          type.live_storage,
          index,
          'live_storage',
          'onsiteStorageConfig',
        ),
      );
      dispatch(
        setArrayValue(
          type.id_required,
          index,
          'id_required',
          'onsiteStorageConfig',
        ),
      );
    }
  });
};

const initEditModal = () => async (dispatch, getState) => {
  try {
    const initJob = data => ({
      type: 'MODAL_EDIT_JOB_SAVE_INIT_JOB',
      data,
    });
    const state = getState();
    const { id } = state.jobsList.jobs.editJob;

    // const sandTicketTypes = await sandTicket.types.get();
    // dispatch(
    //   saveInitDataEdit(
    //     sandTicketTypes.data.data.sandTicketTypes,
    //     'sandTicketTypes',
    //   ),
    // );

    // const districts = await district.get();
    // dispatch(saveInitDataEdit(districts.data.data.district, 'districts'));

    const certificates = await jobs.certificates({ id: Number(id) });
    dispatch(
      setEditJobValue(
        certificates.data.data.certOn.map(item => ({
          value: item.id,
          label: item.name,
        })),
        'certificates',
      ),
    );
    // dispatch(
    //   saveInitDataEdit(
    //     [...certificates.data.data.certOn, ...certificates.data.data.certOff],
    //     'allCertificates',
    //   ),
    // );

    // sandType
    //   .get()
    //   .then(msg => {
    //     dispatch(saveInitDataEdit(msg.data.data.sandTypes, 'sandTypes'));
    //   })
    //   .catch(error => {
    //     if (error.data.message) {
    //       swal(error.response.data.message, '', 'error');
    //     } else {
    //       swal('Something went wrong, please try again', '', 'error');
    //     }
    //   });


    const details = await jobs.details(id);
    const job = details.data.data.details;
    const { storage, tags } = details.data.data;
    const info = {
      name: job.jobName,
      startDate: moment(job.startDate).local(),
      endDate: moment(job.endDate).local(),
      isTestJob: job.isTestJob,
      useCommodityPrice: Boolean(job.useCommodityPrice),
      numberOfStages: job.potentialTS1,
      directions: job.directions,
      pumpTime: job.pumpTime,
      wirelineTime: job.wirelineTime,
      assignClosesOnly: job.assignClosesOnly,
      sendCompletionCodeToDriver: Boolean(job.sendCompletionCodeToDriver),
      sandTicketDesign: [],
      districtIds: [],
      loaded: true,
      operationType: job.operationType,
      alertsEnabled: Boolean(job.alertsEnabled),
      equipment: job.equipments,
      stagingSite: job.stagingSite,
      totalWeightPerJob: job.totalWeightPerJob,
      targetStagesPerDay: job.targetStagesPerDay,
      customerJobId: job.customerJobId,
      navId: job.nav_id,
      customerId: job.customerId,
      wellSiteId: job.location,
      minDriversAmount: job.minDriversAmount,
      demurrageAlert: job.demurrageAlert,
      messagesAllowed: job.messagesAllowed,
      billingProfileId: job.billingProfileId,
      operators: details.data.data.operators,
      customerProjectId: job.customerProjectId,
      tags: (tags || []).map(t => t.id)
    };

    // dispatch(saveInitDataEdit(job.isDraft, 'isADraftJob'));


    if (storage && storage.find(s => s.storage_type === '1' && s.storage_id)) {
      info.storageIds = storage
        .filter(s => s.storage_type === '1' && s.storage_id)
        .map(s => ({
          storage_id: s.storage_id,
          id: s.id,
        }));
    }

    details.data.data.sandTicketDesign.forEach(item => {
      if (item.isRequired === 1) {
        info.sandTicketDesign.push({
          value: item.sandTicketTypeId,
          label: item.name,
        });
      }
    });
    details.data.data.districts.forEach(item => {
      info.districtIds.push({
        value: item.id,
        label: item.name,
      });
    });

    dispatch(setEditJobValue(details.data.data.jobDesign, 'jobDesign'));

    const phoneNumbers = details.data.data.phoneNumbers.map(item => ({
      number: item
    }));

    dispatch(setEditJobValue(phoneNumbers, 'phoneNumbers'));

    // const { customerId } = info;
    // let payments = '';
    // let table = '';

    const onsiteStorageTypes = (await jobs.onsiteStorageTypes.get()).data.data;
    // dispatch(saveInitDataEdit(onsiteStorageTypes, 'onsiteStorageTypes'));

    const onsiteStorageConfig = details.data.data.storage.map(item => {
      const storageItem = { ...item };
      storageItem.type = Number(item.storage_type);
      storageItem.count = Number(item.amount);

      const type = onsiteStorageTypes.find(storageType => storageType.id === storageItem.type);
      storageItem.live_storage = type.live_storage;
      storageItem.id_required = type.id_required;

      return storageItem;
    });
    dispatch(setEditJobValue(onsiteStorageConfig, 'onsiteStorageConfig'));

    // await wellSite
    //   .getWellsitesForCustomer(customerId)
    //   .then(msg => {
    //     dispatch(saveInitDataEdit(msg.data.data, 'wellSites'));
    //   })
    //   .catch(error => swal(error.response.data.message, '', 'error'));
    // await customer.billing.payments
    //   .get(customerId)
    //   .then(msg => {
    //     payments = msg.data.data;
    //   })
    //   .catch(error => swal(error.response.data.message, '', 'error'));
    // await customer.rateTable
    //   .get(customerId)
    //   .then(msg => {
    //     table = msg.data.data;
    //   })
    //   .catch(error => swal(error.response.data.message, '', 'error'));
    // customer.demurrage
    //   .get(customerId)
    //   .then(msg => {
    //     dispatch(saveInitDataEdit(msg.data.data, 'demmurage'));
    //   })
    //   .catch(error => swal(error.response.data.message, '', 'error'));
    // customer.billing.profiles
    //   .get(customerId)
    //   .then(msg => {
    //     dispatch(saveInitDataEdit(msg.data.data, 'billingProfiles'));
    //   })
    //   .catch(error => swal(error.response.data.message, '', 'error'));
    jobs.operators
      .getShared(id)
      .then(msg => {
        dispatch(setEditJobValue(msg.data.data.operators.map(op => ({
          value: `${op.id}/${op.entityType}`,
          label: op.name,
        })), 'additionalOperators'));
      })
      .catch(error => swal(error?.response?.data?.message || 'Something went wrong.', '', 'error'));

    // carrier
    //   .getCarriers()
    //   .then(carrierResponse => {
    //     let companies = carrierResponse.data.data.carriers.map(c => ({
    //       id: `${c.id}/3`,
    //       name: c.name,
    //     }));
    //     customer
    //       .get()
    //       .then(customerResponse => {
    //         const customers = customerResponse.data.data.customers.map(c => ({
    //           id: `${c.id}/2`,
    //           name: c.name,
    //         }));

    //         dispatch(
    //           saveInitDataEdit(customerResponse.data.data.customers, 'customers'),
    //         );

    //         companies = sortBy(companies.concat(customers), ['name']);
    //         dispatch(saveInitDataEdit(companies, 'companyArray'));
    //       })
    //       .catch(error => {
    //         swal(
    //           error.response.data.error || 'Something went wrong.',
    //           '',
    //           'error',
    //         );
    //       });
    //   })


    // dispatch(saveInitDataEdit(table, 'rateTable'));
    dispatch(initJob(info));
  } catch (err) {
    return swal(err?.response?.data?.message || 'Something went wrong.', '', 'error');
  }
};

const editJob = close => async (dispatch, getState) => {
  try {
    const state = getState();
    const data = { ...state.jobsList.jobs.editJob };
    data.isDraft = Number(data.isADraftSave ?? 0);
    data.useCommodityPrice = Number(data.useCommodityPrice);
    data.assignClosesOnly = Number(data.assignClosesOnly);
    data.sendCompletionCodeToDriver = Number(data.sendCompletionCodeToDriver);
    data.alertsEnabled = Number(data.alertsEnabled);
    data.sandTicketDesign = data.sandTicketDesign.map(item => ({
      sandTicketTypeId: item.value,
    }));
    data.certificates = data.certificates.map(item => item.value);
    data.districtIds = Array.from(data.districtIds.map(item => item.value));
    data.equipment = data.equipment.map(e => Number(e));
    data.totalWeightPerJob = Number(data.totalWeightPerJob);
    data.targetStagesPerDay = Number(data.targetStagesPerDay);

    if (!data.totalWeightPerJob) {
      delete data.totalWeightPerJob;
    }
    if (!data.targetStagesPerDay) {
      delete data.targetStagesPerDay;
    }
    if (!data.stagingSite) {
      delete data.stagingSite;
    }
    if (!data.targetStagesPerDay) {
      delete data.targetStagesPerDay;
    }
    if (!data.totalWeightPerJob) {
      delete data.totalWeightPerJob;
    }

    data.phoneNumbers = data.messagesAllowed
      ? data.phoneNumbers.map(item => `${item.code}${item.number}`)
      : undefined;
    data.messagesAllowed = Number(data.messagesAllowed);
    data.onsiteStorageConfig = data.onsiteStorageConfig.map(item => ({
      ...item,
      storage_id: item.storage_id?.length ? String(item.storage_id ?? '') : undefined,
      volume: Number(item.volume ? item.volume : 1),
      amount: Number(item.amount ? item.amount : 1)
    }));

    data.jobDesign = data.jobDesign.map(item => ({
      ...item,
      minVolume: Number(item.minVolume),
      loadWeight: Number(item.loadWeight),
      volume: Number(item.volume)
    }));
    const operatorList = data.additionalOperators;
    const editAdditionalOperators = authService.getUser().role === 6 || Number(authService.getCompany()) === Number(data.customerId);

    if (editAdditionalOperators) {
      const filteredCompanies = operatorList.filter(ol => {
        const entityArray = ol.value.split('/');

        if (Number(entityArray[1]) === 2) {
          return Number(entityArray[0]) !== Number(data.customerId);
        }

        return true;
      });
      const params = { jobId: data.id, customerId: data.customerId };
      const payload = filteredCompanies.map(item => {
        const entityArray = item.value.split('/');
        return {
          entityType: Number(entityArray[1]),
          entityId: Number(entityArray[0]),
        };
      });

      await jobs.operators.update(params, payload);
    }

    jobs
      .update(data)
      .then(() => {
        close();
        swal('Success!', '', 'success');
        dispatch(resourceActions.getJobs());
      })
      .catch(error => swal(error.response.data.message, '', 'error'));
  } catch (error) {
    swal(error.response.data.message, '', 'error');
  }
};

const getUsers = () => dispatch => {
  const success = users => ({ type: 'JOB_LIST_GET_USERS', users });
  users
    .get()
    .then(response => {
      dispatch(success(response.data.data));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const clearQuickBooksModal = () => ({
  type: 'JOB_LIST_QUICKBOOKS_MODAL_CLEAR',
});

const setQuickBooksValue = (value, name) => ({
  type: 'MODAL_QUICKBOOKS_SET_VALUE',
  value,
  name,
});

const initQuickBooksModal = data => dispatch => {
  dispatch({
    type: 'JOB_LIST_INIT_QUICKBOOKS_MODAL',
    qbProjectId: data.qbProjectId,
    qbCustomerId: data.qbCustomerId,
    qbDeepLinkUrl: data.qbDeepLinkUrl,
    name: data.job_name,
    id: data.id,
  });
};

const saveQuickBooks = (jobId, link) => dispatch => {
  dispatch(setQuickBooksValue(true, 'isSyncing'));
  jobs
    .linkQbId({ jobId, link })
    .then(response => {
      dispatch(
        setQuickBooksValue(response.data.data.qbProjectId || '', 'qbProjectId'),
      );
      dispatch(setQuickBooksValue(false, 'isSyncing'));
    })
    .catch(error => {
      dispatch(setQuickBooksValue(false, 'isSyncing'));
      swal(error.response.data.message, '', 'error');
    });
};

const setIsDraftSave = () => dispatch => {
  dispatch(setEditJobValue(true, 'isADraftSave'));
}

export default {
  setStatus,
  setIsDraftJob,
  setValue,
  setAddJobValue,
  clearAddJobValue,
  deleteJob,
  completeJob,
  uncompleteJob,
  clearEditJobValue,
  initEditJob,
  displayJobApi,
  setArrayValue,
  addArrayValue,
  deleteArrayValue,
  setOnsiteStorageType,
  setIsDraftSave,

  clearCertificateModal,
  initCertificateModal,
  addCertificate,
  deteteCertificate,

  initEditModal,
  editJob,
  setEditJobValue,

  clearQuickBooksModal,
  setQuickBooksValue,
  saveQuickBooks,
  initQuickBooksModal,

  getUsers,
};
