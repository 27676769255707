import { createSelector } from 'reselect';
import { dynamicSort } from 'utils/dynamicSort';

const getJobs = state => state.resourceReducer.jobs.jobs;

export const filterJobs = createSelector(
  getJobs,
  jobs => filters => {
    const escapedName = filters.name.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
    const regexId = new RegExp(filters.id || '', 'i');
    const regexName = new RegExp(escapedName || '', 'i');
    const regexStatus =
      filters.status === 0 ? new RegExp('0', 'i') : new RegExp('1', 'i');
    const regexJobType = new RegExp(
      filters.jobType > 0 ? `\\b${filters.jobType}\\b` : '',
      'i',
    );
    const regexIsDraft = filters.isDraftJob === 0 ? new RegExp('0', 'i') : new RegExp('1', 'i');

    return jobs
      .filter(
        item =>
          item.id.toString().search(regexId) !== -1 &&
          item.operation_type.toString().search(regexJobType) !== -1 &&
          item.job_name.toString().search(regexName) !== -1 &&
          item.status.toString().search(regexStatus) !== -1 &&
          item.isDraft.toString().search(regexIsDraft) !== -1
      )
      .sort(dynamicSort(filters.sort));
  },
);
