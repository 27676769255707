import swal from 'bootstrap-sweetalert';
import { users } from 'services/users/service';
import { customer } from 'services/customer/service';
import { carrier } from 'services/carrier/service';
import { jobs } from 'services/jobs/service';
import { aws } from 'services/aws/service';
import { document } from 'services/document/service';
import authService from 'services/auth/service';

const getUsers = () => dispatch => {
  const success = users => ({ type: 'USERS_GET_USERS', users });
  users
    .get()
    .then(response => {
      const token = authService.getToken();
      const formattedData = response.data.data.map(user => {
        user.profile.profileUrl = user.profile.profileUrl
          ? `${user.profile.profileUrl}?token=${token}`
          : 'https://www.gravatar.com/avatar/294fcfe0ab64d8c7ecce8ec3214af997';
        return user;
      });
      dispatch(success(formattedData));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const setFilterValue = (value, name) => ({
  type: 'USERS_SET_FILTER_VALUE',
  value,
  name,
});
const unSelectUser = () => ({ type: 'USERS_UNSELECT_USER' });
const clear = () => ({ type: 'USERS_CLEAR' });
const selectAction = action => ({
  type: 'USERS_SELECT_ACTION',
  action,
});

const getUserRoles = userId => dispatch => {
  const success = roles => ({ type: 'USERS_SET_USER_ROLES', roles });
  users.role
    .get(userId)
    .then(response => {
      dispatch(success(response.data.data));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const selectUser = user => dispatch => {
  dispatch({ type: 'USERS_SELECT_USER', user });
  dispatch(getUserRoles(user.id));
};

const getUsersAfterEdit = id => dispatch => {
  const success = users => ({ type: 'USERS_GET_USERS', users });
  users
    .get()
    .then(response => {
      const token = authService.getToken();
      const formattedData = response.data.data.map(user => {
        user.profile.profileUrl = user.profile.profileUrl
          ? `${user.profile.profileUrl}?token=${token}`
          : 'https://www.gravatar.com/avatar/294fcfe0ab64d8c7ecce8ec3214af997';
        return user;
      });
      dispatch(success(formattedData));
      const tmpUser = formattedData.find(u => u.id === id);
      dispatch(selectUser(tmpUser));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const initUser = () => (dispatch, getState) => {
  const {
    users: {
      info: { selectedUser },
    },
  } = getState();

  dispatch({ type: 'USERS_ADD_USER_INIT_EDIT', selectedUser });
};

const setInputValue = (value, name) => ({
  type: 'USERS_ADD_USER_SET_INPUT_VALUE',
  value,
  name,
});
const setInputEmail = value => ({
  type: 'USERS_ADD_USER_SET_INPUT_EMAIL',
  value,
});
const clearAddUser = () => ({ type: 'USERS_ADD_USER_CLEAR' });

const submitAddUser = (openModalRole, closeModal) => (dispatch, getState) => {
  const currentState = getState();
  const { action } = currentState.users.info;
  const addUser = {
    email: currentState.users.addUser.email,
    permissionId: currentState.users.addUser.permissionId,

    description: currentState.users.addUser.profile.description,
    file: currentState.users.addUser.profile.file,
    firstName: currentState.users.addUser.profile.firstName,
    lastName: currentState.users.addUser.profile.lastName,
    phoneNumber: currentState.users.addUser.profile.phoneNumber,
    profileUrl: currentState.users.addUser.profile.profileUrl,
    timeZone: currentState.users.addUser.profile.timeZone,
  };
  if (action == 'edit') {
    addUser.id = currentState.users.addUser.id;
  }

  const submit = {
    add: users.add,
    edit: users.edit,
  };

  const token = authService.getToken();

  if (addUser.file) {
    document.getSignedUrl().then(response => {
      const url = response.data.data.uploadUrl;
      const documentId = response.data.data.id;

      aws.uploadToS3(addUser.file, url).then(s3Response => {
        document.finishUpload(documentId).then(docResponse => {
          const avatar = `${window._env_.API_BASE_URL
            }v2/documents/${documentId}`;
          addUser.avatar = avatar;
          delete addUser.file;

          submit[action](addUser)
            .then(res => {
              if (action == 'edit') {
                dispatch(getUsersAfterEdit(addUser.id));
                swal(res.data.message, '', 'success');
              }
              if (action == 'add') {
                const addUser = users => ({
                  type: 'USERS_ADD_USER_SUCCESS',
                  users,
                });
                const state = getState();
                const users = [...state.users.info.users];
                const user = res.data.data;
                const userFormatted = {
                  email: user.email,
                  id: user.id,
                  createdAt: user.createdAt,
                  lastLoggedIn: user.lastLoggedIn,
                  roles: user.roles || [],
                  profile: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    phoneNumber: user.phoneNumber,
                    timeZone: user.timeZone,
                    description: user.description,
                    profileUrl: user.profileUrl
                      ? `${user.profileUrl}?token=${token}`
                      : 'https://www.gravatar.com/avatar/294fcfe0ab64d8c7ecce8ec3214af997',
                  },
                };
                users.push(userFormatted);
                dispatch(addUser(users));
                dispatch(selectUser(userFormatted));
                openModalRole();
              }
              closeModal();
            })
            .catch(error => {
              swal(error.response.data.message, '', 'error');
            });
        });
      });
    });
  } else {
    submit[action](addUser)
      .then(res => {
        if (action == 'edit') {
          dispatch(getUsersAfterEdit(addUser.id));
          swal(res.data.message, '', 'success');
        }
        if (action == 'add') {
          const addUser = users => ({
            type: 'USERS_ADD_USER_SUCCESS',
            users,
          });
          const state = getState();
          const users = [...state.users.info.users];
          const user = res.data.data;
          const userFormatted = {
            email: user.email,
            id: user.id,
            createdAt: user.createdAt,
            lastLoggedIn: user.lastLoggedIn,
            roles: user.roles || [],
            profile: {
              firstName: user.firstName,
              lastName: user.lastName,
              phoneNumber: user.phoneNumber,
              timeZone: user.timeZone,
              description: user.description,
              profileUrl: user.profileUrl
                ? `${user.profileUrl}?token=${token}`
                : 'https://www.gravatar.com/avatar/294fcfe0ab64d8c7ecce8ec3214af997',
            },
          };
          users.push(userFormatted);
          dispatch(addUser(users));
          dispatch(selectUser(userFormatted));
          openModalRole();
        }
        closeModal();
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
      });
  }
};

const setInputValueAddRole = (value, name) => ({
  type: 'USERS_ADD_ROLE_SET_INPUT_VALUE',
  value,
  name,
});
const clearAddRole = () => ({ type: 'USERS_ADD_ROLE_CLEAR' });

const addRole = (data, closeModal) => (dispatch, getState) => {
  const {
    users: {
      info: {
        selectedUser: { id },
      },
      addRole: { roles },
    },
  } = getState();

  const formattedData = {
    id, // user id getting new role applied
    companyId: data.entitySelected, // entity applied id
    operatingType: data.companySelected.toLowerCase(),
    roleTypeId: roles.find(role => role.name === data.roleSelected).id,
  };

  users.role
    .add(formattedData)
    .then(response => {
      dispatch(getUserRoles(id));
      closeModal();
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const getCustomers = () => (dispatch, getState) => {
  const save = customers => ({
    type: 'USERS_ADD_ROLE_SET_CUSTOMERS',
    customers,
  });
  customer
    .get()
    .then(msg => {
      dispatch(save(msg.data.data.customers));
    })
    .catch(error => {
      swal(error.response.data.error, '', 'error');
    });
};
const getCarriers = () => (dispatch, getState) => {
  const save = carriers => ({
    type: 'USERS_ADD_ROLE_SET_CARRIERS',
    carriers,
  });
  carrier
    .getCarriers()
    .then(response => {
      dispatch(save(response.data.data.carriers));
    })
    .catch(error => {
      swal(error.response.data.error || 'Something went wrong.', '', 'error');
    });
};
const getJobs = () => (dispatch, getState) => {
  const save = jobs => ({ type: 'USERS_ADD_ROLE_SET_JOBS', jobs });
  jobs
    .get()
    .then(msg => {
      dispatch(save(msg.data.data.jobs));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const getAutomatizeRoles = () => dispatch => {
  const success = roles => ({ type: 'USERS_ADD_ROLE_SET_ROLES', roles });
  users.role
    .getAutomatizeRoles()
    .then(response => {
      dispatch(success(response.data.data));
      swal(response.data.message, '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const getRoles = (companyType, entityId) => dispatch => {
  const success = roles => ({ type: 'USERS_ADD_ROLE_SET_ROLES', roles });
  companyType === 'automatize' ? (entityId = '') : null;
  users.role
    .getRoles(companyType, entityId)
    .then(response => {
      dispatch(success(response.data.data));
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const removeRole = data => dispatch => {
  users.role
    .remove(data)
    .then(() => {
      dispatch(getUserRoles(data.id));
      swal('Success!', '', 'success');
    })
    .catch(error => {
      swal(error.message, '', 'error');
    });
};

const removeUser = userId => dispatch => {
  users
    .remove(userId)
    .then(() => {
      dispatch(unSelectUser());
      dispatch(getUsers());
      swal('Success!', '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const restoreUser = userId => dispatch => {
  users
    .restore(userId)
    .then(() => {
      dispatch(unSelectUser());
      dispatch(getUsers());
      swal('Success!', '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

export default {
  getUsers,
  setFilterValue,
  selectUser,
  getUserRoles,
  unSelectUser,
  selectAction,
  clear,

  initUser,
  setInputValue,
  setInputEmail,
  submitAddUser,
  clearAddUser,

  setInputValueAddRole,
  getCustomers,
  getCarriers,
  addRole,
  getJobs,
  clearAddRole,
  getAutomatizeRoles,
  getRoles,

  removeRole,
  removeUser,
  restoreUser,
};
