import React from 'react';
import { orderStatus } from 'components/globalInfo/orderStatus';
import OrderList from 'components/views/OrderList';

const Orders = ({ district: {
  id
},
}) => {
  const initFilters = {
    statuses: orderStatus.reduce(
      (acc, item) => [...acc, { value: item.id, label: item.name }],
      [],
    ),
    itemsPerPage: 10,
    districtIds: [{ value: id }],
    sort: 'order_id DESC',
    show: {
      sort: true,
      order_id: true,
      statuses: true,
      truck: true,
      stage: true,
    },
  };
  return <OrderList {...{ initFilters }} />;
};

export default Orders;
