import React from 'react';
import SlidePanel from 'components/shared/SlidePanel';
import OrdersSlideout from './OrdersSlideout';

const Slideout = ({
  isVisible,
  setSlideoutVisible,// closePanel={() => setSlideoutVisible(false)}
  orders,
  jobId,
  jobName,
  jobPlanId,
  billableTimeBand
}) => (
    <SlidePanel isVisible={isVisible} closePanel={() => setSlideoutVisible(false)}>
      <OrdersSlideout
        isVisible={isVisible}
        setSlideoutVisible={setSlideoutVisible}
        billableTimeBand={billableTimeBand}
        orders={orders}
        jobId={jobId}
        jobName={jobName}
        jobPlanId={jobPlanId}
      />
    </SlidePanel>
  );

export default Slideout;
