import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

import actions from 'store/actions/OrderActions';
import targetValue from 'utils/targetValue';
import { filterOptions } from 'utils/selectUtils';

const Divert = props => {
  const {
    openModal,
    turnModal,
    divert,
    setInputValue,
    submitDivert,
    getJobPo,
    order,
    init,
    clear,
  } = props;
  const { jobs, input } = divert;
  const { jobId, additionalMileage, description } = input;
  const [saveDisabled, setSaveDisabled] = useState(true);
  const formattedJobs = jobs.map(job => {
    job.label = `${job.jobId} | ${job.jobName}`;
    return job;
  });
  const jobsList = filterOptions(formattedJobs, null, null, 'jobId', 'label');

  const determineAddDisabled = () => {
    let disabled = false;
    if (
      !description?.trim().length ||
      !additionalMileage?.trim().length ||
      !jobId
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [input]);

  useEffect(() => {
    init(order);
  }, []);

  return (
    <Modal
      className="modal-container"
      show={openModal}
      onHide={() => {
        turnModal();
        clear();
      }}>
      <Modal.Header>
        <Modal.Title as="h3">Divert</Modal.Title>
      </Modal.Header>
      <form onSubmit={submitDivert}>
        <Modal.Body>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Select
                placeholder="Job"
                onChange={item => {
                  setInputValue(item.value, 'jobId');
                  getJobPo(item.value);
                }}
                options={jobsList}
                value={jobsList.find(item => item.value === jobId)}
                required
                testSelector="order-actions_divert_job_select"
              />
            </div>
          </Row>
          <Row>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setInputValue(e, 'description')}
                value={description}
                label="Description"
                required
                testSelector="order-actions_divert_additional-mileage_input"
              />
            </div>
            <div className="form-group has-feedback col-md-6">
              <Input
                onChange={e => setInputValue(e, 'additionalMileage')}
                value={additionalMileage}
                label="Additional Mileage"
                required
                testSelector="order-actions_divert_description_input"
              />
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => {
              turnModal();
              clear();
            }}
            colour="white"
            testSelector="order-actions_divert_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="order-actions_divert_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  divert: state.orderActions.divert,
  order: state.orderActions.order,
});

const mapDispatchToProps = dispatch => ({
  init: order => dispatch(actions.initDivert(order)),
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueDivert(targetValue(e), name)),
  getJobPo: e => dispatch(actions.getJobPos(targetValue(e))),
  submitDivert: e => {
    e.preventDefault();
    dispatch(actions.submitDivert());
  },
  clear: () => dispatch(actions.clearDivertData()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Divert);
