import axios from 'axios';
import swal from 'bootstrap-sweetalert';

export const getScadaReport = async customerId => {
  try {
    const scadaData = await axios.get(`v2/customer/${customerId}/scada-report`);

    const hiddenElement = document.createElement('a');
    const blob = new Blob([scadaData.data], {
      type: 'application/csv;charset=utf-8',
    });
    hiddenElement.href = window.URL.createObjectURL(blob);
    hiddenElement.download = 'scada.csv';
    hiddenElement.click();
  } catch (error) {
    swal(
      'Unable to get SCADA report.',
      error?.response?.data?.message,
      'error',
    );
  }
};
