import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal, Row } from 'react-bootstrap';
import moment from 'moment';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import DatePicker from 'components/shared/DatePicker';

import { useJobDetails } from 'api/v2/jobs';
import actions from 'store/actions/JobDetails/AddOrderModal';
import targetValue from 'utils/targetValue';
import { isDateFormatValid } from 'utils/datePickersFunctions';
import Loader from 'components/shared/Loader';
import StagingSiteSelect from './StagingSiteSelect';
import { useDriverOptions, usePoCommodities, useStageOptions } from './hooks';

const AddOrder = ({
  jobId,
  showAddOrder,
  closeAddOrderModal,
  addOrderData,
  setInputValue,
  setAutoAssignValue,
  submitAddOrder,
  getCalculatedPickUp,
  clearModalStore,
}) => {
  const { data: jobDetails, isLoading } = useJobDetails({ jobId });
  const { details } = jobDetails;

  const [selectedPo, setSelectedPo] = useState();

  useEffect(() => {
    if (details?.stagingSite) {
      setInputValue(details?.stagingSite, 'stagingSite');
    }
    return () => {
      clearModalStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [isDateAlert, turnDateAlert] = useState(false);
  const [isCertRequired, setIsCertRequired] = useState(false);
  const { addJob, disableSubmit } = addOrderData;
  const {
    startDate,
    loadDate,
    customerOrderId,
    count,
    stage,
    autoAssign,
    driverId,
    po,
    stagingSite,
  } = addJob;

  const isWaterJob = useMemo(() => details?.operationType === 12, [
    details?.operationType,
  ]);

  const saveDisabled = useMemo(
    () => !po || !count.length || (!autoAssign && !driverId),
    [po, count, autoAssign, driverId],
  );

  const poCommodities = usePoCommodities(jobId);
  const driverOptions = useDriverOptions({ jobId, po: selectedPo });
  const stageOptions = useStageOptions(jobId);

  const handleStartDateBlur = async () => {
    if (addJob.po.originId && addJob.startDate) {
      try {
        getCalculatedPickUp({
          unloadAppointment: addJob.startDate,
          originId: Number(addJob.po.originId),
          destinationId: Number(addJob.po.destinationId),
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleWellsite = async wellPo => {
    setSelectedPo(wellPo);
    setInputValue(wellPo, 'po');
    if (wellPo.originId && wellPo.destinationId) {
      try {
        getCalculatedPickUp({
          unloadAppointment: addJob.startDate,
          originId: Number(wellPo.originId),
          destinationId: Number(wellPo.destinationId),
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handleWarning = async interstateCertRequired => {
    const certRequired = Boolean(interstateCertRequired);
    setIsCertRequired(certRequired);
  };

  // Sync the default time with opening the modal.
  useEffect(() => {
    setInputValue(moment().add(1, 'm'), 'startDate');
  }, [setInputValue, showAddOrder]);

  const handleSubmit = (e) => {
    e.preventDefault();
    submitAddOrder(jobId);
  }

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Modal
      show={showAddOrder}
      fullscreen="sm-down"
      onHide={closeAddOrderModal}>
      <Modal.Header>
        <h3>Add New Order</h3>
      </Modal.Header>
      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <Row>
            {isDateAlert && (
              <div className="nested-no-outer-gutter" style={{ color: 'red' }}>
                <p>* Incorrect date format</p>
              </div>
            )}
            <div className="form-group has-feedback col-md-6">
              <DatePicker
                label="Deliver Appointment"
                timeFormat="hh:mm A"
                required
                onBlur={handleStartDateBlur}
                value={startDate}
                onChange={e => {
                  isDateFormatValid(e, turnDateAlert);
                  setInputValue(e, 'startDate');
                }}
                testSelector="job-details_add-order_deliver-appt_dp"
              />
            </div>
            {!isWaterJob && (
              <div className="form-group has-feedback col-md-6">
                <DatePicker
                  label="Pick Up Appointment"
                  timeFormat="hh:mm A"
                  required
                  value={loadDate}
                  onChange={e => {
                    isDateFormatValid(e, turnDateAlert);
                    setInputValue(e, 'loadDate');
                  }}
                  disabled={!po.poNo}
                  testSelector="job-details_add-order_pickup-appt_dp"
                />
              </div>
            )}
            {!isWaterJob && (
              <>
                <div className="form-group has-feedback col-md-6">
                  {isCertRequired && (
                    <div style={{ color: 'rgba(239, 61, 61, 0.483)' }}>
                      *Interstate Cert Required
                    </div>
                  )}
                  <Select
                    placeholder="PO / Commodity / Location"
                    onChange={item => {
                      // getFreeDrivers(item.value);
                      handleWellsite(item);
                      handleWarning(item.interstateCertRequired);
                    }}
                    required
                    value={poCommodities.find(item => item.value === po.poNo)}
                    options={poCommodities}
                    testSelector="job-details_add-order_po_select"
                  />
                </div>
                <div className="form-group has-feedback col-md-6">
                  <Select
                    placeholder="Choose stage"
                    onChange={event => setInputValue(event.value, 'stage')}
                    value={stageOptions.find(
                      item => item.value === stage || null,
                    )}
                    options={stageOptions}
                    testSelector="job-details_add-order_stage_select"
                  />
                </div>
              </>
            )}
            <div className="form-group col-md-6">
              <Input
                type="number"
                onChange={e => setInputValue(e, 'count')}
                value={count}
                label="Amount of orders"
                min="1"
                max="100"
                required
                testSelector="job-details_add-order_amount_input"
              />
            </div>
            <div className="form-group col-md-6">
              <Input
                onChange={e => setInputValue(e, 'customerOrderId')}
                value={customerOrderId}
                label="Customer order ID"
                testSelector="job-details_add-order_customer-id_input"
              />
            </div>
            {details.stagingSite && (
              <div className="form-group col-md-6">
                <StagingSiteSelect
                  onChange={e =>
                    setInputValue(e && e.value ? e.value : null, 'stagingSite')
                  }
                  jobStagingSite={details.stagingSite}
                  stagingSite={stagingSite}
                />
              </div>
            )}
            <div className="form-group has-feedback col-md-6">
              <Input
                type="checkbox"
                isChecked={autoAssign}
                onChange={setAutoAssignValue}
                label="Auto-assign Driver"
                testSelector="job-details_add-order_assign-driver_input"
                disabled={!isWaterJob ? !po.poNo : false}
              />

              {!autoAssign && (
                <Select
                  placeholder="Choose driver"
                  onChange={event => setInputValue(event.value, 'driverId')}
                  required
                  value={driverOptions.find(
                    item => item.value === driverId || null,
                  )}
                  options={driverOptions}
                  isDisabled={!isWaterJob ? !po.poNo : false}
                  testSelector="job-details_add-order_driver_select"
                />
              )}
            </div>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={closeAddOrderModal}
            colour="white"
            testSelector="job-details_add-order_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={disableSubmit || saveDisabled || isDateAlert}
            testSelector="job-details_add-order_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  addOrderData: state.jobDetails.addOrderModal,
});

const mapDispatchToProps = (dispatch, ownProps) => ({
  setInputValue: (e, name) =>
    dispatch(actions.setInputValue(targetValue(e), name)),
  setAutoAssignValue: () => dispatch(actions.setAutoAssignValue()),
  submitAddOrder: (jobId) => {
    dispatch(
      actions.submitAddOrder(ownProps.closeAddOrderModal, ownProps.initFilters, jobId),
    );
  },
  clearModalStore: () => dispatch(actions.clearModalStore()),
  getCalculatedPickUp: data => {
    dispatch(actions.getCalculatedPickUp(data));
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AddOrder);
