import swal from 'bootstrap-sweetalert';
import { district } from 'services/district/service';
import { carrier } from 'services/carrier/service';
import { queryClient } from 'api/APIProvider';

const clear = () => ({ type: 'DISTRICT_DETAILS_CLEAR' });
const setTab = value => ({ type: 'DISTRICT_DETAILS_SET_TAB', value });

// Add carrier modal
const setInputValueCarrier = (value, name) => ({
  type: 'DISTRICT_DETAILS_ADD_CARRIER_SET_INPUT_VALUE',
  value,
  name,
});

const initAddCarrier = () => (dispatch, getState) => {
  const {
    district: {
      carriers
    },
  } = getState();
  const carrierIds = carriers.map(item => item.id);
  carrier.getCarriers().then(msg => {
    const unicCarriers = msg.data.data.carriers.filter(
      item => !carrierIds.includes(item.id),
    );
    const carrierForSelect = unicCarriers.map(item => ({
      value: item.id,
      label: `${item.id} | ${item.name}`,
    }));
    dispatch(setInputValueCarrier(carrierForSelect, 'carriersArray'));
  });
};

const deleteCarrier = (districtId, carrierId) => dispatch => {
  const data = {
    carrierId,
    districtId,
  };

  carrier
    .deleteDistrict({
      districtId,
      carrierId,
      deleteDriverDistricts: 1,
    })
    .then(() => {
      queryClient.invalidateQueries(['districts', Number(districtId), 'details']);
      swal('Success!', '', 'success');
    })
    .catch(error => {
      swal(error.response.data.message, '', 'error');
    });
};

const clearAddCarrier = () => ({ type: 'DISTRICT_DETAILS_ADD_CARRIER_CLEAR' });

// Add unit modal
const clearAddUnit = () => ({ type: 'DISTRICT_DETAILS_ADD_UNIT_CLEAR' });

// Edit district
const clearEditDistrict = () => ({
  type: 'DISTRICT_DETAILS_EDIT_DISTRICT_CLEAR',
});

// Edit ratio
const setInputValueEditRatio = (value, name) => ({
  type: 'DISTRICT_DETAILS_EDIT_RATIO_SET_INPUT_VALUE',
  value,
  name,
});

// edit unit

const clearEditUnit = () => ({ type: 'DISTRICT_DETAILS_EDIT_UNIT_CLEAR' });

export default {
  clear,
  setTab,
  // add carrier
  setInputValueCarrier,
  clearAddCarrier,
  initAddCarrier,
  // delete carrier
  deleteCarrier,
  // add unit
  clearAddUnit,
  // edit district
  clearEditDistrict,
  // edit ratio
  setInputValueEditRatio,
  // edit unit
  clearEditUnit,
};
