import React, { useCallback, useMemo } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { Container, Row } from 'react-bootstrap';

import Authorize, { authorize } from 'components/common/Authorize';
import DropdownButton from 'components/shared/DropdownButton';
import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';

import { useGetCustomerDetailsQuery } from 'features/api/apiSlice';
import { useJobs } from 'api/v2/jobs';
import Info from './Info';
import Stats from './Stats';
import CustomerTabs from './Tabs';
import { getScadaReport } from './helpers';

const CustomerDetails = () => {
  const { customerId } = useParams();
  const { data: details, isLoading } = useGetCustomerDetailsQuery(customerId);

  const { data: jobs } = useJobs(
    {
      customerId: details?.id,
    },
    {
      enabled: !!details?.id,
    },
  );
  const jobStats = useMemo(() => {
    const data = {
      count: 0,
      draft: 0,
      ongoing: 0,
      completed: 0,
    };
    if (jobs) {
      data.count = jobs.length;
      jobs.forEach(item => {
        if (item.status) {
          data.completed += 1;
        } else if (item.isDraft) {
          data.draft += 1;
        } else {
          data.ongoing += 1;
        }
      });
      return data; 
    }
    return data;
  }, [jobs]);

  const history = useHistory();
  const location = useLocation();
  const handleContainerLink = useCallback(() => {
    const params = new URLSearchParams();

    if (customerId) {
      params.set('customerId', customerId);
    }

    return history.push({
      pathname: `/${location.pathname.split('/')[1]}/equipment/containers`,
      search: params.toString(),
    });
  }, [history, location, customerId]);

  const actionOptions = useMemo(() => {
    const tempArr = [
      {
        name: 'containerDetails',
        label: 'View Container Details',
        onClick: () => handleContainerLink(),
      },
    ];

    if (
      details?.hasScadaData &&
      authorize({
        abilityPermissions: [{ resource: 'Customers', permissions: ['read'] }],
        itemAccessGranted: details.accessLevel === 'owner',
      })
    ) {
      tempArr.push({
        name: 'downloadScada',
        label: 'Download SCADA',
        onClick: () => getScadaReport(details?.id),
      });
    }
    return tempArr;
  }, [details, handleContainerLink]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container fluid>
      <Header title="Customer Details">
        <Authorize
          abilityPermissions={[
            { resource: 'Customers', permissions: ['read'] },
          ]}
          itemAccessGranted={details.accessLevel === 'owner'}>
          <div className="float-end">
            <DropdownButton
              inverse
              text="Actions"
              icon="down"
              options={actionOptions}
              testSelector="job-details_actions_dropdown-btn"
            />
          </div>
        </Authorize>
      </Header>
      <Authorize
        abilityPermissions={[{ resource: 'Customers', permissions: ['read'] }]}>
        <Row>
          <Stats jobStats={jobStats} details={details} />
          <Info details={details} />
          <CustomerTabs customerId={details.id} />
        </Row>
      </Authorize>
    </Container>
  );
};
export default CustomerDetails;
