import React from 'react';
import moment from 'moment';
import { orderStatus } from 'components/globalInfo/orderStatus';
import OrderList from 'components/views/OrderList';

const Orders = () => {
  const initFilters = {
    sort: 'order_id DESC',
    order_id: '',
    stage: '',
    statuses: orderStatus.reduce(
      (acc, item) =>
        item.id !== 4 ? [...acc, { value: item.id, label: item.name }] : acc,
      [],
    ),
    truck: '',
    itemsPerPage: 10,
    carrierIds: [],
    customerIds: [],
    districtIds: [],
    jobIds: [],
    startDate: moment().subtract(3, 'day'),
    endDate: moment(),
    show: {
      statuses: true,
      carrierIds: true,
      customerIds: true,
      districtIds: true,
      jobIds: true,
      date: true,
      count: true,
      origin: true,
      sortByDate: true,
    },
  };
  return <OrderList initFilters={initFilters} />;
};
export default Orders;
