import moment from 'moment';
import swal from 'bootstrap-sweetalert';

export const formattedDate = date =>
  moment(date, ['YYYY-MM-DD hh:mm A', 'MM-DD-YYYY hh:mm A', 'D MMM YYYY H:mm']);

export const formattedPos = (pos, operationType) =>
  pos.map(item => {
    item.label =
      operationType !== 12
        ? `${item.poNo} | ${item.sandTypeName} | ${item.originName}`
        : `${item.originName}`;
    item.value = item.poNo;
    item.disabled = !item.enabled;
    return item;
  });

export const submitFunction = (
  e,
  stageArrivalTime,
  stageDepartureTime,
  submitModify,
  shouldComplete,
  orderStatus,
  stagingSite,
  jobPlansData,
  callbackFn,
) => {
  e.preventDefault();

  if (
    stagingSite &&
    stageArrivalTime &&
    stageArrivalTime !== 'Invalid date' &&
    (stageDepartureTime === 'Invalid date' || !stageDepartureTime) &&
    (shouldComplete || orderStatus === 4)
  ) {
    swal('Please, select a Stage Departure Time', '', 'error');
  } else if (
    stagingSite &&
    stageDepartureTime &&
    stageDepartureTime !== 'Invalid date' &&
    (stageArrivalTime === 'Invalid date' || !stageArrivalTime)
  ) {
    swal('Please, select a Stage Arrival Time', '', 'error');
  } else if (jobPlansData) {
    submitModify(jobPlansData, callbackFn);
  } else {
    submitModify();
  }
};

export const isLoadWeightValid = (weight, jobWeight) => {
  if (weight && (weight > jobWeight || weight < 0)) {
    return true;
  }
  return false;
};
