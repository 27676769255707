import swal from 'bootstrap-sweetalert';
import moment from 'moment';
import initAction from 'store/actions/OrderDetails';
import orderListAction from 'store/actions/OrdersList';
import { order } from 'services/order/service';
import { jobs } from 'services/jobs/service';
import { queue } from 'services/queue/service';
import authService from 'services/auth/service';
import sandTicket from 'services/sandTicket/service';
import { convertTime, toUTC } from 'utils/convertTime';
import jobPlansActions from 'store/actions/JobPlans';


const pageLocation = window.location.pathname.split('/')[2];

const openModal = (modal, orderData) => dispatch => {
  const open = type => ({ type });
  let modalType;
  if (orderData) {
    dispatch({ type: 'ORDER_ACTIONS_SET_ORDER', order: orderData });
  } else {
    dispatch({ type: 'ORDER_ACTIONS_CLEAR_ORDER' });
  }
  switch (modal) {
    case 'modify':
      modalType = 'ORDER_ACTIONS_MODIFY_TURN';
      break;
    case 'uploadST':
      modalType = 'ORDER_ACTIONS_UPLOAD_ST_TURN';
      break;
    case 'divert':
      modalType = 'ORDER_ACTIONS_DIVERT_TURN';
      break;
    case 'transfer':
      modalType = 'ORDER_ACTIONS_TRANSFER_TURN';
      break;
    case 'requeue':
      modalType = 'ORDER_ACTIONS_REQUEUE_TURN';
      break;
    default:
      break;
  }
  if (modalType) dispatch(open(modalType));
};

const releaseDriver = orderId => dispatch => {
  order
    .releaseDriverFromOrder({ orderId })
    .then(() => {
      swal('Success!', '', 'success');
      if (pageLocation === 'order-details') {
        dispatch(initAction.init(orderId));
      } else {
        dispatch(orderListAction.refreshOrder(orderId));
      }
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const callToWell = orderInfo => dispatch => {
  order
    .callDriverToWell({
      orderId: orderInfo.order_id,
      driverId: orderInfo.driver_id,
    })
    .then(() => {
      swal('Success!', '', 'success');
      if (pageLocation === 'order-details') {
        dispatch(initAction.init(orderInfo.order_id));
      } else {
        dispatch(orderListAction.refreshOrder(orderInfo.order_id));
      }
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const deleteOrder = orderInfo => dispatch => {
  const user = authService.getUser();
  const payload = {
    orderId: orderInfo.order_id,
    driverId: orderInfo.driver_id,
    userId: user.id,
  };
  order
    .delete(payload)
    .then(() => {
      swal('Success!', '', 'success');

      if (pageLocation === 'order-details') {
        dispatch(initAction.init(orderInfo.order_id));
      }
      else {
        dispatch(orderListAction.getOrders());
      }
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const reviveOrder = orderInfo => dispatch => {
  const payload = {
    orderId: orderInfo.order_id,
  };
  order
    .revive(payload)
    .then(() => {
      swal('Success!', '', 'success');
      dispatch(initAction.init(orderInfo.order_id));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

// Divert modal

const setInputValueDivert = (value, name) => ({
  type: 'ORDER_ACTIONS_DIVERT_SET_INPUT_VALUE',
  value,
  name,
});

const initDivert = data => dispatch => {
  const setInitValue = value => ({
    type: 'ORDER_ACTIONS_DIVERT_INIT_JOBS',
    jobs: value,
  });

  dispatch(setInputValueDivert(data.order_id, 'orderId'));

  order
    .getJobsForDiversion(data.order_id)
    .then(msg => dispatch(setInitValue(msg.data.data.jobs)));
};

const submitDivert = () => (dispatch, getState) => {
  const successDivert = () => ({
    type: 'ORDER_ACTIONS_DIVERT_SUCCESS',
  });

  const state = getState();
  const data = { ...state.orderActions.divert.input };

  order
    .divertOrder(data)
    .then(() => {
      swal('Success!', '', 'success');
      dispatch(successDivert());
      if (pageLocation === 'order-details') {
        dispatch(initAction.init(data.orderId));
      } else {
        dispatch(orderListAction.refreshOrder(data.orderId));
      }
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const getJobPos = jobId => dispatch => {
  dispatch(setInputValueDivert('', 'poId'));

  if (jobId) {
    jobs
      .getJobPo(jobId)
      .then(msg => {
        dispatch(setInputValueDivert(msg.data.data.po, 'po'));
      })
      .catch(error => swal(error.response.data.message, '', 'error'));
  } else {
    dispatch(setInputValueDivert([]));
  }
};

const clearDivertData = () => dispatch => {
  dispatch({ type: 'ORDER_ACTIONS_DIVERT_CLEAR' });
};

// requeue

const initRequeue = data => dispatch => {
  const setInitValue = (value, name) => ({
    type: 'ORDER_ACTIONS_REQUEUE_INIT_VALUE',
    value,
    name,
  });
  const {
    job_id: jobId,
    unload_appt: unloadAppointment,
    expected_pickup_time: expectedPickupTime,
    order_id: orderId,
  } = data;

  const input = {
    po: data.po,
    unloadAppointment: convertTime(unloadAppointment),
    expectedPickupTime: convertTime(expectedPickupTime),
    orderId,
  };

  dispatch(setInitValue(input, 'input'));
};

const submitRequeue = () => (dispatch, getState) => {
  const request = () => ({ type: 'ORDER_ACTIONS_REQUEUE_REQUEST' });
  const success = () => ({ type: 'ORDER_ACTIONS_REQUEUE_SUCCESS' });
  const state = getState();
  const data = { ...state.orderActions.requeue.input };
  const orderCreated = state.orderActions.order.created_at;
  const validationDate = (date1, date2) => !(new Date(date2) < new Date(date1));

  data.unloadAppointment = toUTC(data.unloadAppointment);

  if (data.expectedPickupTime != null) {
    data.expectedPickupTime = toUTC(data.expectedPickupTime);
  }

  dispatch(request());
  if (
    !data.expectedPickupTime &&
    !validationDate(moment(toUTC(orderCreated)), moment(data.unloadAppointment))
  ) {
    swal(
      `Unload Appointment time must be later than order was created`,
      '',
      'error',
    );
    dispatch(request());
  } else if (
    data.expectedPickupTime &&
    !validationDate(
      moment(data.expectedPickupTime),
      moment(data.unloadAppointment),
    )
  ) {
    swal(
      `Unload Appointment time must be later than Expected Pickup time`,
      '',
      'error',
    );
    dispatch(request());
  } else if (
    data.expectedPickupTime &&
    !validationDate(
      moment(toUTC(orderCreated)),
      moment(data.expectedPickupTime),
    )
  ) {
    swal(
      `Expected Pickup time must be later than order was created`,
      '',
      'error',
    );
    dispatch(request());
  } else {
    order
      .requeue(data)
      .then(() => {
        swal('Success!', '', 'success');
        dispatch(success());
        if (pageLocation === 'order-details') {
          dispatch(initAction.init(data.orderId));
        } else {
          dispatch(orderListAction.refreshOrder(data.orderId));
        }
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
        dispatch(request());
      });
  }
};

const clearStateRequeue = () => ({
  type: 'ORDER_ACTIONS_REQUEUE_CLEAR_STATE',
});

const setInputValueRequeue = (value, name) => ({
  type: 'ORDER_ACTIONS_REQUEUE_SET_INPUT_VALUE',
  value,
  name,
});

// transfer

const setInputValueTransfer = (value, name) => ({
  type: 'ORDER_ACTIONS_TRANSFER_SET_INPUT_VALUE',
  value,
  name,
});

const initTransfer = data => dispatch => {
  const setInitValue = drivers => ({
    type: 'ORDER_ACTIONS_TRANSFER_INIT_DRIVER',
    drivers,
  });

  dispatch(setInputValueTransfer(data.order_id, 'orderId'));

  queue
    .getAvailableDrivers()
    .then(msg => dispatch(setInitValue(msg.data.data.availableDrivers)));
};

const submitTransfer = () => (dispatch, getState) => {
  const successTransfer = () => ({
    type: 'ORDER_ACTIONS_TRANSFER_SUCCESS',
  });
  const validationDate = (date1, date2) => !(new Date(date2) < new Date(date1));

  const state = getState();
  const data = { ...state.orderActions.transfer.input };

  if (!data.showNewTruck) {
    delete data.newTruck;
  }
  if (!validationDate(moment(), moment(data.date))) {
    swal(`Actual Transfer Date must be later than now`, '', 'error');
  } else {
    data.date = toUTC(data.date);
    order.transfer
      .put(data)
      .then((msg) => {
        if (msg.data.data.failedToSend?.length > 0) {
          swal(`Transfer Successful; Failed to send notification to ${msg.data.data.failedToSend.join(' and ')}`, '', 'error');
        } else {
          swal('Success!', '', 'success');
        }

        dispatch(successTransfer());
        if (pageLocation === 'order-details') {
          dispatch(initAction.init(data.orderId));
        } else {
          dispatch(orderListAction.refreshOrder(data.orderId));
        }
      })
      .catch(error => swal(error.response.data.message, '', 'error'));
  }
};

// upload ST
const setInputValueST = (value, name) => ({
  type: 'ORDER_ACTIONS_UPLOAD_ST_SET_INPUT',
  value,
  name,
});

const selectFileST = e => dispatch => {
  if (e.target.files && e.target.files.length > 0) {
    const reader = new FileReader();
    const file = e.target.files[0];
    dispatch(setInputValueST(file.name, 'fileName'));
    reader.addEventListener(
      'load',
      () => dispatch(setInputValueST(reader.result, 'url')),
      false,
    );
    reader.readAsDataURL(file);
  }
};

const generateImg = (url, pixelCrop) => {
  const canvas = document.createElement('canvas');
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;
  const ctx = canvas.getContext('2d');

  return new Promise(resolve => {
    const img = new Image();
    if (pixelCrop.width) {
      img.onload = () => {
        ctx.drawImage(
          img,
          pixelCrop.x,
          pixelCrop.y,
          pixelCrop.width,
          pixelCrop.height,
          0,
          0,
          pixelCrop.width,
          pixelCrop.height,
        );
        resolve();
      };
    } else {
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        resolve();
      };
    }
    img.src = url;
  }).then(
    () =>
      new Promise(resolve => {
        canvas.toBlob(file => {
          file.name = 'sandTiket';
          resolve(file);
        }, 'image/jpeg');
      }),
  );
};

const saveST = orderData => (dispatch, getState) => {
  const request = () => ({
    type: 'ORDER_ACTIONS_UPLOAD_ST_SET_REQUEST',
  });
  const success = () => ({
    type: 'ORDER_ACTIONS_UPLOAD_ST_SET_SUCCESS',
  });
  const state = getState();
  const { uploadST } = state.orderActions;
  const { input } = uploadST;

  const {
    sandTicketNo,
    weight,
    sandTicketType,
    containerIds,
    url,
    pixelCrop,
    crop,
    fileName,
    king,
    compartment,
  } = input;
  const { order_id: orderId, driver_id: driverId, equipment } = orderData;
  const size = pixelCrop || crop;
  dispatch(request());
  generateImg(url, size).then(img => {
    const formData = new FormData();
    const file = new File([img], fileName);
    formData.append('orderId', orderId);
    formData.append('driverId', driverId);
    formData.append('sandTicketNo', sandTicketNo);
    formData.append('weight', weight);
    formData.append('bol', file);
    formData.append('king', king);
    formData.append('compartment', compartment);
    if (sandTicketType) {
      formData.append('sandTicketType', sandTicketType);
    }
    if (equipment === 3) {
      const selectedContainer = containerIds.map(item => item.value);
      // if (containersPerTruck != selectedContainer.length) {
      //   swal(`Need to select ${containersPerTruck} containers`, '', 'error');
      //   return false;
      // }
      if (selectedContainer.length) {
        formData.append('containerIds', `[${selectedContainer}]`);
      }
    }
    sandTicket
      .post(formData)
      .then(() => {
        swal('Success!', '', 'success');
        dispatch(success());
        if (pageLocation === 'order-details') {
          dispatch(initAction.init(orderId));
        } else {
          dispatch(orderListAction.refreshOrder(orderId));
        }
      })
      .catch(error => {
        swal(error.response.data.message, '', 'error');
        dispatch(request());
      });
  });
};

const initST = data => dispatch => {
  const setInitValue = (name, value) => ({
    type: 'ORDER_ACTIONS_UPLOAD_ST_SET_INIT_DATA',
    name,
    value,
  });
  order
    .containers(data.order_id)
    .then(msg =>
      dispatch(setInitValue('containers', msg.data.data.containers)),
    );
  order.getSandTicketTypesDesign(data.order_id).then(msg => {
    const requiredTickets = msg.data.data.sandTicketTypes;
    dispatch(setInitValue('sandTicketsTypes', requiredTickets));
  });

  dispatch(setInitValue('containersPerTruck', data.containersPerTruck));
};

const clearStateST = () => ({
  type: 'ORDER_ACTIONS_UPLOAD_ST_SET_SUCCESS',
});

const initModify = data => dispatch => {
  const setInitValue = (value, name) => ({
    type: 'ORDER_ACTIONS_MODIFY_INIT_VALUE',
    value,
    name,
  });
  const {
    sand_ticket_no: sandTicketNo,
    mileage,
    order_accepted_at: orderAcceptedAt,
    load_arrival: loadArrival,
    load_depart: loadDepart,
    enter_stage: enterStage,
    depart_stage: departStage,
    well_arrival: wellArrival,
    well_depart: wellDepart,
    order_id: orderId,
    chat_id: chatId,
    driver_id: driverId,
    customer_order_id: customerOrderId,
    driver_weight: driverWeight,
    stage,
    jobId,
    updated_at: updatedAt,
    king_comp: kingComp,
    truck,
    truck_status: truckStatus,
    trailer_number: trailerNumber,
    created_at: createdAt,
    completed_at: completedAt,
    status,
    staging_site,
    staging_stop_type,
  } = data;

  const input = {
    mileage,
    customerOrderId,
    sandTicketNo,
    orderAcceptedAt: convertTime(orderAcceptedAt),
    loadArrivalTime: convertTime(loadArrival),
    loadDepartureTime: convertTime(loadDepart),
    wellArrivalTime: convertTime(wellArrival),
    wellDepartureTime: convertTime(wellDepart),
    orderId,
    driverWeight,
    stage,
    shouldComplete: false,
    updatedAt,
    po: data.po,
    kingComp,
    truck,
    trailer_number: trailerNumber,
    truckDown: truckStatus === 1 ? 1 : 0,
    isStageSiteOnOrder: staging_site && staging_stop_type,
    createdAt,
    completedAt,
    orderStatus: status,
  };

  if (enterStage) {
    input.stageArrivalTime = convertTime(enterStage);
  }
  if (departStage) {
    input.stageDepartureTime = convertTime(departStage);
  }

  const notificationInfo = {
    customer_order_id: customerOrderId,
    chat_id: chatId,
    driver_id: driverId,
  };

  dispatch(setInitValue(input, 'input'));
  dispatch(setInitValue(notificationInfo, 'notificationInfo'));

  jobs.stage
    .get(jobId || jobId)
    .then(msg => dispatch(setInitValue(msg.data.data.stages, 'stages')))
    .catch(error => swal(error.response.data.message, '', 'error'));

  order
    .getTickets(orderId)
    .then(msg => {
      const tickets = (msg.data.data && msg.data.data.tickets) || [];
      const config = (msg.data.data && msg.data.data.config) || [];
      const filteredTickets = tickets.filter(ot => !ot.isDeleted);
      const formattedTickets = {
        tickets: filteredTickets,
        config,
      };
      dispatch(setInitValue(formattedTickets, 'orderTickets'));
    })
    .catch(() => {
      // Fail silently here
      const formattedTickets = {
        ticets: [],
        config: [],
      };
      dispatch(setInitValue(formattedTickets, 'orderTickets'));
    });
};

// demurrage reason

const initDemurrageReason = data => dispatch => {
  const setInitValue = value => ({
    type: 'ORDER_ACTIONS_DEMURRAGE_INIT_VALUES',
    data: value,
  });
  dispatch(setInitValue(data));
};

const turnModalDemurrageReason = (reloadPage = false) => (
  dispatch,
  getState,
) => {
  const state = getState();
  const data = { ...state.orderActions.order };
  dispatch({ type: 'ORDER_ACTIONS_DEMURRAGE_TURN' });
  if (reloadPage) {
    if (pageLocation === 'order-details') {
      dispatch(initAction.init(data.order_id));
    } else {
      dispatch(orderListAction.refreshOrder(data.order_id));
    }
  }
};

const saveDemurrageReason = () => (dispatch, getState) => {
  const successReason = () => ({ type: 'ORDER_ACTIONS_SET_REASON_SUCCESS' });

  const {
    orderActions: {
      demurrageReason: {
        input: { sandSiteReason, wellsiteReason },
        dataDemurrage: { sandSiteDemurrageReasonId, wellSiteDemurrageReasonId },
      },
      order: { order_id: orderId },
    },
  } = getState();

  const paramData = {
    orderId,
    sandsiteDemurrageReasonId:
      sandSiteReason || sandSiteDemurrageReasonId || '0',
    wellsiteDemurrageReasonId:
      wellsiteReason || wellSiteDemurrageReasonId || '0',
  };

  order
    .setDemurrageReasons(paramData)
    .then(() => {
      swal('Success!', '', 'success');
      dispatch(turnModalDemurrageReason(true));
      dispatch(successReason());
    })
    .catch(error => {
      swal(error.message, '', 'error');
    });
};

const setInputValueDemurrageReason = (value, name) => ({
  type: 'ORDER_ACTIONS_DEMURRAGE_SET_INPUT_VALUE',
  value,
  name,
});

const checkTime = data => {
  const validationDate = (date1, date2) => {
    const dat1 = Date.parse(date1);
    const dat2 = Date.parse(date2);
    return !(dat2 < dat1);
  };

  const sandSiteTime = moment(data.loadDepartureTime).diff(
    moment(data.loadArrivalTime),
    'minutes',
  );
  const wellsiteTime = moment(data.wellDepartureTime).diff(
    moment(data.wellArrivalTime),
    'minutes',
  );

  if (
    data.orderAcceptedAt !== 'Invalid date' &&
    !validationDate(
      moment(data.createdAt)
        .utc()
        .format('YYYY/MM/DD hh:mm A'),
      moment(data.orderAcceptedAt).format('YYYY/MM/DD hh:mm A'),
    )
  ) {

    swal(
      `Order Accepted Time must be later than Order Created (${moment(
        data.createdAt,
      ).format('YYYY-MM-DD hh:mm A')})`,
      '',
      'error',
    );
  } else if (
    data.orderAcceptedAt !== 'Invalid date' &&
    data.loadArrivalTime !== 'Invalid date' &&
    !validationDate(data.orderAcceptedAt, data.loadArrivalTime)
  ) {
    swal(
      'Origin Arrival Time must be later than Order Accepted Time',
      '',
      'error',
    );
  } else if (
    data.loadArrivalTime !== 'Invalid date' &&
    data.loadDepartureTime !== 'Invalid date' &&
    !validationDate(data.loadArrivalTime, data.loadDepartureTime)
  ) {
    swal(
      'Origin Departure Time must be later than Origin Arrival Time',
      '',
      'error',
    );
  } else if (
    data.isStageSiteOnOrder &&
    data.loadDepartureTime !== 'Invalid date' &&
    data.stageArrivalTime &&
    data.stageArrivalTime !== 'Invalid date' &&
    !validationDate(data.loadDepartureTime, data.stageArrivalTime)
  ) {
    swal(
      'Stage Arrival Time must be later than Origin Departure Time',
      '',
      'error',
    );
  } else if (
    data.isStageSiteOnOrder &&
    data.stageArrivalTime &&
    data.stageArrivalTime !== 'Invalid date' &&
    data.stageDepartureTime &&
    data.stageDepartureTime !== 'Invalid date' &&
    !validationDate(data.stageArrivalTime, data.stageDepartureTime)
  ) {
    swal(
      'Stage Departure time must be later than Stage Arrival Time',
      '',
      'error',
    );
  } else if (
    data.loadDepartureTime !== 'Invalid date' &&
    data.wellArrivalTime !== 'Invalid date' &&
    !validationDate(data.loadDepartureTime, data.wellArrivalTime)
  ) {
    swal(
      'Destination Arrival Time must be later than Origin Departure Time',
      '',
      'error',
    );
  } else if (
    data.isStageSiteOnOrder &&
    data.stageArrivalTime &&
    data.stageArrivalTime !== 'Invalid date' &&
    (!data.stageDepartureTime ||
      (data.stageDepartureTime &&
        data.stageDepartureTime === 'Invalid date')) &&
    data.wellArrivalTime !== 'Invalid date' &&
    !validationDate(data.stageArrivalTime, data.wellArrivalTime)
  ) {
    swal(
      'Destination Arrival Time must be later than Stage Arrival Time',
      '',
      'error',
    );
  } else if (
    data.isStageSiteOnOrder &&
    data.stageDepartureTime &&
    data.stageDepartureTime !== 'Invalid date' &&
    data.wellArrivalTime !== 'Invalid date' &&
    !validationDate(data.stageDepartureTime, data.wellArrivalTime)
  ) {
    swal(
      'Destination Arrival Time must be later than Stage Departure Time',
      '',
      'error',
    );
  } else if (
    data.wellArrivalTime !== 'Invalid date' &&
    data.wellDepartureTime !== 'Invalid date' &&
    !validationDate(data.wellArrivalTime, data.wellDepartureTime)
  ) {
    swal(
      'Destination Departure time must be later than Destination Arrival Time',
      '',
      'error',
    );
  } else if (
    data.loadDepartureTime &&
    data.loadArrivalTime &&
    sandSiteTime < 5
  ) {
    swal('Time at origin must be more than 4 minutes', '', 'error');
  } else if (
    data.wellDepartureTime &&
    data.wellArrivalTime &&
    wellsiteTime < 16
  ) {
    swal('Time at destination must be more than 15 minutes', '', 'error');
  } else {
    return true;
  }
  return false;
};

const submitModify = (updateJobPlans, callbackFn) => (dispatch, getState) => {
  const request = () => ({ type: 'ORDER_ACTIONS_MODIFY_REQUEST' });
  const success = () => ({ type: 'ORDER_ACTIONS_MODIFY_SUCCESS' });
  dispatch(request());
  const state = getState();
  const data = { ...state.orderActions.modify.input };
  data.lastUpdatedAt = data.updatedAt;
  const { status } = state.orderActions.order;

  const timesValue = [
    'orderAcceptedAt',
    'loadArrivalTime',
    'loadDepartureTime',
    'wellArrivalTime',
    'wellDepartureTime',
  ];
  if (data.stageArrivalTime && data.stageArrivalTime !== 'Invalid date') {
    timesValue.push('stageArrivalTime');
  } else {
    delete data.stageArrivalTime;
  }

  if (data.stageDepartureTime && data.stageDepartureTime !== 'Invalid date') {
    timesValue.push('stageDepartureTime');
  } else {
    delete data.stageDepartureTime;
  }

  for (let i = 0; i < timesValue.length; i += 1) {
    data[timesValue[i]] = moment(data[timesValue[i]], [
      'YYYY-MM-DD hh:mm A',
      'MM-DD-YYYY hh:mm A',
      'D MMM YYYY H:mm',
    ])
      .utc()
      .format('YYYY/MM/DD hh:mm A');
    if (
      data.shouldComplete &&
      (!data[timesValue[i]] || data[timesValue[i]] === 'Invalid date')
    ) {
      swal('Invalid date', '', 'error');
      dispatch(request());
      return;
    }
  }
  data.sandTicketNo = String(data.sandTicketNo);
  // Convert from boolean to number
  data.truckDown = data.truckDown ? 1 : 0;

  Object.keys(data).forEach(item => {
    if (data[item] === '' || data[item] === 'Invalid date') {
      delete data[item];
    }
  });

  data.status = status;
  data.mileage = Math.floor(data.mileage).toString();
  if (checkTime(data)) {
    order
      .modify(data)
      .then(msg => {
        dispatch(success());
        if (
          (Number(msg.data.data.sandDemurrage.price) !== 0 &&
            !msg.data.data.sandSiteDemurrageReasonId) ||
          (Number(msg.data.data.wellDemurrage.price) !== 0 &&
            !msg.data.data.wellSiteDemurrageReasonId)
        ) {
          dispatch(initDemurrageReason(msg.data.data));
          dispatch(turnModalDemurrageReason(true));
        } else {
          if (pageLocation === 'order-details') {
            dispatch(initAction.init(data.orderId));
          } else {
            dispatch(orderListAction.refreshOrder(data.orderId));
          }
          swal('Success!', '', 'success');
        }
        if (updateJobPlans) {
          dispatch(jobPlansActions.getBillableOrders(updateJobPlans))
            .then(() => {
              dispatch(jobPlansActions.getOrderTickets(data.orderId));
            })
            .then(() => {
              if (callbackFn) {
                callbackFn()
              }
            })
            .catch(error => {
              swal(
                error?.response?.data?.data?.message ||
                error?.response?.data?.message ||
                error?.data?.message ||
                'Error getting Job Plans billable orders.',
                '',
                'error',
              );
            });
        }
      })
      .catch(error => {
        swal(
          error?.response?.data?.data?.message ||
          error?.response?.data?.message ||
          error?.data?.message ||
          'Error modifying order.',
          '',
          'error',
        );
        dispatch(request());
      });
  } else {
    dispatch(request());
  }
};

const clearStateModify = () => ({ type: 'ORDER_ACTIONS_MODIFY_CLEAR_STATE' });

const setInputValueModify = (value, name) => ({
  type: 'ORDER_ACTIONS_MODIFY_SET_INPUT_VALUE',
  value,
  name,
});

export default {
  openModal,
  releaseDriver,
  callToWell,
  deleteOrder,
  reviveOrder,
  // divert
  initDivert,
  setInputValueDivert,
  submitDivert,
  getJobPos,
  clearDivertData,
  // requeue
  initRequeue,
  setInputValueRequeue,
  submitRequeue,
  clearStateRequeue,
  // transfer
  initTransfer,
  setInputValueTransfer,
  submitTransfer,
  // upload ST
  selectFileST,
  setInputValueST,
  initST,
  saveST,
  clearStateST,
  // modify
  initModify,
  setInputValueModify,
  submitModify,
  clearStateModify,
  // demurrage reason
  initDemurrageReason,
  setInputValueDemurrageReason,
  turnModalDemurrageReason,
  saveDemurrageReason,
};
