import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Input from 'components/shared/Input';

const Billing = ({ setStep, setBillValue, addCustomer, closeAdd }) => {
  const [saveDisabled, setSaveDisabled] = useState(true);
  const {
    input: {
      billingAddress: {
        city,
        address1,
        phoneNumber,
        email,
        state,
        address2,
        zip,
      },
    },
  } = addCustomer;

  const determineAddDisabled = () => {
    let disabled = false;
    if (
      city === '' ||
      address1 === '' ||
      phoneNumber === '' ||
      email === '' ||
      state === '' ||
      zip === ''
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };
  useEffect(() => {
    determineAddDisabled();
  }, [addCustomer]);

  return (
    <form onSubmit={e => setStep(4, e)} className="wizard-big wizard">
      <Modal.Body className="form-group">
        <div className="step2">
          <h1 id="form-h-1" className="title">
            Billing Address
          </h1>
          <fieldset id="form-p-1" role="tabpanel" className="body">
            <Row>
              <div className="form-group col-lg-6 col-md-6 has-feedback">
                <Input
                  onChange={e => setBillValue(e, 'address1')}
                  value={address1}
                  label="Address 1"
                  required
                  testSelector="admin-customers_billing_address-1_input"
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 has-feedback">
                <Input
                  onChange={e => setBillValue(e, 'address2')}
                  value={address2}
                  label="Address 2"
                  testSelector="admin-customers_billing_address-2_input"
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 has-feedback">
                <Input
                  onChange={e => setBillValue(e, 'city')}
                  value={city}
                  label="City"
                  required
                  testSelector="admin-customers_billing_city_input"
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 has-feedback">
                <Input
                  onChange={e => setBillValue(e, 'state')}
                  value={state}
                  label="State"
                  required
                  testSelector="admin-customers_billing_state_input"
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 has-feedback">
                <Input
                  type="tel"
                  required
                  value={phoneNumber}
                  onChange={e => setBillValue(e, 'phoneNumber')}
                  label="Phone number"
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 has-feedback">
                <Input
                  onChange={e => setBillValue(e, 'zip')}
                  value={zip}
                  label="ZIP"
                  required
                  testSelector="admin-customers_billing_zip_input"
                />
              </div>
              <div className="form-group col-lg-6 col-md-6 has-feedback">
                <Input
                  onChange={e => setBillValue(e, 'email')}
                  value={email}
                  type="email"
                  label="Email"
                  testSelector="admin-customers_billing_email_input"
                  required
                />
              </div>
            </Row>
          </fieldset>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={closeAdd}
          colour="white"
          children="Close"
          testSelector="admin-customers_billing_close_btn"
        />
        <Button
          onClick={() => setStep(2)}
          children="Previous"
          testSelector="admin-customers_billing_prev_btn"
        />
        <Button
          type="submit"
          disabled={saveDisabled}
          children="Next"
          testSelector="admin-customers_billing_next_btn"
        />
      </Modal.Footer>
    </form>
  );
};

export default Billing;
