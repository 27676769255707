import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/ManageCustomers';
import { Modal, Row } from 'react-bootstrap';
import InputMask from 'react-input-mask';
import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import Select from 'components/shared/Select';
import targetValue, { targetValueNumber } from 'utils/targetValue';
import MapAddWellsite from '../MapAddWellsite';

// TODO: Combine with other wellsite modals
const WellSiteModal = ({
  wellSite,
  setValue,
  setValueNumber,
  submitFunction,
  modalTitle,
  customers,
  showModal,
  closeModal,
  action,
  clearModalWellSite,
}) => {
  const [saveDisabled, setSaveDisabled] = useState(true);

  const {
    name,
    contactPhone,
    address,
    city,
    state,
    latitude,
    longitude,
    specialInstructions,
    customerId,
    geofenceRange,
    email,
  } = wellSite;

  useEffect(() => () => clearModalWellSite(), []);

  const customerList = customers.map(item => ({
    value: item.id,
    label: `${item.id} | ${item.name}`,
  }));

  const determineSaveDisabled = () => {
    let disabled = false;
    if (
      !name ||
      !customerId ||
      !contactPhone ||
      !address ||
      !city ||
      !state ||
      !Number(latitude) ||
      latitude > 90 ||
      latitude < -90 ||
      !Number(longitude) ||
      longitude > 180 ||
      longitude < -180 ||
      !specialInstructions ||
      !Number(geofenceRange) ||
      !email
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };

  useEffect(() => {
    determineSaveDisabled();
  }, [wellSite]);

  return (
    <div className="inmodal">
      <Modal className="modal-container" show={showModal} onHide={closeModal}>
        <form
          onSubmit={e => submitFunction(e, action, closeModal)}
          className="m-t"
          role="form">
          <Modal.Header>
            <Modal.Title as="h4">{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body className="form-group">
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'name')}
                  value={name}
                  label="Name"
                  required
                  testSelector="admin-customers_wellsite-modal_name_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Select
                  required
                  options={customerList}
                  onChange={item => setValue(item.value, 'customerId')}
                  value={customerList.find(item => item.value === customerId)}
                  label="Choose Customer"
                  isDisabled={action === 'edit'}
                  testSelector="admin-customers_wellsite_customer_select"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <InputMask
                  mask="999999999999"
                  maskChar={null}
                  value={contactPhone}
                  onChange={e => setValue(e, 'contactPhone')}
                  label="Contact number">
                  {inputProps => (
                    <Input
                      {...inputProps}
                      type="text"
                      required
                      testSelector="admin-customers_wellsite-modal_phone_input"
                    />
                  )}
                </InputMask>
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'address')}
                  value={address}
                  label="Address"
                  id="address"
                  required
                  testSelector="admin-customers_wellsite-modal_address_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'city')}
                  value={city}
                  label="City"
                  required
                  testSelector="admin-customers_wellsite-modal_city_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValue(e, 'state')}
                  value={state}
                  label="State"
                  required
                  testSelector="admin-customers_wellsite-modal_state_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValueNumber(e, 'latitude')}
                  value={latitude}
                  label="Latitude"
                  min={-90}
                  max={90}
                  type="number"
                  required
                  testSelector="admin-customers_wellsite-modal_lat_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Input
                  onChange={e => setValueNumber(e, 'longitude')}
                  value={longitude}
                  label="Longitude"
                  min={-180}
                  max={180}
                  type="number"
                  required
                  testSelector="admin-customers_wellsite-modal_lng_input"
                />
              </div>
            </Row>
            <Row>
              <div className="form-group has-feedback col-md-6">
                <Input
                  type="number"
                  onChange={e => setValueNumber(e, 'geofenceRange')}
                  value={geofenceRange}
                  label="Geofence range in Miles"
                  required
                  min={0.1}
                  max={30}
                  step={0.1}
                  testSelector="admin-customers_wellsite-modal_geofence-range_input"
                />
                <Input
                  noMargin
                  className="input__top-margin"
                  type="email"
                  onChange={e => setValue(e, 'email')}
                  value={email || ''}
                  label="Email"
                  required
                  testSelector="admin-customers_wellsite-modal_email_input"
                />
              </div>
              <div className="form-group has-feedback col-md-6">
                <Textarea
                  onChange={e => setValue(e, 'specialInstructions')}
                  value={specialInstructions}
                  label="Location Special Instructions"
                  required
                  rows="3"
                  testSelector="admin-customers_wellsite-modal_instructions_input"
                />
              </div>
            </Row>
            <Row>
              <div className="col-md-12">
                <MapAddWellsite action={action} />
              </div>
            </Row>
          </Modal.Body>

          <Modal.Footer>
            <Button
              onClick={closeModal}
              colour="white"
              children="Close"
              testSelector="admin-customers_wellsite-modal_close_btn"
            />
            <Button
              type="submit"
              children="Save"
              disabled={saveDisabled}
              testSelector="admin-customers_wellsite-modal_save_btn"
            />
          </Modal.Footer>
        </form>
      </Modal>
    </div>
  );
};

const mapStateToProps = state => ({
  wellSite: state.manageCustomers.modalWellSite,
});

const mapDispatchToProps = dispatch => ({
  setValueNumber: (value, name) =>
    dispatch(actions.setValue(targetValueNumber(value), name)),
  setValue: (value, name) =>
    dispatch(actions.setValue(targetValue(value), name)),
  setCustomer: name => dispatch(actions.setCustomer(name)),
  geocodeLocation: () => dispatch(actions.geocodeLocation()),
  clearModalWellSite: () => dispatch(actions.clearModalWellSite()),
});

export const WellSiteModalTest = WellSiteModal; // for Tests

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WellSiteModal);
