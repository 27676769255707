import React, { useMemo, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import Select from 'components/shared/Select';
import moment from 'moment';
import Button from 'components/shared/Button';
import Input, { Textarea } from 'components/shared/Input';
import multiSelectOptions from 'utils/multiSelectOptions';
import DatePicker from 'components/shared/DatePicker';
import { useTags } from 'api/v2/tags';
import { isDateFormatValid } from 'utils/datePickersFunctions';

const JobDetailsTab = ({
  closeAddJobModal,
  setModalValue,
  addJob,
  addJobInitData,
  setStep,
  setStartDate,
  setEndDate,
  selectData,
}) => {
  const {
    startDate,
    endDate,
    numberOfStages,
    pumpTime,
    certificates,
    sandTicketDesign,
    directions,
    wirelineTime,
    totalWeightPerJob,
    targetStagesPerDay,
    tags,
  } = addJob;

  const { data: jobTags } = useTags({ type: 2 });
  const [dateAlert, setDateAlert] = useState(false);

  const tagOptions = useMemo(
    () =>
      (jobTags || []).map(tag => ({
        label: tag.name,
        value: tag.id,
      })),
    [jobTags],
  );

  const { certificatesList } = addJobInitData;
  const sandTicketTypes = addJobInitData.sandTicketTypes.filter(
    e => e.sandTicketTypeId !== 6,
  );

  return (
    <form className="wizard-big wizard" onSubmit={() => setStep(3)}>
      <Modal.Body>
        <h4 className="title" style={{ paddingTop: 20, paddingLeft: 10 }}>
          Job Details
        </h4>
        {dateAlert && (
          <div className="alert-input-msg">
            Time format should be &quot;MM/DD/YY hh:mm AM/PM&quot;
          </div>
        )}
        <fieldset id="form-p-1" role="tabpanel" className="body">
          <Row style={{ marginLeft: -5, marginRight: -5 }}>
            <Col md={6} className="form-group has-feedback">
              <DatePicker
                label="Start Date"
                required
                value={startDate}
                timeFormat="hh:mm A"
                onChange={e => {
                  isDateFormatValid(e, setDateAlert);
                  setStartDate(e);
                }}
                placeholderText="Start Date"
                data-testid="job-details_add-job_start-date_dp"
                id="job-details_add-job_start-date_dp"
                name="job-details_add-job_start-date_dp"
              />
              {(moment().isSameOrAfter(startDate) ||
                typeof startDate !== 'object') && (
                <small style={{ color: 'rgba(239, 61, 61, 0.483)' }}>
                  * Incorrect start date
                </small>
              )}
            </Col>
            <Col md={6} className="form-group has-feedback">
              <DatePicker
                label="End Date"
                required
                value={endDate}
                timeFormat="hh:mm A"
                onChange={e => {
                  isDateFormatValid(e, setDateAlert);
                  setEndDate(e);
                }}
                className="form-input__calendar"
                placeholderText="End Date"
                data-testid="job-details_add-job_end-date_dp"
                id="job-details_add-job_end-date_dp"
                name="job-details_add-job_end-date_dp"
              />
              {(moment().isSameOrAfter(endDate) ||
                typeof endDate !== 'object' ||
                (typeof startDate === 'object' &&
                  startDate.isSameOrAfter(endDate))) && (
                <small style={{ color: 'rgba(239, 61, 61, 0.483)' }}>
                  * Incorrect end date
                </small>
              )}
            </Col>
            <Col md={6} className="form-group has-feedback">
              <Input
                type="number"
                onChange={e => setModalValue(e, 'numberOfStages')}
                value={numberOfStages}
                label="Number of stages"
                required
                min="1"
                testSelector="jobs_add_job-details_stages-number_input"
              />
            </Col>
            <Col md={6} className="form-group has-feedback">
              <Select
                placeholder="Certificate"
                isMulti
                closeMenuOnSelect={false}
                onChange={selected => selectData(selected, 'certificates')}
                options={multiSelectOptions(certificatesList, 'id', 'name')}
                value={certificates}
                required
                testSelector="jobs_add_job-details_certificates_select"
              />
              {certificates.length === 0 && (
                <small style={{ color: 'rgba(239, 61, 61, 0.483' }}>
                  * Need to select certificate
                </small>
              )}
            </Col>
            <Col md={6} className="form-group has-feedback">
              <Input
                type="number"
                onChange={e => setModalValue(e, 'pumpTime')}
                value={pumpTime}
                label="Pump time (hours)"
                required
                min="1"
                max="100"
                testSelector="jobs_add_job-details_pump-time_input"
              />
            </Col>
            <Col md={6} className="form-group has-feedback">
              <Select
                isMulti
                closeMenuOnSelect={false}
                onChange={selected => selectData(selected, 'sandTicketDesign')}
                options={multiSelectOptions(
                  sandTicketTypes,
                  'sandTicketTypeId',
                  'name',
                )}
                placeholder="Ticket types"
                value={sandTicketDesign}
                required
                testSelector="jobs_add_job-details_ticket-type_select"
              />
              {sandTicketDesign.length === 0 && (
                <small style={{ color: 'rgba(239, 61, 61, 0.483)' }}>
                  * Need to select ticket type
                </small>
              )}
            </Col>
            <Col md={6} className="form-group has-feedback">
              <Input
                type="number"
                onChange={e => setModalValue(e, 'wirelineTime')}
                value={wirelineTime}
                label="Wireline time (hours)"
                required
                min="1"
                max="100"
                testSelector="jobs_add_job-details_wireline-time_input"
              />
            </Col>
            <Col md={6} className="form-group has-feedback">
              <Input
                type="number"
                onChange={e => setModalValue(e, 'totalWeightPerJob')}
                value={totalWeightPerJob}
                label="Total Quantity Per Job"
                min="1"
                max="2000000000"
                testSelector="jobs_add_job-details_total-weight-per-job_input"
              />
            </Col>
            <Col md={6} className="form-group has-feedback">
              <Input
                type="number"
                onChange={e => setModalValue(e, 'targetStagesPerDay')}
                value={targetStagesPerDay}
                label="Target Stages Per Day"
                min="1"
                testSelector="jobs_add_job-details_target-stages-per-day_input"
              />
            </Col>
            <Col md={6} className="form-group has-feedback">
              <Select
                placeholder="Tag (Billing)"
                // isMulti
                onChange={e => setModalValue([e.value], 'tags')}
                options={tagOptions}
                value={tagOptions.find(i => i.value === (tags || [])[0])}
                testSelector="jobs_add_job-details_tags_input"
              />
            </Col>
            <Col md={12} className="form-group has-feedback">
              <Textarea
                rows="3"
                label="Directions"
                onChange={e => setModalValue(e, 'directions')}
                value={directions}
                required
                testSelector="jobs_add_details_directions_input"
              />
            </Col>
          </Row>
        </fieldset>
      </Modal.Body>

      <Modal.Footer>
        <Button
          onClick={closeAddJobModal}
          colour="white"
          testSelector="jobs-list_add-modal_job-details_close_btn">
          Close
        </Button>
        <Button
          onClick={() => setStep(1)}
          testSelector="jobs-list_add-modal_job-details_previous_btn">
          Previous
        </Button>
        <Button
          type="submit"
          disabled={
            dateAlert ||
            moment().isSameOrAfter(startDate) ||
            typeof startDate !== 'object' ||
            moment().isSameOrAfter(endDate) ||
            (typeof startDate === 'object' &&
              startDate.isSameOrAfter(endDate)) ||
            typeof endDate !== 'object'
          }
          testSelector="jobs-list_add-modal_job-details_next_btn">
          Next
        </Button>
      </Modal.Footer>
    </form>
  );
};
export default JobDetailsTab;
