import React, { useEffect, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';

import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

import { useStorageTypes } from 'api/v2/storage-types';
import { useCommodities, useCustomerCommodities } from 'api/v2/commodities';

const OnsiteStorageType = ({
  filteredCommodityOptions,
  index,
  item,
  setArrayValue,
  deleteArrayValue,
  setOnsiteStorageType,
  customerId
}) => {
  const { data: storageTypes } = useStorageTypes();
  const typeOptions = useMemo(
    () =>
      storageTypes.map(data => ({
        ...data,
        value: data.id,
        label: data.name,
      })),
    [storageTypes],
  );

  const { data: commodities } = useCustomerCommodities(customerId);

  const commodityOptions = useMemo(
    () =>
      (commodities || []).map(data => ({
        ...data,
        value: data.id,
        label: data.name,
      })),
    [commodities],
  );
  useEffect(() => {
    if (item.commodityId && !item.commodity) {
      const commodity = commodityOptions.find(
        option => option.id === item.commodityId,
      );
      setArrayValue(commodity, index, 'commodity', 'storageConfig');
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [index, item]);

  return (
    <Row key={`storage-${index}`}>
      <Col lg={3} md={4} className="form-group has-feedback no-padding">
        <Select
          label="Storage Type"
          onChange={e => setOnsiteStorageType(e, index)}
          value={typeOptions.find(k => k.value === item.type)}
          options={typeOptions}
          required
          testSelector="jobs_add_onsite-storage_sane-type_select"
        />
      </Col>
      <Col md={3} className="form-group has-feedback">
        <Input
          type="text"
          onChange={e => setArrayValue(e, index, 'name', 'storageConfig')}
          value={item.name || ''}
          label="Name"
          required
          testSelector="jobs_add_storage_name_input"
        />
      </Col>
      {!!item.type &&
        (item.isLive ? (
          <Col md={5} className="form-group has-feedback no-padding">
            <Input
              onChange={e =>
                setArrayValue(
                  e.target.value.replace(/[^\w]|_/g, ''),
                  index,
                  'storageId',
                  'storageConfig',
                )
              }
              value={item && item.storageId ? item.storageId.trim() : ''}
              label="Storage ID"
              required
              min="0"
              testSelector="jobs_add_onsite-storageId_input"
            />
          </Col>
        ) : (
          <>
            <Col md={2} className="form-group has-feedback no-padding">
              <Input
                type="number"
                onChange={e =>
                  setArrayValue(e, index, 'capacity', 'storageConfig')
                }
                value={item.capacity || ''}
                label="Capacity"
                required
                min="0"
                testSelector="jobs_add_storage_capacity_input"
              />
            </Col>
            <Col
              md={3}
              className="form-group has-feedback"
              style={{ paddingRight: 0 }}>
              <Select
                label="Commodity"
                onChange={e => {
                  setArrayValue(e.value, index, 'commodityId', 'storageConfig');
                  setArrayValue(e, index, 'commodity', 'storageConfig');
                }}
                value={item.commodity}
                options={filteredCommodityOptions ?? commodityOptions}
                required
                testSelector="jobs_add_storage_commodity_input"
              />
              {!!item.commodity && (
                <div style={{ paddingLeft: 10 }}>
                  <b>UOM:</b> {item?.commodityUOMName || item?.commodity?.unitOfMeasureName}
                </div>
              )}
            </Col>
          </>
        ))}
      <div className="form-group has-feedback col-md-1">
        <Button
          theme="square"
          colour="white"
          testSelector="jobs-list_onsite-storage-type_delete_btn"
          onClick={() => deleteArrayValue(index, 'storageConfig')}>
          <Icon icon="trash" />
        </Button>
      </div>
    </Row>
  );
};
export default OnsiteStorageType;
