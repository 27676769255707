import { useJobDetails } from 'api/v2/jobs';
import { useState, useEffect, useMemo } from 'react';

import { useLocation } from 'react-router-dom';

export const useAudio = url => {
  const [audio] = useState(new Audio(url));
  const [playing, setPlaying] = useState(false);

  const toggle = () => setPlaying(!playing);

  useEffect(() => {
    if (playing) {
      audio.play();
    } else {
      audio.pause();
    }
  }, [playing, audio]);

  useEffect(() => {
    audio.addEventListener('ended', () => setPlaying(false));
    return () => {
      audio.removeEventListener('ended', () => setPlaying(false));
    };
  }, [audio]);

  return [playing, toggle];
};

export function useSearchParams() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

/**
 *
 * @param {*} value
 * @param {number} delay milliseconds
 * @returns
 */
export const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

export const useJobUnits = jobId => {
  const {
    data: { details },
  } = useJobDetails({ jobId });

  const determineUnit = unit => {
    switch (unit) {
      case 1:
        return 'lbs.';
      case 2:
        return 'gal.';
      case 3:
        return 'bbl.';
      default:
        return 'lbs.';
    }
  };

  return determineUnit(details.commodityUnits);
};
