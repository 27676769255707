import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/Export';
import { Tabs, Tab } from 'react-bootstrap';
import Authorize from 'components/common/Authorize';

import Header from 'components/common/Header';
import Loader from 'components/shared/Loader';
import Orders from './Orders';
import Templates from './Templates';
import AddTemplate from './Templates/AddTemplate';
import PacketInvoice from './Orders/PacketInvoice';
import Preview from './Orders/Preview';

const ExportPage = ({
  templates,
  openModal,
  openModalPreview,
  isLoadedCustomers,
  isLoadedJobs,
  isLoadedCarriers,
  isLoadedConfig,
  isLoadedTemplates,
  init,
  getTemplates,
  clearModalAddTemplate,
  checkAllFields,
}) => {
  const [showAddTemplate, turnAddTemplate] = useState(false);
  const [keyTab, changeKeyTab] = useState(1);

  useEffect(() => {
    init();
  }, []);

  if (
    !(
      isLoadedCustomers &&
      isLoadedJobs &&
      isLoadedCarriers &&
      isLoadedConfig &&
      isLoadedTemplates
    )
  ) {
    <Loader />;
  }

  return (
    <div>
      <Header title="Orders" />

      <Authorize
        abilityPermissions={[{ resource: 'Exports', permissions: ['read'] }]}>
        <Tabs
          activeKey={keyTab}
          onSelect={e => changeKeyTab(e)}
          id="controlled-tab-example"
          style={{ paddingTop: '20px' }}>
          <Tab eventKey={1} title="Orders">
            <Authorize
              abilityPermissions={[
                { resource: 'Orders', permissions: ['read'] },
              ]}>
              <Orders />
            </Authorize>
          </Tab>
          <Tab eventKey={2} title="Templates">
            <Templates
              templates={templates}
              openAddTemplate={() => turnAddTemplate(true)}
            />
          </Tab>
        </Tabs>
      </Authorize>

      {showAddTemplate && (
        <AddTemplate
          showAddTemplate={showAddTemplate}
          closeAddTemplate={() => {
            turnAddTemplate(false);
            getTemplates();
            clearModalAddTemplate();
            checkAllFields();
          }}
        />
      )}
      {openModal && <PacketInvoice />}
      {openModalPreview && <Preview />}
    </div>
  );
};

const mapStateToProps = state => ({
  isLoadedCustomers: state.resourceReducer.customers.isLoadedCustomers,
  isLoadedJobs: state.resourceReducer.jobs.isLoadedJobs,
  isLoadedCarriers: state.resourceReducer.carriers.isLoadedCarriers,
  isLoadedConfig: state.exportPage.exportConfig.isLoadedConfig,
  isLoadedTemplates: state.exportPage.templates.isLoadedTemplates,
  openModal: state.exportPage.modalPacketInvoice.openModal,
  openModalPreview: state.exportPage.modalPreview.openModal,
});

const mapDispatchToProps = dispatch => ({
  init: () => dispatch(actions.init()),
  clearModalAddTemplate: () => dispatch(actions.clearModalAddTemplate()),
  getTemplates: () => dispatch(actions.getTemplates()),
  checkAllFields: () => dispatch(actions.checkAllFields()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExportPage);
