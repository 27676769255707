import React, { useEffect, useState } from 'react';
import { Modal, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import targetValue from 'utils/targetValue';
import actions from 'store/actions/OrderActions';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Select from 'components/shared/Select';
import { filterOptions } from 'utils/selectUtils';
import { reasonsFormatted } from './helpers';
import { filterReasons } from './selector';

const DemurrageReason = ({
  getReasons,
  openModal,
  turnModal,
  setInputValue,
  save,
  dataDemurrage,
  reasons,
  input,
}) => {
  const [reasonsList, setReasonsList] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);

  useEffect(() => {
    getReasons();
  }, []);

  useEffect(() => {
    if (reasons.length) {
      const list = filterOptions(
        reasonsFormatted(reasons),
        null,
        null,
        null,
        'label',
      );
      setReasonsList(list);
    }
  }, [reasons]);

  const {
    sandSiteDemurrageReasonId,
    sandDemurrage,
    wellDemurrage,
    wellSiteDemurrageReasonId,
  } = dataDemurrage;
  const { wellsiteReason, sandSiteReason } = input;

  const determineSaveDisabled = () => {
    let disabled = false;

    if (
      (sandDemurrage.price != 0 &&
        !sandSiteDemurrageReasonId &&
        (!sandSiteReason || sandSiteReason.length === 0)) ||
      (wellDemurrage.price != 0 &&
        !wellSiteDemurrageReasonId &&
        (!wellsiteReason || wellsiteReason.length === 0))
    ) {
      disabled = true;
    }
    setSaveDisabled(disabled);
  };

  useEffect(() => {
    determineSaveDisabled();
  }, [wellsiteReason, sandSiteReason]);

  return (
    <Modal
      className="modal-container"
      show={openModal}
      onHide={() => turnModal(true)}>
      <Modal.Header>
        <h3 className="modal-title">Reason for demurrage</h3>
      </Modal.Header>
      <form onSubmit={save}>
        <Modal.Body>
          {dataDemurrage ? (
            <Row>
              {sandDemurrage.price != 0 && !sandSiteDemurrageReasonId && (
                <div className="form-group has-feedback col-md-6">
                  <label className="small font-noraml">Origin reason</label>
                  <Select
                    placeholder="Please Select Reason"
                    options={reasonsList}
                    onChange={item =>
                      setInputValue(item.value, 'sandSiteReason')
                    }
                    value={reasonsList.find(
                      item => item.value === sandSiteReason,
                    )}
                    required
                    isOptionDisabled={option => option.disabled}
                    testSelector="order-actions_modify_demurr-reason_sandsite-reason_select"
                  />
                </div>
              )}
              {wellDemurrage.price != 0 && !wellSiteDemurrageReasonId && (
                <div className="form-group has-feedback col-md-6">
                  <label className="small font-noraml">
                    Destination reason
                  </label>
                  <Select
                    placeholder="Please Select Reason"
                    options={reasonsList}
                    onChange={item =>
                      setInputValue(item.value, 'wellsiteReason')
                    }
                    value={reasonsList.find(
                      item => item.value === wellsiteReason,
                    )}
                    required
                    isOptionDisabled={option => option.disabled}
                    testSelector="order-actions_modify_demurr-reason_wellsite-reason_select"
                  />
                </div>
              )}
            </Row>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            colour="white"
            onClick={() => turnModal()}
            testSelector="order-actions_modify_demurrage-reason_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            disabled={saveDisabled}
            testSelector="order-actions_modify_demurrage-reason_save_btn">
            Save
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  input: state.orderActions.demurrageReason.input,
  dataDemurrage: state.orderActions.demurrageReason.dataDemurrage,
  openModal: state.orderActions.demurrageReason.openModal,
  order: state.orderActions.order,
  reasons: filterReasons(state),
});

const mapDispatchToProps = dispatch => ({
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueDemurrageReason(targetValue(e), name)),
  save: e => {
    e.preventDefault();
    dispatch(actions.saveDemurrageReason());
  },
  getReasons: () => dispatch(resourceActions.getReasons()),
  turnModal: reloadPage =>
    dispatch(actions.turnModalDemurrageReason(reloadPage)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DemurrageReason);
