import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import { getJobComments, sendJobComment } from './queries';

/**
 *
 * @param {*} params
 * @param {import('@tanstack/react-query').DefinedInitialDataOptions} options
 * @returns
 */
export const useJobComments = ({ jobId }) =>
  useQuery({
    queryKey: ['jobs', jobId, 'comments'],
    queryFn: () => getJobComments(jobId),
    enabled: !!jobId,
    placeholderData: [],
  });

export const useSendJobComment = ({ jobId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: comment => sendJobComment({ jobId, comment }),
    onSuccess: () => {
      queryClient.invalidateQueries(['jobs-comments', jobId]);
    },
    onError: error => {
      if (error.isAxiosError) {
        // Fail silently if it is a permission error
        if (error.response.status !== 400) {
          swal(`${error.response.data.message} from events`, '', 'error');
        }
      }
    },
  });
};
