import React from 'react';
import Content from './Content';
import Header from './Header';

export default props => {
  const {
    info: { orders },
  } = props;
  return orders.map(order => (
    <div className="ibox ibox-order" key={`order-${order.order_id}`}>
      <Header {...{ ...props, order }} />
      <Content {...{ ...props, order }} />
    </div>
  ));
};
