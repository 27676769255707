import { customer } from 'services/customer/service';
import { wellSite } from 'services/wellSite/service';
import { resourceActions } from 'store/modules/resourceReducer/actions';
import containersActions from 'store/actions/Equipments';
import swal from 'bootstrap-sweetalert';

const clear = () => ({ type: 'CUSTOMER_DETAILS_CLEAR' });
const save = (name, value) => ({
  type: 'CUSTOMER_DETAILS_SAVE_INIT_DATA',
  name,
  value,
});
const saveVoided = (name, value) => ({
  type: 'CUSTOMER_DETAILS_SAVE_VOIDED_DATA',
  name,
  value,
});

const turnPaymentStatus = value => ({
  type: 'CUSTOMER_DETAILS_PAYMENTS_STATUS',
  value,
});
const turnProfileStatus = value => ({
  type: 'CUSTOMER_DETAILS_PROFILES_STATUS',
  value,
});

const init = id => dispatch => {
  dispatch(clear());
  dispatch({ type: 'CUSTOMER_DETAILS_START' });
  customer
    .details(id)
    .then(msg => {
      dispatch(save('details', msg.data.data));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
  customer.billing.payments
    .get(id, 1)
    .then(msg => dispatch(save('payments', msg.data.data)))
    .catch(error => swal(error.response.data.message, '', 'error'));
  customer.billing.profiles
    .get(id, 1)
    .then(msg => dispatch(save('profiles', msg.data.data)))
    .catch(error => swal(error.response.data.message, '', 'error'));
  customer.jobs
    .get(id)
    .then(msg => dispatch(save('jobs', msg.data.data)))
    .catch(error => swal(error.response.data.message, '', 'error'));
  customer.notarizations
    .get(id)
    .then(msg =>
      dispatch(save('notarizations', msg.data.data.notarizationSettings)),
    )
    .catch(error => swal(error.response.data.message, '', 'error'));
  wellSite
    .getWellsitesForCustomer(id)
    .then(msg => {
      dispatch(save('customerWellsites', msg.data.data));
    })
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const getPayments = (id, status) => dispatch => {
  customer.billing.payments
    .get(id, status)
    .then(msg => dispatch(saveVoided('payments', msg.data.data)))
    .catch(error => swal(error.response.data.message, '', 'error'));
};
const getProfiles = (id, status) => dispatch => {
  customer.billing.profiles
    .get(id, status)
    .then(msg => dispatch(saveVoided('profiles', msg.data.data)))
    .catch(error => swal(error.response.data.message, '', 'error'));
};

const initBillingTerms = id => dispatch => {
  dispatch(clear());
  dispatch({ type: 'CUSTOMER_DETAILS_START' });

  customer
    .details(id)
    .then(msg => {
      const details = msg.data.data;

      let terms = details.billingTerms;
      const billableOperation = details.billableOperationTypes;
      billableOperation.forEach(element => {
        element.billableStatus = false;
      });

      terms.bulkInvoiceEnabled = Boolean(false);
      terms.csvInvoiceEnabled = Boolean(false);
      terms.nonBillable = Boolean(false);
      terms.packetInvoiceEnabled = Boolean(false);
      terms = {
        ...terms,
        approvalsRequired: details.approvalsRequired,
        billableOperationTypeStatus: billableOperation,
      };

      dispatch({
        type: 'CUSTOMER_DETAILS_INIT_BILLING_TERMS',
        terms,
      });
    })

    .catch(error => swal(error.response.data.message, '', 'error'));
};

const setIdForContainersDetails = () => (dispatch, getState) => {
  const state = getState();
  const {
    customerDetails: {
      customer: {
        details: { id },
      },
    },
  } = state;
  dispatch(containersActions.setCustomerId(id));
  dispatch(resourceActions.getTrailers({ type: 2 }));
};

const getScadaReport = () => async (dispatch, getState) => {
  const state = getState();
  const {
    customerDetails: {
      customer: {
        details: { id },
      },
    },
  } = state;
  const scadaData = await customer.scada.get(id).catch(error => {
    swal(error.response.data.message, '', 'error');
  });
  const hiddenElement = document.createElement('a');
  const blob = new Blob([scadaData.data], {
    type: 'application/csv;charset=utf-8',
  });
  hiddenElement.href = window.URL.createObjectURL(blob);
  hiddenElement.download = 'scada.csv';
  hiddenElement.click();
};

const setStatus = value => ({
  type: 'CUSTOMER_DETAILS_JOB_LIST_SET_FILTER_STATUS',
  value,
});

const locationSearch = locStr => (dispatch, getState) => {
  const state = getState();
  const setFilteredLocations = locations => ({
    type: 'CUSTOMER_DETAILS_LOCATIONS_SET_FILTERED_LOCATIONS',
    locations,
  });
  const wells = state.customerDetails.customer.customerWellsites;
  dispatch(
    setFilteredLocations(
      wells.filter(w => w.name.toLowerCase().includes(locStr.toLowerCase())),
    ),
  );
};

export default {
  save,
  init,
  clear,
  setIdForContainersDetails,
  getScadaReport,
  setStatus,
  initBillingTerms,
  locationSearch,
  getPayments,
  getProfiles,
  turnPaymentStatus,
  turnProfileStatus,
};
