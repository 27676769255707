import React from 'react';
import Badge from 'components/shared/Badge';
import ordersReviewStatus from 'components/globalInfo/ordersReviewStatus';
import { orderStatus } from 'components/globalInfo/orderStatus';
import { billingStatus } from 'components/globalInfo/billingStatus';

const TagsList = ({ data = null, equipmentRequired = null }) => (

    <div className="order-info__tags-list">
      <Badge type="green" children={orderStatus[data.status].name} />
      {data.billing_status < 20
        ? <Badge
          type={String(ordersReviewStatus[String(data.review_status)].btnColor)}
          children={String(ordersReviewStatus[String(data.review_status)].title)}
        />
        : <Badge
          type={String(billingStatus[String(data.billing_status)].class)}
          children={String(billingStatus[String(data.billing_status)].text)}
        />
      }
      {!!data.equipment && (
        <Badge>
          {equipmentRequired.map(
            item => item.id === data.equipment && item.title,
          )}
        </Badge>
      )}
      {!!data.hasMissingSandTickets && (
        <Badge type="red" children="Missing ticket" />
      )}
      {!!data.isTransferred && (
        <Badge type="orange" children="Transferred" />
      )}
      {!!data.is_deleted && <Badge type="red" children="Deleted" />}
    </div>
  );

export default TagsList;
