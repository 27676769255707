import React from 'react';

const Price = props => {
  const { order, prices: priceData } = props;
  const prices = priceData.prices ?? [];
  const sharedOrder = order.accessLevel === "shared"
  const endToEndTime = prices.find(item => Boolean(item.endtoEndTime))?.endtoEndTime; 
  const { totalPrice } = order;
  const mileage = order?.mileage;

  return (
    <>
      <h3>
        Total price:
        <span className="text-navy"> ${sharedOrder ? 0 : totalPrice}</span>
      </h3>
      <div style={{ marginBottom: '15px' }}>
        <strong className="stats-label">Mileage: </strong>
        <span className="text-navy">
          {sharedOrder ? 'N/A' : mileage ?? 'N/A'}
        </span>
      </div>
      <div style={{ marginBottom: '15px' }}>
        <strong className="stats-label">Order Time: </strong>
        <span className="text-navy">
          {endToEndTime && !sharedOrder ? `${endToEndTime} minutes` : 'N/A'}
        </span>
      </div>

      {prices.map((item, index) => (
        <div key={`price-${index}`}>
          {!!item.price && (
            <div
              // style={{marginBottom: '15px'}}
              style={
                prices?.length > 1
                  ? {
                    borderBottom: '1px solid rgba(255, 255, 255, 0.5)',
                    marginBottom: '15px',
                  }
                  : { marginBottom: '15px' }
              }>
              <strong>{item.title}</strong>
              {item.name && (
                <span className="text-navy">{` - ${item.name}`}</span>
              )}
              {': '}
              <strong className="text-navy">${item.price} </strong>-{' '}
              {item.comment}
            </div>
          )}
        </div>
      ))}
    </>
  );
};

export default Price;
