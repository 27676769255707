import { useMemo, useState } from 'react';

export const useCertModalControls = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState({});

  const handlers = useMemo(
    () => ({
      open: job => {
        setData({
          jobId: job.id,
          isFullEdit: job.isFullEdit,
        });
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
        setData({});
      },
    }),
    [],
  );

  return {
    isOpen,
    data,
    ...handlers,
  };
};

export const useEditJobModalControls = () => {
  const [data, setData] = useState({});
  const [isOpen, setIsOpen] = useState(false);

  const handlers = useMemo(
    () => ({
      open: jobId => {
        setData({
          jobId,
        });
        setIsOpen(true);
      },
      close: () => {
        setIsOpen(false);
        setData({});
      },
    }),
    [],
  );

  return {
    isOpen,
    data,
    ...handlers,
  };
};
