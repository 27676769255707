import React from 'react';
import Button from 'components/shared/Button';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';
import Authorize from 'components/common/Authorize';
import Pagination from 'components/shared/Pagination';
import { operationTypes } from 'components/globalInfo/jobOperationType';
import { Col, Row } from 'react-bootstrap';

const filters = [
  { value: 'id', label: 'Job ID' },
  { value: 'job_name', label: 'Job Name' },
  { value: 'well_name', label: 'Location' },
  { value: 'job_manager_name', label: 'Job Manager' },
];

const Filters = ({
  filter,
  setValue,
  setStatus,
  setFilter,
  setIsDraftJob,
  totalPages,
  page,
  setPage,
  pageSize,
  setPageSize
}) => {
  const { status, name, id, sort, isDraftJob } = filter;

  return (
    <>
      <Row>
        <h3 className="col-md-12" style={{ paddingLeft: 0 }}>
          Sort by:
        </h3>
        <Col
          md={2}
          className="form-group has-feedback"
          style={{ paddingLeft: 0 }}>
          <Select
            defaultValue={filters.find(item => item.value === sort)}
            onChange={item => {
              setPage(1);
              setFilter(item.value, 'sort')
            }
            }
            options={filters}
            testSelector="jobs_filters_sorting_select"
          />
        </Col>
        <Col md={2} className="form-group has-feedback">
          <Input
            type="number"
            onChange={e => {
              setPage(1);
              setValue(e, 'id');
            }}
            value={id}
            label="Job ID"
            testSelector="jobs_filters_id_input"
          />
        </Col>
        <Col md={2} className="form-group has-feedback">
          <Input
            onChange={e => {
              setPage(1);
              setValue(e, 'name');
            }}
            value={name}
            label="Job name"
            testSelector="jobs_filter_name_input"
          />
        </Col>
        <Col md={2} className="form-group has-feedback">
          <Select
            defaultValue={{ value: -1, label: 'All' }}
            onChange={item => {
              setPage(1);
              setFilter(item.value, 'jobType')
            }}
            options={Object.values(operationTypes).map(item => {
              if (!item.value) {
                return { value: -1, label: 'All' };
              }
              return { value: item.value, label: item.label };
            })}
            testSelector="jobs_filters_job-type_select"
          />
        </Col>
        <Col
          md={4}
          className="form-group has-feedback"
          style={{ textAlign: 'right', paddingRight: 0 }}>
          <Authorize forbidden={['CARRIER']}>
            <Button
              onClick={() => {
                setPage(1);
                setIsDraftJob(Number(!isDraftJob));
              }}
              colour={`${isDraftJob === 1 ? 'yellow' : ''}`}>
              Draft
            </Button>
          </Authorize>
          <Button
            onClick={() => {
              setPage(1);
              setStatus(0);
            }}
            colour={`${status === 0 ? 'orange' : ''}`}
            testSelector="job-details_filters_pendig_btn">
            Pending
          </Button>
          <Button
            onClick={() => {
              setPage(1);
              setStatus(1);
            }}
            colour={`${status === 1 ? 'orange' : ''}`}
            testSelector="job-details_filters_completed_btn">
            Completed
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <Pagination
            withLimit
            onPageChange={newPage => setPage(newPage.currentPage)}
            onLimitChange={limit => {
              setPage(1);
              setPageSize(limit);
            }}
            currentLimit={pageSize}
            currentPage={page}
            totalPages={totalPages}
          />

        </Col>
      </Row>
    </>
  );
};
export default Filters;
