import authService from 'services/auth/service';
import { jobManager } from 'services/jobManager/service';
import { customer } from 'services/customer/service';
import { tanks } from 'services/tanks/service';
import { ROLES } from 'config/constants';
import swal from 'bootstrap-sweetalert';

import orderListActions from 'store/actions/OrdersList';
import poModalActions from './PO';
import stageDesignModalActions from './StageDesign';
import unitsAction from './Units';

const getDetails = jobId => dispatch => {
  const success = date => ({
    type: 'JOB_DETAILS_GET_DETAILS_SUCCESS',
    date
  });
  const failure = error => ({
    type: 'NOTIFICATION_ERROR_IGNORE',
    error
  });
  const detailsParam = { excludeOrder: true };
  jobManager
    .getJobDetails(jobId, detailsParam)
    .then(response => {
      const { data } = response.data;
      const dataObj = {
        details: data.details,
        purchaseOrders: data.purchaseOrders,
        rateTable: data.rateTable,
        stageDesign: data.stageDesign,
        stages: data.stages,
        storage: data.storage,
        storageHistory: data.storageHistory,
        totalStats: data.jobDesign,
        conteinerizedSettings: data.conteinerizedSettings,
        demurrageTable: data.demurrageTable,
        units: data.disposalUnits,
        carriers: data.carriers,
        facilityId: data.facilityId,
        documents: data.documents,
        logisticPo: data.logisticPo,
        stats: data.stats,
        stageDesignConfig: data.stageDesignConfig,
        storageTimeline: data.storageTimeline,
        storageSiloInfo: data.storageSiloInfo,
        storageStatus: data.storageStatus,
        operators: data.operators,
        billingProfile: data.billingProfile,
        districts: data.districts,
        permissions: data.permissions,
        cygnet: data.cygnet,
      };
      jobManager.getJobAlerts(jobId)
        .then(alertResponse => {
          if (alertResponse.status === 200 && alertResponse.data && alertResponse.data.data) {
            const alerts = alertResponse.data.data;
            dataObj.jobAlerts = alerts.map(a => ({ message: a.payload.message, time: a.publishTime }));
          }

          dispatch(
            getContainers({
              customerId: dataObj.details.customerId,
              jobId: dataObj.details.jobId,
              type: 2
            }),
          );


          dispatch(success(dataObj));
          dispatch(getTanks(dataObj.details.jobId));

          const user = authService.getUser();

          if (
            ((!dataObj.purchaseOrders.length && !dataObj.stageDesign.length && dataObj.details.operationType !== 12) ||
              (dataObj.units && !dataObj.units.length)) &&
            user.role !== ROLES.JOB_COORDINATOR &&
            (!dataObj.permissions || (dataObj.permissions && dataObj.permissions.isFullEdit))
          ) {
            swal(
              {
                title: 'This job is not yet configured. ',
                text: 'Would you like to setup missed configuration?',
                showCancelButton: true,
                confirmButtonColor: '#DD6B55',
                cancelButtonColor: 'rgb(35,198,200)',
                confirmButtonText: 'Ok',
                cancelButtonText: 'Skip',
                closeOnConfirm: true,
                closeOnCancel: true
              },
              (isConfirm) => {
                if (isConfirm) {
                  if (dataObj.details.operationType == 12) {
                    dispatch(unitsAction.openAddModal());
                  } else {
                    dispatch(stageDesignModalActions.turnAddStageDesign());
                    dispatch(poModalActions.turnAddPO(data.details));
                  }
                }
              },
            );
          }
        })
        .catch(error => {
          dispatch(failure(error));
          swal(error.response.data.message, '', 'error');
        });
    });
};

const jobDetailsRequest = () => ({
  type: 'JOB_DETAILS_GET_DETAILS_REQUEST'
});

const getContainers = data => dispatch => {
  const success = date => ({
    type: 'JOB_DETAILS_GET_CONTAINERS_SUCCESS',
    date
  });
  const failure = error => ({
    type: 'JOB_DETAILS_GET_CONTAINERS_FAILURE',
    error
  });

  customer.container
    .get(data)
    .then(response => {
      dispatch(success(response.data.data));
    })
    .catch(error => {
      dispatch(failure(error));
      // Fail silently if it is a permission error
      if (error.data.status !== 400) {
        swal(`${error.data.message} from container`, '', 'error');
      }
    });
};

const getTanks = data => dispatch => {
  const success = tanks => ({
    type: 'JOB_DETAILS_GET_TANKS_SUCCESS',
    tanks
  });
  const failure = error => ({
    type: 'JOB_DETAILS_GET_TANKS_FAILURE',
    error
  });

  tanks
    .get(data)
    .then(response => {
      dispatch(success(response.data.data.tanks));
    })
    .catch(error => {
      dispatch(failure(error));
      // Fail silently if it is a permission error
      if (error.data.status !== 400) {
        swal(`${error.data.message} from container`, '', 'error');
      }
    });
};

let interval;
const initAutoRefresh = (value, jobId, initFilters) => dispatch => {
  if (value) {
    if (interval) {
      clearInterval(interval);
    }
    interval = setInterval(() => {
      dispatch(getDetails(jobId));
      dispatch(orderListActions.init(initFilters));
    }, 30000);
  } else {
    clearInterval(interval);
  }
};

const switchWeightStatus = data => ({
  type: 'JOB_DETAILS_SWITCH_WEIGHT_STATUS',
  data
});

const clearState = () => dispatch => {
  if (interval) {
    clearInterval(interval);
  }
  dispatch({ type: 'JOB_DETAILS_CLEAR_STATE' });
};

const changeWeightsValue = data => (dispatch, getState) => {
  const state = getState();
  const pounds = parseInt(state.jobDetails.details.pounds);
  const formatData = data.toLocaleString(navigator.language);
  switch (pounds) {
    case 0: {
      return (data = `${formatData} unknown`);
    }
    case 1:
      return (data = `${formatData} lbs`);
    case 2:
      return (data = `${formatData} bbl`);
    case 3:
      return (data = `${data.toFixed(2)} tons`);
    default:
      return `${formatData} unknown`;
  }
};

const updateAutoOrder = (jobId, autoOrder) => dispatch => {
  const success = autoOrder => ({
    type: 'UPDATE_AUTO_ORDER_SUCCESS',
    autoOrder
  });
  jobManager.updateAutoOrder(jobId, autoOrder).then(response => {
    dispatch(success(response.data.data.autoOrder));
  });
};

export default {
  getDetails,
  switchWeightStatus,
  changeWeightsValue,
  getContainers,
  getTanks,
  initAutoRefresh,
  clearState,
  updateAutoOrder,
  jobDetailsRequest
};
