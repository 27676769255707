import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import { Modal, Row, Col } from 'react-bootstrap';
import moment from 'moment';

import Authorize from 'components/common/Authorize';
import Loader from 'components/shared/Loader';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Input from 'components/shared/Input';
import Select from 'components/shared/Select';

import { useJobPurchaseOrders } from 'api/v2/purchase-orders';

import { filterOptions } from 'utils/selectUtils';
import targetValue from 'utils/targetValue';
import { isDateFormatValid } from 'utils/datePickersFunctions';
import actions from 'store/actions/OrderActions';

import DatePicker from 'components/shared/DatePicker';
import Documents from './Documents';
import { formattedDate, formattedPos, submitFunction } from './helpers';

const Modify = ({
  order,
  init,
  setInputValue,
  clearState,
  openModal,
  turnModal,
  modify,
  submitModify,
  jobPlansdata,
  onClose = () => null,
}) => {
  useEffect(() => {
    init(order);

    if (order.fromQR) {
      setInputValue(moment(), 'wellDepartureTime');
    }

    return () => {
      clearState();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { data: pos, isLoading } = useJobPurchaseOrders(order.jobId);

  const optionallyFormatTimeToNow = (initialTime, destination) => {
    const formattedTime = initialTime.isValid()
      ? initialTime
      : moment(new Date());
    setInputValue(formattedTime, destination);
  };

  const [isDateAlert, turnDateAlert] = useState(false);

  const { input, stages, isLoaded, orderTickets } = modify;
  const {
    status,
    driver_id: driverId,
    operation_type: operationType,
    weight,
    staging_site: stagingSite,
  } = order;

  const {
    customerOrderId,
    sandTicketNo,
    mileage,
    orderAcceptedAt,
    loadArrivalTime,
    loadDepartureTime,
    stageArrivalTime,
    stageDepartureTime,
    wellArrivalTime,
    wellDepartureTime,
    driverWeight,
    stage,
    po,
    shouldComplete,
    kingComp,
    trailer_number: trailerNumber,
    truck,
    truckDown,
    isStageSiteOnOrder,
  } = input;

  const minForAlert = 240;
  const formattedStages = stages.map(item => {
    item.label = `${item.stageId}`;
    return item;
  });
  const stagesList = filterOptions(
    formattedStages,
    null,
    null,
    'stageId',
    'label',
  );
  const posList = useMemo(
    () =>
      filterOptions(
        formattedPos(pos ?? [], operationType),
        null,
        null,
        'value',
        'label',
      ),
    [pos, operationType],
  );

  const loadDepartTime = formattedDate(loadDepartureTime);
  const loadArriveTime = formattedDate(loadArrivalTime);
  const sandSiteTime = loadDepartTime.diff(loadArriveTime, 'minutes');

  const wellDepartTime = formattedDate(wellDepartureTime);
  const wellArriveTime = formattedDate(wellArrivalTime);

  const wellsiteTime = wellDepartTime.diff(wellArriveTime, 'minutes');

  return (
    <Modal show={openModal} onHide={turnModal} fullscreen="sm-down">
      <Modal.Header>
        <Modal.Title as="h3">Modify & Complete Order</Modal.Title>
      </Modal.Header>
      <form
        onSubmit={e => {
          submitFunction(
            e,
            stageArrivalTime,
            stageDepartureTime,
            submitModify,
            shouldComplete,
            status,
            stagingSite,
            jobPlansdata,
            onClose,
          );
        }}>
        <Modal.Body>
          {!isLoaded && !isLoading ? (
            <Row>
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <Input
                  label="Customer Order ID"
                  onChange={e => setInputValue(e, 'customerOrderId')}
                  value={customerOrderId}
                  testSelector="order-actions_modify_customer-order-id_input"
                />
              </Col>
              {driverId && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    label="Ticket Number"
                    onChange={e => setInputValue(e, 'sandTicketNo')}
                    value={sandTicketNo}
                    required={shouldComplete}
                    testSelector="order-actions_modify_ticket-number_input"
                  />
                </Col>
              )}
              {isDateAlert && (
                <div
                  className="nested-no-outer-gutter"
                  style={{ color: 'red' }}>
                  <p>
                    * Date format should be &apos;YYYY-MM-DD hh:mm AM/PM&apos;
                  </p>
                </div>
              )}
              {driverId && (
                <>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Order Accepted Time"
                      value={orderAcceptedAt}
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        setInputValue(e, 'orderAcceptedAt');
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_load-accepted-time_dp"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <div />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Origin Arrival Time"
                      value={loadArrivalTime}
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        setInputValue(e, 'loadArrivalTime');
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_load-arrival-time_dp"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Origin Depart Time"
                      value={loadDepartureTime}
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        setInputValue(e, 'loadDepartureTime');
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_load-depart-time_dp"
                    />
                  </Col>
                </>
              )}

              {driverId && isStageSiteOnOrder && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <DatePicker
                    label="Staging Arrival Time"
                    value={stageArrivalTime}
                    onChange={e => {
                      isDateFormatValid(e, turnDateAlert);
                      setInputValue(e, 'stageArrivalTime');
                    }}
                    dateFormat="YYYY-MM-DD"
                    timeFormat="hh:mm A"
                    testSelector="order-actions-modify_staging-arrival-time_dp"
                  />
                </Col>
              )}
              {driverId && isStageSiteOnOrder && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <DatePicker
                    label="Staging Depart"
                    value={stageDepartureTime}
                    onChange={e => {
                      isDateFormatValid(e, turnDateAlert);
                      setInputValue(e, 'stageDepartureTime');
                    }}
                    dateFormat="YYYY-MM-DD"
                    timeFormat="hh:mm A"
                    testSelector="order-actions-modify_staging-depart-time_dp"
                  />
                </Col>
              )}
              {driverId && (
                <>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Destination Arrival Time"
                      value={wellArrivalTime}
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        setInputValue(e, 'wellArrivalTime');
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_well-arrival-time_dp"
                    />
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <DatePicker
                      label="Destination Depart Time"
                      value={wellDepartureTime}
                      onFocus={() =>
                        optionallyFormatTimeToNow(
                          wellDepartTime,
                          'wellDepartureTime',
                        )
                      }
                      onChange={e => {
                        isDateFormatValid(e, turnDateAlert);
                        setInputValue(e, 'wellDepartureTime');
                      }}
                      dateFormat="YYYY-MM-DD"
                      timeFormat="hh:mm A"
                      testSelector="order-actions-modify_well-depart-time_dp"
                    />
                  </Col>
                </>
              )}
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <Input
                  label="Truck"
                  onChange={e => setInputValue(e, 'truck')}
                  value={truck}
                  testSelector="order-actions_modify_truck_input"
                />
              </Col>
              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <Input
                  label="Trailer"
                  onChange={e => setInputValue(e, 'trailer_number')}
                  value={trailerNumber}
                  testSelector="order-actions_modify_trailer_input"
                />
              </Col>
              <Authorize forbidden={['JOB_COORDINATOR']}>
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    type="number"
                    label="Mileage"
                    min="0"
                    onChange={e => setInputValue(e, 'mileage')}
                    value={mileage}
                    required={shouldComplete}
                    testSelector="order-actions_modify_mileage_input"
                  />
                </Col>
              </Authorize>
              {operationType !== 12 && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    label="Silo/King/Comp"
                    onChange={e => setInputValue(e, 'kingComp')}
                    value={kingComp}
                    testSelector="order-actions_modify_king-comp_input"
                  />
                </Col>
              )}
              {driverId && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    type="number"
                    label="Quantity"
                    min={!shouldComplete && status !== 4 ? 0 : 100}
                    // max={weight}
                    max={85000}
                    step={1}
                    onChange={e => setInputValue(e, 'driverWeight')}
                    value={driverWeight || weight}
                    required={shouldComplete}
                    testSelector="order-actions_modify_load-weight_input"
                  />
                </Col>
              )}
              {operationType !== 12 && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Select
                    placeholder="Stage"
                    onChange={item => setInputValue(item.value, 'stage')}
                    value={stagesList.find(item => item.value === stage)}
                    options={stagesList}
                    required
                    testSelector="order-actions_modify_stage_select"
                  />
                </Col>
              )}

              <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                <Select
                  placeholder={
                    operationType === 12
                      ? 'Destination'
                      : 'PO / Commodity / Location'
                  }
                  onChange={item => setInputValue(item.value, 'po')}
                  value={posList.find(item => item.value === po)}
                  options={posList}
                  isOptionDisabled={option => option.disabled}
                  required={shouldComplete}
                  testSelector="order-actions_modify_po_select"
                />
              </Col>
              {driverId && (
                <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                  <Input
                    type="checkbox"
                    testSelector="order-actions_modify_truck-down"
                    onChange={item => setInputValue(item, 'truckDown')}
                    isChecked={Boolean(truckDown)}
                    label="Truck Down"
                    disabled={status === 4}
                  />
                </Col>
              )}
              {Number(status) !== 1 && Number(status) !== 0 && (
                <Row>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <div
                      id="vertical-timeline2"
                      className="vertical-container dark-timeline">
                      <div className="vertical-timeline-block">
                        <div className="vertical-timeline-icon navy-bg">
                          <Icon icon="truck" />
                        </div>
                        <div className="vertical-timeline-content">
                          <span>
                            <b>Driver was at Origin</b>
                          </span>
                          <span className="vertical-date">
                            <h1
                              className={`font-bold ${
                                sandSiteTime > minForAlert
                                  ? 'text-warning'
                                  : 'text-navy'
                              }`}>
                              {sandSiteTime} min
                            </h1>
                            {sandSiteTime < 5 && (
                              <div className="text-danger">Incorrect time</div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} md={6} sm={6} className="form-group has-feedback">
                    <div
                      id="vertical-timeline2"
                      className="vertical-container dark-timeline">
                      <div className="vertical-timeline-block">
                        <div className="vertical-timeline-icon navy-bg">
                          <Icon icon="truck" />
                        </div>
                        <div className="vertical-timeline-content">
                          <span>
                            <b>Driver was at Destination</b>
                          </span>
                          <span className="vertical-date">
                            <h1
                              className={`font-bold ${
                                wellsiteTime > minForAlert
                                  ? 'text-warning'
                                  : 'text-navy'
                              }`}>
                              {wellsiteTime} min
                            </h1>
                            {wellsiteTime < 16 && (
                              <div className="text-danger">Incorrect time</div>
                            )}
                          </span>
                        </div>
                      </div>
                    </div>
                  </Col>
                </Row>
              )}
              <Documents orderTickets={orderTickets} orderId={order.order_id} />
            </Row>
          ) : (
            <Loader />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={turnModal}
            colour="white"
            testSelector="order-actions_modify_close_btn">
            Close
          </Button>
          <Button
            type="submit"
            onClick={() => setInputValue(false, 'shouldComplete')}
            disabled={isLoaded || isDateAlert}
            testSelector="order-actions_modify_save_btn">
            Save
          </Button>
          {status !== 4 && driverId && (
            <Button
              type="submit"
              onClick={() => setInputValue(true, 'shouldComplete')}
              colour="green"
              disabled={isLoaded || isDateAlert}
              testSelector="order-actions_modify_complete_btn">
              Complete
            </Button>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

const mapStateToProps = state => ({
  modify: state.orderActions.modify,
  order: state.orderActions.order,
});

const mapDispatchToProps = dispatch => ({
  init: order => dispatch(actions.initModify(order)),
  setInputValue: (e, name) =>
    dispatch(actions.setInputValueModify(targetValue(e), name)),
  submitModify: (jobPlans, callbackFn) => {
    dispatch(actions.submitModify(jobPlans, callbackFn));
  },
  clearState: () => dispatch(actions.clearStateModify()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Modify);
