import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import actions from 'store/actions/OrderDetails';
import Authorize from 'components/common/Authorize';
import orderDetailsModel from 'models/OrderDetails';
import { deleteAlert } from 'components/sweetalert';
import Button from 'components/shared/Button';
import Icon from 'components/shared/Icon';
import Loader from 'components/shared/Loader';
import Label from 'components/shared/Label';
import ImageViewer from 'components/shared/ImageViewer';
import PreviewSandTicket from './PreviewSandTicket';
import EditSandTicket from './EditSandTicket';


const SandTickets = ({
  getSandTicketsDesign,
  selectTiket,
  clearGetST,
  clearEditST,
  info,
  sandTicket,
  deleteSandTicket,
}) => {
  const [showPreview, turnPreview] = useState(false);
  const [showModalEditTicket, turnEditTicket] = useState(false);

  const [showImageViewer, setShowImageViewer] = useState(false);
  const [docNumber, setdocNumber] = useState(undefined);
  const [docName, setdocName] = useState('');

  useEffect(() => {
    getSandTicketsDesign();
  }, []);

  const { sandTicketTypes, previewTicketId, isLoaded } = sandTicket;
  const { order, sandTicketIds, commodityInfo } = info;
  const { order_id, hasMissingSandTickets } = order;
  const {
    missingSandTickets: missTickests,
    mappedSandTicketsToTypes: mappedTickets,
    notRequiredTicketsToTypes: notRequiredTickets,
  } = orderDetailsModel;
  const missingSandTickets = missTickests(sandTicketIds, sandTicketTypes);
  const mappedSandTicketsToTypes = mappedTickets(
    sandTicketIds,
    sandTicketTypes,
  );
  const notRequiredTicketsToTypes = notRequiredTickets(
    sandTicketIds,
    sandTicketTypes,
  );

  return (
    (isLoaded && (
      <div>
        {showPreview && (
          <PreviewSandTicket
            showPreview={showPreview}
            closePreview={() => {
              turnPreview(false);
              clearGetST();
            }}
            previewTicketId={previewTicketId}
            orderId={order_id}
          />
        )}
        {showImageViewer && (
          <ImageViewer
            visibleToggle={setShowImageViewer}
            docId={docNumber}
            docName={docName}
            visible={showImageViewer}
          />
        )}
        {showModalEditTicket && (
          <EditSandTicket
            showModalEditTicket={showModalEditTicket}
            closeEditTicket={() => {
              turnEditTicket(false);
              clearEditST();
            }}
            orderId={order_id}
            sandTicketTypes={sandTicketTypes}
          />
        )}
        <div className="row" style={{ marginRight: '0px' }}>
          <div className="col-md-12 col-sm-12">
            <small>
              <strong>Commodity Info: </strong>
            </small>
            <ul className="unstyled">
              {commodityInfo.length > 0 &&
                commodityInfo.map((item, index) => (
                  <div key={`commodityInfo-${item.name}-${index}`}>
                    <div className="row">
                      <div className="col-md-6 col-sm-12"></div>
                      <div className="col-md-6 col-sm-12">
                        <Button
                          onClick={() => {
                            setShowImageViewer(x => !x);
                            setdocNumber(Number(item.documents));
                            setdocName(item.name);
                          }}
                          theme="small"
                          colour="green"
                          testSelector="order-details_commodity_documents_preview_btn">
                          {`${item.name}`}
                        </Button>
                      </div>
                    </div>
                  </div>
                ))}
            </ul>
          </div>
        </div>

        <div className="row" style={{ marginRight: '0px' }}>
          <div className="col-md-12 col-sm-12">
            <small>
              <strong>Required Tickets: </strong>
            </small>
            <ul className="unstyled">
              {mappedSandTicketsToTypes.map((item, index) => (
                <div className="row" key={`sandTicketTypes-${index}`}>
                  <div className="col-md-6 col-sm-12">
                    <li style={{ height: 30 }}>
                      <Label className="ticket-danger" type="danger">
                        {item.sandTicketType.name}
                      </Label>
                    </li>
                  </div>
                  <div
                    className="col-md-6 col-sm-12"
                    style={{ marginBottom: '10px' }}>
                    {item.sandTicketIds.length > 0 &&
                      item.sandTicketIds.map((item, index) => (
                        <div
                          key={`sandTicketIds-${item.ticket_number}-${index}`}>
                          <Button
                            onClick={() => {
                              turnPreview(true);
                              selectTiket(item);
                            }}
                            theme="small"
                            colour="green"
                            testSelector="order-details_sand-tickets_preview_btn">
                            {sandTicketTypes.map(
                              type =>
                                type.sandTicketTypeId ===
                                  item.sand_ticket_type && type.name,
                            )}{' '}
                            #{item.ticket_number || 'unknown'}
                          </Button>
                          <Authorize
                            {...{
                              itemAccessGranted: !order.is_deleted,
                              abilityPermissions: [
                                {
                                  resource: 'Orders',
                                  permissions: ['update'],
                                },
                              ],
                            }}>
                            <Button
                              onClick={() => {
                                turnEditTicket(true);
                                selectTiket(item);
                              }}
                              testSelector="order-details_sand-tickets_preview_btn"
                              theme="small">
                              <Icon icon="pencil" />
                            </Button>
                          </Authorize>
                          <Authorize
                            {...{
                              itemAccessGranted: !order.is_deleted,
                              abilityPermissions: [
                                {
                                  resource: 'Orders',
                                  permissions: ['update'],
                                },
                              ],
                            }}>
                            <Button
                              onClick={() =>
                                deleteAlert(deleteSandTicket, item.id)
                              }
                              testSelector="order-details_sand-tickets_delete_btn"
                              theme="small">
                              <Icon icon="trash" />
                            </Button>
                          </Authorize>
                        </div>
                      ))}
                  </div>
                </div>
              ))}
            </ul>
          </div>
          {hasMissingSandTickets === 1 && missingSandTickets.length ? (
            <div className="col-md-12 col-sm-12" style={{ marginTop: '5px' }}>
              <small>
                <strong>Missing Tickets: </strong>
              </small>
              {
                <ul className="unstyled">
                  {missingSandTickets.map((item, index) => (
                    <li key={`missingSandTickets-${index}`}>
                      <Label className="ticket-danger" type="danger">
                        {item}
                      </Label>
                    </li>
                  ))}
                </ul>
              }
            </div>
          ) : null}
          {notRequiredTicketsToTypes.length ? (
            <div className="col-md-12 col-sm-12" style={{ marginTop: '5px' }}>
              <small>
                <strong>Not Required Tickets: </strong>
              </small>
              <ul className="unstyled">
                {notRequiredTicketsToTypes.map((item, index) => (
                  <div
                    className="row"
                    key={`notRequiredTicketsToTypes-${index}`}>
                    <div className="col-md-6 col-sm-12">
                      <li>
                        <Label className="ticket-danger" type="danger">
                          {item.sandTicketType.name}
                        </Label>
                      </li>
                    </div>
                    <div
                      className="col-md-6 col-sm-12"
                      style={{ marginBottom: '10px' }}>
                      {item.sandTicketIds.length > 0 &&
                        item.sandTicketIds.map((item, index) => (
                          <div
                            key={`sandTicketIds-${item.ticket_number}-${index}`}>
                            <Button
                              onClick={() => {
                                turnPreview(true);
                                selectTiket(item);
                              }}
                              theme="small"
                              colour="green"
                              testSelector="order-details_sand-tickets_preview_btn">
                              {sandTicketTypes.map(
                                type =>
                                  type.sandTicketTypeId ===
                                    item.sand_ticket_type && type.name,
                              )}{' '}
                              #{item.ticket_number || 'unknown'}
                            </Button>
                            <Authorize
                              {...{
                                itemAccessGranted: !order.is_deleted,
                                abilityPermissions: [
                                  {
                                    resource: 'Orders',
                                    permissions: ['update'],
                                  },
                                ],
                              }}>
                              <Button
                                onClick={() => {
                                  turnEditTicket(true);
                                  selectTiket(item);
                                }}
                                testSelector="order-details_sand-tickets_preview_btn"
                                theme="small">
                                <Icon icon="pencil" />
                              </Button>
                            </Authorize>
                            <Authorize
                              {...{
                                itemAccessGranted: !order.is_deleted,
                                abilityPermissions: [
                                  {
                                    resource: 'Orders',
                                    permissions: ['update'],
                                  },
                                ],
                              }}>
                              <Button
                                onClick={() =>
                                  deleteAlert(deleteSandTicket, item.id)
                                }
                                testSelector="order-details_sand-tickets_delete_btn"
                                theme="small">
                                <Icon icon="trash" />
                              </Button>
                            </Authorize>
                          </div>
                        ))}
                    </div>
                  </div>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </div>
    )) || <Loader />
  );
};

const mapStateToProps = state => ({
  info: state.orderDetails.info,
  sandTicket: state.orderDetails.sandTicket,
});

const mapDispatchToProps = dispatch => ({
  getSandTicketsDesign: () => dispatch(actions.getSandTicketsDesign()),
  selectTiket: item => dispatch(actions.selectTiket(item)),
  clearGetST: () => dispatch(actions.clearGetST()),
  clearEditST: () => dispatch(actions.clearEditST()),
  deleteSandTicket: id => dispatch(actions.deleteSandTicket(id)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SandTickets);
