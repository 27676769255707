import { useMemo } from 'react';
import multiSelectOptions from 'utils/multiSelectOptions';

import { useCommodities, useCustomerCommodities } from 'api/v2/commodities';

export const useCommodityOptions = (stageDesign, customerId) => {
  const { data: customerCommodities } = useCustomerCommodities(customerId);
  const { data: defaultCommodities } = useCommodities();
  const commodities = customerId ? customerCommodities : defaultCommodities;
  const commodityOptions = multiSelectOptions(commodities, 'id', 'name');

  if (stageDesign?.length) {
    const filteredCommodities = commodities.filter(
      commodity => !stageDesign.some(sd => sd.sandTypeId === commodity.id),
    );
    return [
      multiSelectOptions(filteredCommodities, 'id', 'name'),
      commodityOptions,
    ];
  }

  return [commodityOptions, commodityOptions];
};

export const useJobDesignCommodities = (jobDesign, customerId) => {
  const { data: customerCommodities } = useCustomerCommodities(customerId);
  const { data: defaultCommodities } = useCommodities();
  const commodities = customerId ? customerCommodities : defaultCommodities;

  const commodityOptions = useMemo(() => {
    if (jobDesign?.length) {
      const filteredCommodities = commodities.filter(commodity =>
        jobDesign.some(jd => {
          if (jd.commodity) {
            return jd.commodity.id === commodity.id;
          }
          return jd.sandTypeId === commodity.id;
        })
      );
      const commoditiesWithUOMName = filteredCommodities.map(commodity => {
        const matchingJobDesign = jobDesign.find(jd => {
          if (jd.commodity) {
            return jd.commodity.id === commodity.id;
          }
          return jd.sandTypeId === commodity.id;
        });
    
        return {
          ...commodity,
          commodityUOMName: matchingJobDesign ? matchingJobDesign.commodityUOMName : ''
        };
      });
      return multiSelectOptions(commoditiesWithUOMName, 'id', 'name');
    }
    return multiSelectOptions(commodities, 'id', 'name');
  }, [jobDesign, commodities]);

  return commodityOptions;
};

export const useJobDesignCommoditiesUOM = (jobDesign, storageConfig) => {
  const configUOM = useMemo(() => {
    if (jobDesign?.length) {
      return storageConfig.map(sc => {
        const matchingJobDesign = jobDesign.find(jd => jd.sandTypeId === sc.commodityId);
        return {
          ...sc,
          commodityUOMName: matchingJobDesign ? matchingJobDesign.commodityUOMName : ''
        };
      });
    }
    return storageConfig;
  }, [jobDesign, storageConfig]);
  return configUOM;
};

