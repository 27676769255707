import { customer } from 'services/customer/service';
import swal from 'bootstrap-sweetalert';

const cahngeKey = value => ({
  type: 'JOB_DETAILS_CHANGE_TAB_KEY',
  value,
});
const clearState = () => ({ type: 'JOB_DETAILS_TAB_CLEAR_STATE' });

const updateContainerStatus = data => (dispatch, getState) => {
  const success = date => ({
    type: 'JOB_DETAILS_UPDATE_CONTAINERS_STATUS_SUCCESS',
    date,
  });
  const failure = error => ({
    type: 'JOB_DETAILS_UPDATE_CONTAINERS_STATUS_FAILURE',
    error,
  });
  const state = getState();
  const details = Object.assign(
    {},
    state.jobDetails.details.details,
  );

  const { jobId, customerId } = details;

  customer.container
    .updateStatus(data)
    .then(response => {
      dispatch(success(response.data.data));
      dispatch(
        initActions.getContainers({
          customerId,
          jobId,
          type: 2,
        }),
      );
    })
    .catch(error => {
      dispatch(failure(error));
      swal(error.message, '', 'error');
    });
};

export default {
  cahngeKey,
  updateContainerStatus,
  clearState,
};
