import {
  queryOptions,
  useMutation,
  useQuery,
  useQueryClient,
  useSuspenseQuery,
} from '@tanstack/react-query';
import swal from 'bootstrap-sweetalert';
import {
  addStorageType,
  deleteStorageType,
  editStorageType,
  getStorageSources,
  getStorageTypes,
} from './queries';

export const useStorageSources = () =>
  useQuery({
    queryKey: ['storage-types', 'sources'],
    queryFn: getStorageSources,
    placeholderData: [],
  });

const storageTypesOptions = (options = {}) =>
  queryOptions({
    queryKey: ['storage-types'],
    queryFn: getStorageTypes,
    ...options,
  });

export const useStorageTypes = () =>
  useQuery(storageTypesOptions({ placeholderData: [] }));
export const useSuspenseStorageTypes = () =>
  useSuspenseQuery(storageTypesOptions());

export const useAddStorageType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: addStorageType,
    onSuccess: () => {
      swal('Successfully Added Storage Type', '', 'success');
      queryClient.invalidateQueries(['storage-types']);
    },
    onError: error => {
      swal(
        'Unable to Add Storage Type',
        error.response?.data?.message,
        'error',
      );
    },
  });
};

export const useEditStorageType = ({ storageTypeId }) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: newStorageType =>
      editStorageType({ storageTypeId, newStorageType }),
    onSuccess: () => {
      swal('Successfully Edited Storage Type', '', 'success');
      queryClient.invalidateQueries(['storage-types']);
    },
    onError: error => {
      swal(
        'Unable to Edit Storage Type',
        error.response?.data?.message,
        'error',
      );
    },
  });
};

export const useDeleteStorageType = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: storageTypeId => deleteStorageType(storageTypeId),
    onSuccess: () => {
      swal('Successfully Deleted Storage Type', '', 'success');
      queryClient.invalidateQueries(['storage-types']);
    },
    onError: error => {
      swal(
        'Unable to Delete Storage Type',
        error.response?.data?.message,
        'error',
      );
    },
  });
};
